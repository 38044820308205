export const assignmentSubjectColumns = (intl, sort = 0, idPrefix = '') => [
  {id:idPrefix + 'id', name:intl.formatMessage({id:'export_subjects.columns.id', defaultMessage:'Assigned ID'}), sorting:sort++},
  {id:idPrefix + 'assignment.name', name:intl.formatMessage({id:'export_subjects.columns.assignment', defaultMessage:'Assignment'}), sorting:sort++},
  {id:idPrefix + 'outcome', name:intl.formatMessage({id:'export_subjects.columns.outcome', defaultMessage:'Outcome'}), sorting:sort++},
  {id:idPrefix + 'phoner', name:intl.formatMessage({id:'export_subjects.columns.phoner', defaultMessage:'Phoner'}), sorting:sort++},
  {id:idPrefix + 'customer_contact', name:intl.formatMessage({id:'export_subjects.columns.customer_contact', defaultMessage:'Consultant'}), sorting:sort++},
  {id:idPrefix + 'next_contact_at', name:intl.formatMessage({id:'export_subjects.columns.next_contact_at', defaultMessage:'Next Contact'}), sorting:sort++},
  {id:idPrefix + 'last_edited_by_manager_at', name:intl.formatMessage({id:'export_subjects.columns.last_edited_by_manager_at', defaultMessage:'Edited by Manager'}), sorting:sort++},
  {id:idPrefix + 'last_edited_by_phoner_at', name:intl.formatMessage({id:'export_subjects.columns.last_edited_by_phoner_at', defaultMessage:'Edited by Phoner'}), sorting:sort++},
  {id:idPrefix + 'meeting_established_at', name:intl.formatMessage({id:'export_subjects.columns.meeting_established_at', defaultMessage:'Meeting Established'}), sorting:sort++},
  {id:idPrefix + 'calls_made', name:intl.formatMessage({id:'export_subjects.columns.calls_made', defaultMessage:'Calls Made'}), sorting:sort++},
  {id:idPrefix + 'changes_made', name:intl.formatMessage({id:'export_subjects.columns.changes', defaultMessage:'Changes'}), sorting:sort++},
  {id:idPrefix + 'status', name:intl.formatMessage({id:'export_subjects.columns.status', defaultMessage:'Status'}), sorting:sort++},
  {id:idPrefix + 'comment', name:intl.formatMessage({id:'export_subjects.columns.comment', defaultMessage:'Internal Comment'}), sorting:sort++},
  {id:idPrefix + 'ext_comment', name:intl.formatMessage({id:'export_subjects.columns.ext_comment', defaultMessage:'External Comment'}), sorting:sort++},
  {id:idPrefix + 'subject_contact.title', name:intl.formatMessage({id:'export_subjects.columns.contact_title', defaultMessage:'Contact Title'}), sorting:sort++},
  {id:idPrefix + 'subject_contact.name', name:intl.formatMessage({id:'export_subjects.columns.contact_name', defaultMessage:'Contact Name'}), sorting:sort++},
  {id:idPrefix + 'subject_contact.email', name:intl.formatMessage({id:'export_subjects.columns.contact_email', defaultMessage:'Contact Email'}), sorting:sort++},
  {id:idPrefix + 'subject_contact.phone', name:intl.formatMessage({id:'export_subjects.columns.contact_phone', defaultMessage:'Contact Phone'}), sorting:sort++},
  {id:idPrefix + 'subject_contact.mobile', name:intl.formatMessage({id:'export_subjects.columns.contact_mobile', defaultMessage:'Contact Mobile'}), sorting:sort++},
  {id:idPrefix + 'subject_contact.department.name', name:intl.formatMessage({id:'export_subjects.columns.contact_department', defaultMessage:'Contact Department'}), sorting:sort++},
];

export const subjectColumns = (intl, sort = 0, idPrefix = '') => [
  {id:idPrefix + 'id', name:intl.formatMessage({id:'export_subjects.columns.subject_id', defaultMessage:'Subject ID'}), sorting:sort++},
  {id:idPrefix + 'name', name:intl.formatMessage({id:'export_subjects.columns.subject_name', defaultMessage:'Name'}), sorting:sort++},
  {id:idPrefix + 'address_1', name:intl.formatMessage({id:'export_subjects.columns.subject_address_1', defaultMessage:'Address'}), sorting:sort++},
  {id:idPrefix + 'address_2', name:intl.formatMessage({id:'export_subjects.columns.subject_address_2', defaultMessage:'Address 2'}), sorting:sort++},
  {id:idPrefix + 'zip', name:intl.formatMessage({id:'export_subjects.columns.subject_zip', defaultMessage:'Zip'}), sorting:sort++},
  {id:idPrefix + 'city', name:intl.formatMessage({id:'export_subjects.columns.subject_city', defaultMessage:'City'}), sorting:sort++},
  {id:idPrefix + 'state', name:intl.formatMessage({id:'export_subjects.columns.subject_state', defaultMessage:'State'}), sorting:sort++},
  {id:idPrefix + 'country_code', name:intl.formatMessage({id:'export_subjects.columns.subject_country_code', defaultMessage:'Country code'}), sorting:sort++},
  {id:idPrefix + 'meeting_address_1', name:intl.formatMessage({id:'export_subjects.columns.subject_meeting_address_1', defaultMessage:'Meeting Address'}), sorting:sort++},
  {id:idPrefix + 'meeting_address_2', name:intl.formatMessage({id:'export_subjects.columns.subject_meeting_address_2', defaultMessage:'Meeting Address 2'}), sorting:sort++},
  {id:idPrefix + 'meeting_zip', name:intl.formatMessage({id:'export_subjects.columns.subject_meeting_zip', defaultMessage:'Meeting Zip'}), sorting:sort++},
  {id:idPrefix + 'meeting_city', name:intl.formatMessage({id:'export_subjects.columns.subject_meeting_city', defaultMessage:'Meeting City'}), sorting:sort++},
  {id:idPrefix + 'main_phone', name:intl.formatMessage({id:'export_subjects.columns.subject_main_phone', defaultMessage:'Main Phone'}), sorting:sort++},
  // {id:idPrefix + 'direct_phone', name:intl.formatMessage({id:'export_subjects.columns.subject_direct_phone', defaultMessage:'Direct Phone'}), sorting:sort++},
  // {id:idPrefix + 'mobile_phone', name:intl.formatMessage({id:'export_subjects.columns.subject_mobile_phone', defaultMessage:'Mobile Phone'}), sorting:sort++},
  {id:idPrefix + 'email', name:intl.formatMessage({id:'export_subjects.columns.subject_email', defaultMessage:'Email'}), sorting:sort++},
  {id:idPrefix + 'website', name:intl.formatMessage({id:'export_subjects.columns.subject_website', defaultMessage:'Website'}), sorting:sort++},
  {id:idPrefix + 'registration_number', name:intl.formatMessage({id:'export_subjects.columns.subject_registration_number', defaultMessage:'Registration Number'}), sorting:sort++},
  {id:idPrefix + 'business_type', name:intl.formatMessage({id:'export_subjects.columns.subject_business_type', defaultMessage:'Business Type'}), sorting:sort++},
  {id:idPrefix + 'employees', name:intl.formatMessage({id:'export_subjects.columns.subject_employees', defaultMessage:'Employees'}), sorting:sort++},
  {id:idPrefix + 'notes', name:intl.formatMessage({id:'export_subjects.columns.subject_notes', defaultMessage:'Notes'}), sorting:sort++},
  {id:idPrefix + 'import_key', name:intl.formatMessage({id:'export_subjects.columns.subject_import_key', defaultMessage:'Import Key'}), sorting:sort++},
  {id:idPrefix + 'created_at', name:intl.formatMessage({id:'export_subjects.columns.subject_created_at', defaultMessage:'Imported'}), sorting:sort++},
];