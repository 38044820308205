import {CalendarToday, Home as HomeIcon} from '@material-ui/icons';
import {Button, Grid} from '@material-ui/core';
import AutocompleteApi from 'components/AutocompleteApi/AutocompleteApi';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {authUser} from 'contexts/Auth';
import {useEffect, useState} from 'react';
import AssignmentSubjectList from 'views/Assignment/Subjects/AssignmentSubjectList';
import {rolePriorities} from 'config';
import DialerMain from 'views/Dialer/DialerMain';
import Calendar from 'views/Dialer/Calendar';
// import Dialer from 'views/Dialer/Dialer';

const AssignmentSelector = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const intl = useIntl();
  const [selected, setSelected] = useState(null);

  let filter = 'is_active:true is_template:false is_screening:false';
  if (authUser().hasRole('manager')) {
    filter += ' manager_id:' + authUser().id;
  }
  else if (authUser().hasRole('employee')) {
    filter += ' phoner_id:' + authUser().id;
  }

  useEffect(() => {
    if (match.params && match.params.assignmentId) {
      setSelected(match.params.assignmentId);
    }
  }, [match.params]);

  let url = '/dialer/' + match.params.assignmentId + '/subjects';
  if (authUser().isAllowed(rolePriorities.manager)) {
    url = '/assignment/' + match.params.assignmentId + '/subjects';
  }

  return <Grid container wrap="nowrap" style={{width: 600}}>
    <Grid item style={{paddingTop: 4}}>{intl.formatMessage({
      id: "menu.dialer.with_assignment",
      defaultMessage: "Dialer assignment:"
    })}</Grid>
    <Grid item style={{flexGrow: 1}}><AutocompleteApi
      query="assignments"
      titleField="name"
      filter={filter}
      selectedValue={selected}
      onChange={(_e, v) => {
        setSelected(v);
        history.push('/dialer'+ (v && v.id ? '/' + v.id : ''));
      }}
      fullWidth
    /></Grid>
    {authUser().isAllowed(rolePriorities.lead) && match.params.assignmentId &&
      <Grid item>
        <Button
          size="small"
          variant="contained"
          onClick={() => history.push(url)}
        >Til opgave</Button>
      </Grid>}
  </Grid>;
}

export default function routes(intl, prefix) {
  return [
    {
      path: "",
      exact: true,
      name: <AssignmentSelector name={intl.formatMessage({id: "menu.dialer.single", defaultMessage: "Dialer"})}/>,
      icon: HomeIcon,
      component: DialerMain,
      query: 'assignments(filter:{id:#assignmentID#}) {data{id name}}',
      queryResult: (result) => {
        if (!window.location.pathname.endsWith('/' + result.assignments.data[0].id)) {
          return (result.assignments.data?.length ? result.assignments.data[0].name : intl.formatMessage({id: 'menu.dialer.single', defaultMessage: 'Dialer'}));
        }
        else {
          return <AssignmentSelector
              name={intl.formatMessage({id: 'menu.dialer.single', defaultMessage: 'Dialer'}) + ' - ' + (result.assignments.data?.length ? result.assignments.data[0].name : 'Ukendt')}
              result={result}
              prefix={prefix}
          />;
        }
      }
    },
    {
      allow_priority: 100,
      path: "/subjects",
      exact: true,
      name: intl.formatMessage({id: "menu.dialer.subjects", defaultMessage: "Subjects"}),
      icon: HomeIcon,
      component: AssignmentSubjectList,
    },
    {
      allow_priority: 125,
      path: "/calendar",
      exact: true,
      name: intl.formatMessage({id: "menu.dialer.calendar", defaultMessage: "Calendar"}),
      icon: CalendarToday,
      component: Calendar,
    },
  ].map((r) => ({...r, path: prefix + r.path}));
}
