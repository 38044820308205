import React from "react";
import {useIntl} from "react-intl";
import {Grid, Card, CardHeader, CardContent} from "@material-ui/core";
import {Score} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function AssignmentOutcomeEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.id);
    let assignmentId = props.match.params.assignmentID;

    const fields = [
        {
            field: "outcome_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "assignment_outcomes.edit.label.outcome_id", defaultMessage: "Outcome"}),
            query: "outcomes",
            filter: "",
            titleField: "name"
        },
        {
            field: "score",
            initial: 0,
            type: "Int",
            label: intl.formatMessage({id: "assignment_outcomes.edit.label.score", defaultMessage: "Score"}),
            input: "number"
        },
        {
            field: "hour_value",
            initial: 0,
            type: "Int",
            label: intl.formatMessage({id: "assignment_outcomes.edit.label.hour_value", defaultMessage: "Hour value"}),
            input: "number"
        }
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        avatar={<Score color="primary"/>}
                        title={intl.formatMessage({id: "assignment_outcomes.edit.heading", defaultMessage:"Assignment Outcomes edit"})}
                        titleTypographyProps={{color:"primary"}}
                    />
                    <CardContent>
                        <GraphQLEditForm id={id}
                                         query={"assignmentOutcomes"}
                                         mutations={"assignmentOutcome"}
                                         fields={fields}
                                         cols={3}
                                         extraArgs={"assignment_id:"+assignmentId}
                                         {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
