import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Card, CardContent, CardHeader, Grid} from '@material-ui/core';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';

// country code and int. phone validation
// import ReactPhoneInput from "react-phone-input-material-ui";
import ReactFlagsSelect from 'react-flags-select';
import {useIntl} from 'react-intl';
import themeColors from 'assets/theme/colors';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "0.5rem",
    "& .ReactFlagsSelect-module_selectOptionWithlabel__2GpmM": {
      padding: "0.5rem 1rem",
    },
    "& .ReactFlagsSelect-module_selectOptions__3LNBJ": {
      position: "absolute",
      zIndex: 999999,
      border: "1px solid #bdbbbb",
      borderRadius: "3px",
      marginTop: "8px",
      padding: "8px 0",
      /* max-height: 180px; */
      overflow: "auto",
    },
  },
  btn: {
    border: "none",
  },
  toggleButton: {
    backgroundColor: "transparent",
    color: themeColors.text.primary+" !important",
    // fontSize: "1rem",
    fontWeight: "400",
    textTransform: "uppercase",
    // height: "90%",
    padding: 7,
  }
}));

export default function PhoneSelector({
  assignmentSubject,
  telSelect,
  onCallState,
  selectedCountryState,
  countries
}) {
  const intl = useIntl();
  const classes = useStyles();

  let main = '';
  let direct = '';
  let mobile = '';
  let contact = null;
  let contactIndex = null;
  if (assignmentSubject && assignmentSubject.subject) {
    main = assignmentSubject.subject.main_phone ? assignmentSubject.subject.main_phone.replaceAll(' ', '') : '';
    direct = assignmentSubject.subject.direct_phone ? assignmentSubject.subject.direct_phone.replaceAll(' ', '') : '';
    mobile = assignmentSubject.subject.mobile_phone ? assignmentSubject.subject.mobile_phone.replaceAll(' ', '') : '';
    if (assignmentSubject.subject_contact_id) {
      contactIndex = assignmentSubject.subject.subject_contacts.findIndex(c => c.id === assignmentSubject.subject_contact_id);
      if (contactIndex > -1) {
        contact = assignmentSubject.subject.subject_contacts[contactIndex];
        direct = contact.phone ? contact.phone.replaceAll(' ', '') : '';
        mobile = contact.mobile ? contact.mobile.replaceAll(' ', '') : '';
      }
    }
  }

  const handleChangeTog = (event, nextView) => {
    if (nextView !== null) {
      telSelect.setViewTog(nextView);
    }
  };

  return (
    <Card
      elevation={4}
      style={{
        height: "100%",
      }}
    >
      <CardHeader
          style={{padding:"0.7rem"}}
        title="Telefon nummer"
        subheader={contact ? contact.name.trim() : undefined}
        action={
          <ReactFlagsSelect
              className={classes.root}
              selectButtonClassName={classes.btn}
              selected={selectedCountryState.selectedCountry}
              onSelect={(e) => selectedCountryState.setSelectedCountry(e)}
              fullWidth={false}
              placeholder={intl.formatMessage({id:"dialer.card.phone-selector.country.label", defaultMessage:"Country"})}
              disabled={!!onCallState}
              selectedSize={20}
              showSelectedLabel={false}
              showSecondarySelectedLabel={false}
              showSecondaryOptionLabel
              searchable
              countries={Object.getOwnPropertyNames(countries)}
              customLabels={countries}
          />}
      />
      <CardContent style={{ padding: "0.7rem", alignItems:"flex-end",display:"flex" }}>
        <ToggleButtonGroup
          orientation="vertical"
          value={telSelect.viewTog}
          exclusive
          onChange={handleChangeTog}
          style={{
            width: "100%",
          }}
        >
          <ToggleButton
              className={classes.toggleButton}
            name={'main_phone'}
            value={main ? main : '----no-main----'}
            disabled={!main || !main.length || !!onCallState}
          >
            <Grid container justifyContent="space-between" style={{width:"100%"}}>
              <Grid item>{intl.formatMessage({id:"dialer.cards.phone_select.main", defaultMessage:"Main"})}</Grid>
              <Grid item>{main}</Grid>
            </Grid>
          </ToggleButton>
          <ToggleButton
              className={classes.toggleButton}
            name={'direct_phone'}
            value={direct ? direct : '----no-direct----'}
            disabled={!direct || !direct.length || !!onCallState}
          >
            <Grid container justifyContent="space-between" style={{width:"100%"}}>
              <Grid item>{intl.formatMessage({id:"dialer.cards.phone_select.direct", defaultMessage:"Direct"})}</Grid>
              <Grid item>{direct}</Grid>
            </Grid>
          </ToggleButton>
          <ToggleButton
              className={classes.toggleButton}
            name={'mobile_phone'}
            value={mobile ? mobile : '----no-mobile----'}
            disabled={!mobile || !mobile.length || !!onCallState}
          >
            <Grid container justifyContent="space-between" style={{width:"100%"}}>
              <Grid item>{intl.formatMessage({id:"dialer.cards.phone_select.mobile", defaultMessage:"Mobile"})}</Grid>
              <Grid item>{mobile}</Grid>
            </Grid>
          </ToggleButton>
        </ToggleButtonGroup>
      </CardContent>
    </Card>
  );
}
