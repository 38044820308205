import {Dialog, DialogTitle, DialogContent} from '@material-ui/core';
import {useIntl} from "react-intl";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function ContactDialog({subjectId, open, row, onClose, ...rest}) {
    const intl = useIntl();

    const fields = [
        {
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "subject_contacts.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            field: "title",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "subject_contacts.edit.label.title", defaultMessage: "Title"}),
            input: "text"
        },
        {
            field: "phone",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "subject_contacts.edit.label.phone", defaultMessage: "Phone"}),
            input: "text"
        },
        {
            field: "mobile",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "subject_contacts.edit.label.mobile", defaultMessage: "Mobile"}),
            input: "text"
        },
        {
            field: "email",
            initial: "",
            type: "Email",
            label: intl.formatMessage({id: "subject_contacts.edit.label.email", defaultMessage: "Email"}),
            input: "email"
        },
        {
            field: "department_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "subject_contacts.edit.label.department_id", defaultMessage: "Department"}),
            query: "departments",
            filter: "",
            titleField: "name"
        },
        {
            field: "side_link_contact_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "subject_contacts.edit.label.side_link_contact_id", defaultMessage: "Side Link Contact"}),
            query: "subjectContacts",
            filter: `subject_id:${subjectId}${row ? `id_ne:${row.id}` : ''}`,
            titleField: "name"
        },
        {
            field: "superior_link_contact_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "subject_contacts.edit.label.superior_link_contact_id", defaultMessage: "Superior Link Contact"}),
            query: "subjectContacts",
            filter: `subject_id:${subjectId}${row ? `id_ne:${row.id}` : ''}`,
            titleField: "name"
        },
        {
            field: "down_link_contact_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "subject_contacts.edit.label.down_link_contact_id", defaultMessage: "Down Link Contact"}),
            query: "subjectContacts",
            filter: `subject_id:${subjectId}${row ? `id_ne:${row.id}` : ''}`,
            titleField: "name"
        },
        {
            field: "import_key",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "subject_contacts.edit.label.import_key", defaultMessage: "Import Key"}),
            input: "text"
        }
    ];

    function save(data) {
        onClose(data);
    }

    function close() {
        onClose();
    }

    return (
        <Dialog open={open} onClose={close}>
            <DialogTitle>{intl.formatMessage({
                id: "contact.dialog.title",
                defaultMessage: "Contact"
            })}</DialogTitle>
            <DialogContent>
                <GraphQLEditForm
                    id={row ? parseInt(row.id) : null}
                    query="subjectContacts"
                    mutations="subjectContact"
                    fields={fields}
                    cols={2}
                    extraArgs={subjectId ? `subject_id:${subjectId}` : ''}
                    onSave={save}
                    buttons={[{
                        label: intl.formatMessage({
                            id: "common.button.cancel",
                            defaultMessage: "Cancel"
                        }),
                        onClick: () => close()
                    }]}
                    {...rest}
                />
            </DialogContent>
        </Dialog>
    );
}