import React from 'react'
import { Paper } from '@material-ui/core'
import EnhancedTable from 'components/DataTable/EnhancedTable'
import { People } from '@material-ui/icons'
import { useIntl } from 'react-intl'

export default function AssignmentQuestionList(props) {
    const intl = useIntl();
    let assignmentId = props.match.params && props.match.params.assignmentID;

    const columns = [
        {title: intl.formatMessage({id:'assignment_questions.list.column.sorting', defaultMessage:'Sorting'}), field: 'sorting', type: 'numeric', width: '75px', defaultSort:'asc'},
        {title: intl.formatMessage({id:'assignment_questions.list.column.text', defaultMessage:'Text'}), field: 'text'},
        {title: intl.formatMessage({id:'assignment_questions.list.column.is_export_allowed', defaultMessage:'Is Export Allowed'}), field: 'is_export_allowed', type: 'boolean', initial: false, width: '75px'},
        {title: intl.formatMessage({id:'assignment_questions.list.column.is_answer_required', defaultMessage:'Is Answer Required'}), field: 'is_answer_required', type: 'boolean', initial: false, width: '75px'},
        {title: intl.formatMessage({id:'assignment_questions.list.column.is_multiple_choice', defaultMessage:'Is Multiple Choice'}), field: 'is_multiple_choice', type: 'boolean', initial: false, width: '75px'}
    ];

    return (
      <Paper>
        <EnhancedTable
          title={intl.formatMessage({id:'assignment_questions.list.table.heading', defaultMessage:"Assignment Questions list"})}
          columns={columns}
          query="assignmentQuestions"
          mutations="assignmentQuestion"
          filter={'assignment_id:'+assignmentId}
          fields='id text sorting is_export_allowed is_answer_required is_multiple_choice'
          sorting="sorting"
          icon={<People/>}
          urlState
          {...props}
        />
      </Paper>
    );
}