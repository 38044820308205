import React, {useRef, useState} from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {Add, Delete, Edit, Group} from "@material-ui/icons";
import {useIntl} from "react-intl";
import {ConfirmDialog} from 'components/Dialogs';
import CustomerContactDialog from './CustomerContactDialog';
import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";

export default function CustomerContactList({history, ...props}) {
    const intl = useIntl();
    const tableRef = useRef();
    const [openCustomerContactDialog, setOpenCustomerContactDialog] = useState(false);
    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
    const [activeRow, setActiveRow] = useState(null);

    const client = new graphQLApi(useAuthDispatch(), history);

    const columns = [
        {
            title: intl.formatMessage({
                id: 'customer_contacts.list.column.customer.name',
                defaultMessage: 'Customer Id'
            }),
            field: 'customer.name',
            type: 'string',
            filtering: false,
            width: 'auto'
        },
        {
            title: intl.formatMessage({id: 'customer_contacts.list.column.title', defaultMessage: 'Title'}),
            field: 'title'
        },
        {title: intl.formatMessage({id: 'customer_contacts.list.column.name', defaultMessage: 'Name'}), field: 'name'},
        {
            title: intl.formatMessage({id: 'customer_contacts.list.column.phone', defaultMessage: 'Phone'}),
            field: 'phone'
        },
        {
            title: intl.formatMessage({id: 'customer_contacts.list.column.mobile', defaultMessage: 'Mobile'}),
            field: 'mobile'
        },
        {
            title: intl.formatMessage({id: 'customer_contacts.list.column.email', defaultMessage: 'Email'}),
            field: 'email',
            type: 'string'
        }
    ];

    if (props.customerId) {
        columns.shift();
    }

    function handleAddAction() {
        console.log('Add!');
        setOpenCustomerContactDialog(true);
    }

    function handleEditAction(row) {
        console.log('Edit!');
        setOpenCustomerContactDialog(true);
        setActiveRow(row);
    }

    function handleDeleteAction(row) {
        console.log('Delete!');
        setOpenConfirmDeleteDialog(true);
        setActiveRow(row);
    }

    function handleConfirmDelete(confirmed) {
        setOpenConfirmDeleteDialog(false);

        if (confirmed) {
            client.mutate(`{customerContactDelete(id:${activeRow.id})}`)
                .then(r => {
                    if (r && r.hasOwnProperty("customerContactDelete")) {
                        updateEnhancedTable();
                    }
                });
        }
    }

    function handleCloseCustomerContactDialog() {
        setOpenCustomerContactDialog(false);
        setActiveRow(null);
        updateEnhancedTable();
    }

    function updateEnhancedTable() {
        if(tableRef.current && tableRef.current.hasOwnProperty('update')) {
            tableRef.current.update();
        }
    }

    return (
        <Paper>
            <EnhancedTable
                ref={tableRef}
                softDeletes={true}
                title={intl.formatMessage({
                    id: 'customer_contacts.list.table.heading',
                    defaultMessage: "Consultants"
                })}
                columns={columns}
                query="customerContacts"
                fields='id customer{name} title name phone mobile email'
                filter={props.customerId ? 'customer_id:' + props.customerId : ''}
                icon={<Group/>}
                baseUrl={props.customerId ? history.location.pathname + '/consultant' : null}
                actions={[
                    {
                        icon: Add,
                        isFreeAction: true,
                        tooltip: intl.formatMessage({id: "enhanced_table.actions.add", defaultMessage: "Add"}),
                        onClick: () => handleAddAction()
                    },
                    {
                        icon: Edit,
                        rowClick: true,
                        tooltip: intl.formatMessage({id: "enhanced_table.actions.edit", defaultMessage: "Edit"}),
                        onClick: (row) => handleEditAction(row)
                    },
                    {
                        icon: Delete,
                        tooltip: intl.formatMessage({id: "enhanced_table.actions.delete", defaultMessage: "Delete"}),
                        onClick: (row) => handleDeleteAction(row)
                    }
                ]}
                {...props}
            />
            <CustomerContactDialog open={openCustomerContactDialog} row={activeRow} onClose={handleCloseCustomerContactDialog} {...props} />
            <ConfirmDialog
                onClose={handleConfirmDelete}
                open={openConfirmDeleteDialog}
                title={intl.formatMessage({
                    id: "common.dialog.delete.title",
                    defaultMessage: "Confirm deletion"
                })}
                message={intl.formatMessage({
                    id: "contact.dialog.delete.message",
                    defaultMessage: "Are you sure you want to delete this contact?",
                })}
            />
        </Paper>
    );
}