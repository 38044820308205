import React, {useState} from 'react';
import {NotificationsActive, People} from '@material-ui/icons';
import {useIntl} from 'react-intl';
import {Chip, Paper, Snackbar} from '@material-ui/core';
import {authUser} from 'contexts/Auth';
import moment from 'moment';
import EnhancedTable from 'components/DataTable/EnhancedTable';

moment.locale(window.language);

export default function UserList(props) {
  const intl = useIntl();
  const [showAlert, setShowAlert] = useState(false);
  const columns = [
    {
      title: intl.formatMessage({
        id: "user.list.column.name",
        defaultMessage: "Name",
      }),
      field: "name",
      render: row => (row.title && row.title !== "" ? row.title + ' ' : '') + row.name,
    },
    {
      title: intl.formatMessage({
        id: "user.list.column.email",
        defaultMessage: "Email",
      }),
      field: "email",
    },
    {
      title: intl.formatMessage({
        id: "user.list.column.phone",
        defaultMessage: "Phone",
      }),
      field: "phone",
    },
    {
      title: intl.formatMessage({
        id: "user.list.column.roles",
        defaultMessage: "Roles",
      }),
      field: "roles",
      render: row => {
        return row.roles.map((r, k) => <Chip size="small" key={"user_" + row.id + "_roles_" + k} label={r.title} style={{marginRight:3}}/>)
      },
      sortable: false,
      filtering: false,
    },
    {
      title: intl.formatMessage({
        id: "user.list.column.can_login",
        defaultMessage: "Can login",
      }),
      field: "can_login",
      type: "boolean",
      filtering: false,
      sortable: false,
    },
    {
      title: intl.formatMessage({
        id: "user.list.column.created",
        defaultMessage: "Created",
      }),
      align: "right",
      field: "created_at",
      type: "datetime",
      filtering: false,
    }
  ];

  return (<Paper>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        color="warning"
        icon={NotificationsActive}
        message={intl.formatMessage({
          id: "user.list.alert.not-allowed-to-delete-user",
          defaultMessage: "You are not allowed to delete this user!",
        })}
        open={showAlert}
        onClose={() => setShowAlert(false)}
        autoHideDuration={6000}
      />
      <EnhancedTable
        {...props}
        softDeletes
        deletable={(row) => {
          if (parseInt(row.id) === parseInt(authUser().id)) {
            setShowAlert(true);
            return false;
          }
          return true;
        }}
        title={
          intl.formatMessage({
            id: "user.list.title",
            defaultMessage: "User list",
          })
        }
        columns={columns}
        query="users"
        mutations="user"
        fields="id title name phone email can_login created_at roles{title}"
        icon={<People/>}
        sorting="name"
        direction="asc"
      />
    </Paper>
  );
}
