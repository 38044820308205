import {Paper} from "@material-ui/core";
import {People} from "@material-ui/icons";
import React from "react";
import {useIntl} from "react-intl";
import EnhancedTable from "../../../components/DataTable/EnhancedTable";

export default function RoleList(props) {
  const intl = useIntl();
  const columns = [
    {title: intl.formatMessage({id: 'roles.list.column.title', defaultMessage: 'Title'}), field: 'title'},
    {
      title: intl.formatMessage({id: 'roles.list.column.priority', defaultMessage: 'Priority'}),
      field: 'priority',
      type: 'numeric'
    },
    {
      title: intl.formatMessage({id: 'roles.list.column.description', defaultMessage: 'Description'}),
      field: 'description'
    }
  ];

  return (
    <Paper>
      <EnhancedTable
        title={intl.formatMessage({id: 'roles.list.table.heading', defaultMessage: "Roles list"})}
        columns={columns}
        query="roles"
        mutations="role"
        fields='id title priority description'
        icon={<People/>}
        urlState
        {...props}
      />
    </Paper>
  );
}