import React from 'react';
import {useLocation} from 'react-router-dom';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
// core components
import componentStyles from 'assets/theme/views/auth/login.js';
import {authReset, authSignOut, getApiDomain, useAuthDispatch} from 'contexts/Auth';
import {CardHeader, CircularProgress, FilledInput, FormControl, FormHelperText, InputAdornment} from '@material-ui/core';
import {Email, VpnKey} from '@material-ui/icons';
import {useIntl} from 'react-intl';

const useStyles = makeStyles(componentStyles);

function Reset(props) {
    const intl = useIntl();
    const dispatch = useAuthDispatch();

    const location = useLocation();
    const search = location.search;
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get('token');
    let initialEmail = "";
    if (urlParams.get('email')) initialEmail = urlParams.get('email');

    const [email, setEmail] = React.useState(initialEmail);
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");

    const [isLoading, setIsLoading] = React.useState(false);

    const [domainCheck, setDomainCheck] = React.useState(null);

    React.useEffect(() => {
        if (domainCheck === null) {
            fetch(getApiDomain() + "/", {}).then((response) => {
                if (response.status === 200) {
                    return response.text().then((text) => {
                        if (text.indexOf(process.env.REACT_APP_NAME) !== -1 &&
                            text.indexOf("TINXDK") !== -1) {
                            setDomainCheck(true);
                        } else {
                            setDomainCheck(false);
                            console.error("Response from api check did not validate", text);
                        }
                    });
                } else {
                    setDomainCheck(false);
                }
            }).catch(reason => {
                console.error('Failed to validate the API domain', getApiDomain() + "/", reason);
                setDomainCheck(false);
            });
        }
    });

    const getValidation = (apiValidation) => {
        if (apiValidation.message === "Unauthorized") {
            authSignOut(dispatch);
        }
    };
    
    const performVerify = (e) => {
        e.preventDefault();
        authReset(dispatch, props.history, setIsLoading, token, email, password, confirmPassword, getValidation);
    };

    const matchPasswords = () => {
        return confirmPassword.trim().length > 0 && password !== confirmPassword
    }
    const classes = useStyles();
    return (
        <>
            <Grid item xs={12} lg={5} md={7}>
                <Card classes={{root: classes.cardRoot}}>
                    <CardHeader title={intl.formatMessage({id:"auth.reset_password",defaultMessage:"Reset password"})} classes={{root: classes.cardRoot, title: classes.cardTitle}} />
                    <CardContent classes={{root: classes.cardContent}}>
                        <form onSubmit={performVerify}>
                            <FormControl
                                variant="filled"
                                component={Box}
                                width="100%"
                                marginBottom="1rem!important"
                            >
                                <FilledInput
                                    autoComplete="off"
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Email/>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <FormControl
                                variant="filled"
                                component={Box}
                                width="100%"
                                marginBottom="1rem!important"
                            >
                                <FilledInput
                                    autoComplete="new-password"
                                    type="password"
                                    placeholder="New password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <VpnKey/>
                                        </InputAdornment>
                                    }
                                    error={matchPasswords()}
                                />
                            </FormControl>
                            <FormControl
                                variant="filled"
                                component={Box}
                                width="100%"
                                marginBottom="1rem!important"
                            >
                                <FilledInput
                                    autoComplete="new-password"
                                    type="password"
                                    placeholder="Confirm password"
                                    value={confirmPassword}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <VpnKey/>
                                        </InputAdornment>
                                    }
                                    error={matchPasswords()}
                                />
                            </FormControl>
                            {matchPasswords() && (
                                <>
                                    <FormHelperText>{intl.formatMessage({id: "auth.reset_password_match"})}</FormHelperText>
                                </>
                            )}

                            <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                                {isLoading ?
                                    <CircularProgress/>
                                    :
                                    <Button disabled={!(token && email.trim().length > 0 && email.includes("@") && password.trim().length >= 8 && password === confirmPassword)} type="submit" color="primary" variant="contained" onClick={performVerify}>
                                        {intl.formatMessage({id:"common.button.save"})}
                                    </Button>
                                }
                            </Box>
                        </form>
                    </CardContent>
                </Card>
                <Grid container component={Box} marginTop="1rem">
                    <Grid item xs={6} component={Box} textAlign="left">
                        <Link
                            href="/login"
                            className={classes.footerLinks}
                        >
                            {intl.formatMessage({id: "auth.back_to_login", defaultMessage: "Back to login"})}
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Reset;