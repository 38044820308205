import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/header.js";
import React from "react";

const useStyles = makeStyles(componentStyles);

const Header = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.header}>
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
