import React, {useRef, useState} from 'react';
import {Dialog, DialogContent, DialogTitle, Paper} from '@material-ui/core';
import EnhancedTable from 'components/DataTable/EnhancedTable';
import {Add, Delete, Flare, ToggleOff, ToggleOn} from '@material-ui/icons';
import {useIntl} from 'react-intl';
import {graphQLApi} from 'services/GraphQLApi';
import {useAuthDispatch} from 'contexts/Auth';
import GraphQLEditForm from 'components/GraphQL/GraphQLEditForm';

export default function SubjectFieldsList(props) {
    const intl = useIntl();
    let assignmentId = props.match.params && props.match.params.assignmentID;

    const [showSelectFields, setShowSelectFields] = useState(false);
    const [showAddField, setAddSelectField] = useState(false);

    const columns = [
        {title: intl.formatMessage({id:'subject_fields.list.column.name', defaultMessage:'Name'}), field: 'name'},
        {title: intl.formatMessage({id:'subject_fields.list.column.category', defaultMessage:'Category'}), field: 'category'},
        {title: intl.formatMessage({id:'subject_fields.list.column.import_key', defaultMessage:'Import key'}), field: 'import_key'},
        {title: intl.formatMessage({id:'subject_fields.list.column.is_highlighted', defaultMessage:'Highlighted'}), field: 'is_assignment_highlighted', type: 'boolean'},
    ];

    const tableRef = useRef();
    const client = new graphQLApi(useAuthDispatch(), props.history);
    const handleAddingFields = (rows) => {
      if (rows.length) {
        client.mutate('{assignmentSubjectFieldAdd(assignment_id:' + assignmentId + ', subject_field_ids:[' + rows.map(r => r.id).join(',') + ']){id}}').then(() => {
          if (tableRef.current) {
            tableRef.current.update();
          }
          setShowSelectFields(false);
        });
      }
    }
    const handleRemoveFields = (rows) => {
      if (rows.length) {
        client.mutate('{assignmentSubjectFieldRemove(assignment_id:' + assignmentId + ', subject_field_ids:[' + rows.map(r => r.id).join(',') + ']){id}}').then(() => {
          if (tableRef.current) {
            tableRef.current.clearSelected();
            tableRef.current.update();
          }
        });
      }
    }

    const handleHighlightFields = (rows) => {
      if (rows.length) {
        client.mutate('{assignmentSubjectFieldUpdate(assignment_id:' + assignmentId + ', is_highlighted:true, subject_field_ids:[' + rows.map(r => r.id).join(',') + '])}').then(() => {
          if (tableRef.current) {
            tableRef.current.clearSelected();
            tableRef.current.update();
          }
        });
      }
    }
    const handleRemoveHighlightFields = (rows) => {
          if (rows.length) {
            client.mutate('{assignmentSubjectFieldUpdate(assignment_id:' + assignmentId + ', subject_field_ids:[' + rows.map(r => r.id).join(',') + '], is_highlighted:false)}').then(() => {
              if (tableRef.current) {
                tableRef.current.clearSelected();
                tableRef.current.update();
              }
            });
          }
        }

    return (
      <Paper>
        <EnhancedTable
          {...props}
          ref={tableRef}
          title={intl.formatMessage({id:'subject_fields.list.table.heading', defaultMessage:"Assignments subject fields"})}
          columns={columns}
          query="assignmentSubjectFields"
          fields='id name category import_key is_assignment_highlighted'
          filter={'assignment_id:'+assignmentId}
          sorting={"name"}
          icon={<Flare/>}
          urlState
          actions={[
            {
              tooltip: intl.formatMessage({id:"common.button.add"}),
              icon: Add,
              isFreeAction: true,
              onClick: () => setShowSelectFields(true),
            },
            {
              tooltip: intl.formatMessage({id:"common.button.remove"}),
              icon: Delete,
              onSelected: true,
              onClick: handleRemoveFields,
            },
            {
              tooltip: intl.formatMessage({id:"subject_fields.list.action.highlight", defaultMessage: "Highlight fields"}),
              icon: ToggleOn,
              onSelected: true,
              onClick: handleHighlightFields,
            },
            {
              tooltip: intl.formatMessage({id:"subject_fields.list.action.remove_highlight", defaultMessage: "Remove highlight fields"}),
              icon: ToggleOff,
              onSelected: true,
              onClick: handleRemoveHighlightFields,
            },

          ]}
        />
          <Dialog open={showSelectFields} onClose={() => setShowSelectFields(false)} maxWidth="lg">
            {showAddField && <DialogTitle disableTypography style={{fontSize:24}}>{intl.formatMessage({
              id: 'subject_fields.create.heading',
              defaultMessage: "Create new subject field"
            })}</DialogTitle>}
            <DialogContent style={{overflow: 'auto'}}>{showAddField
                ? <GraphQLEditForm
                    p={2}
                    query="subjectFields"
                    mutations="subjectField"
                    fields={[
                      {field: 'name', type: 'String', initial: '', required: true, label: 'Navn'},
                      {field: 'category', type: 'String', initial: '', label: 'Kategori'},
                      {field: 'import_key', type: 'String', initial: '', label: 'Import nøgle'},
                    ]}
                    onSave={_data => {
                      setAddSelectField(false);
                    }}
                    buttons={[
                      {label: 'Annuller', onClick: () => setAddSelectField(false)},
                    ]}/>
                : (showSelectFields && <EnhancedTable
                    {...props}
                    title={intl.formatMessage({
                      id: 'subject_fields.list.table.heading',
                      defaultMessage: 'Subject fields',
                    })}
                    columns={columns}
                    query="subjectFields"
                    fields="id name category"
                    sorting={"name"}
                    actions={[
                      {
                        label: intl.formatMessage({id: 'subject_fields.list.action.add_selected', defaultMessage:"Add the selected fields"}),
                        icon: Add,
                        onSelected: true,
                        onClick: handleAddingFields,
                      },
                      {
                        tooltip: intl.formatMessage({id: 'common.button.add'}),
                        icon: Add,
                        isFreeAction: true,
                        onClick: () => setAddSelectField(true),
                      },
                    ]}
                    urlState={false}
                />)
            }</DialogContent>
          </Dialog>
      </Paper>
    );
}