import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {DataGridPro, useGridApiRef,} from '@mui/x-data-grid-pro';
import themeColors from '../../assets/theme/colors';
import {Button, CircularProgress, lighten, Snackbar, SnackbarContent, TextField, Typography,} from '@material-ui/core';
import {Grid, MenuItem} from '@mui/material';
import {graphQLApi} from '../../services/GraphQLApi';
import {authUser, useAuthDispatch} from '../../contexts/Auth';
import {makeStyles} from '@material-ui/core/styles';
import componentStyles from '../../assets/theme/components/dialer-subject';
import {Box} from '@mui/system';
import {CloudUpload} from '@material-ui/icons';

const useStyles = makeStyles(componentStyles);

export default function ContactsFilter(props) {
    const intl = useIntl();
    const tableRef = useGridApiRef();
    const classes = useStyles();
    const client = new graphQLApi(useAuthDispatch());
    const {subject, onCopySubjectContact} = props;
    const columns = [
        {
            headerName: intl.formatMessage({id: 'contacts.filter.column.created_at', defaultMessage: 'Created at',}),
            field: 'created_at',
            width: 200,
        },
        {
            headerName: intl.formatMessage({
                id: 'contacts.filter.column.company_name',
                defaultMessage: 'Firmanavn',
            }),
            field: 'subject.name',
            width: 250,
            renderCell: (params) => params.row?.subject?.name
        },
        {
            headerName: intl.formatMessage(
                {id: 'contacts.filter.column.name', defaultMessage: 'Name'}),
            field: 'name',
            width: 250,
        },
        {
            headerName: intl.formatMessage(
                {id: 'contacts.filter.column.phone', defaultMessage: 'Phone'}),
            field: 'phone',
            width: 150,
        },
        {
            headerName: intl.formatMessage({id: 'contacts.filter.column.mobile', defaultMessage: 'Mobile'}),
            field: 'mobile',
            width: 150,
        },
        {
            headerName: intl.formatMessage(
                {id: 'contacts.filter.column.title', defaultMessage: 'Title'}),
            field: 'title',
            width: 250,
        },
        {
            headerName: intl.formatMessage({
                id: 'contacts.filter.column.department',
                defaultMessage: 'Department',
            }),
            field: 'department.title',
            width: 250,
            renderCell: (params) => params.row?.department?.name
        },
        {
            headerName: intl.formatMessage(
                {id: 'contacts.filter.column.email', defaultMessage: 'E-mail'}),
            field: 'email',
            width: 300,
        },
        {
            headerName: intl.formatMessage({id: 'contacts.filter.column.stopped_at', defaultMessage: 'Stopped at',}),
            field: 'stopped_at',
            width: 250,
        },
    ];
    const [isLoading, setIsLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [rows, setRows] = useState([])
    const [ids, setIds] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [departments, setDepartments] = useState([])
    const [message, setMessage] = useState('')

    const [filter, setFilter] = useState({
        subject_name: subject ? subject.name : '',
        name: '',
        title: '',
        department_id: '',
        telephone: ''
    })

    const labels = {
        subject_name: intl.formatMessage({id: 'contacts.filter.label.subject_name', defaultMessage: 'Company'}),
        name: intl.formatMessage({id: 'contacts.filter.label.name', defaultMessage: 'Name'}),
        title: intl.formatMessage({id: 'contacts.filter.label.title', defaultMessage: 'Title'}),
        telephone: intl.formatMessage({id: 'contacts.filter.label.telephone', defaultMessage: 'Telephone'}),
        department_id: intl.formatMessage({id: 'contacts.filter.label.department_id', defaultMessage: 'Department'})
    }

    useEffect(() => {
        client.query('{departments{data{id name}}}').then(r => {
            if (r) setDepartments(r.departments.data)
        })
        if (subject)
            updateSearchResults(filter)
    }, [])

    const getFilterString = (filter) => {
        const obj = {...filter};
        let string = [];
        for (const property in obj) {
            if (obj[property]?.length > 0) {
                string.push(property + ':"' + String(obj[property]).replaceAll('"', '\\"') + '"');
            }
        }
        return string.join(', ');
    }

    const fetchRows = () => {
        setIsLoading(true);
        console.dir(filter);
        client
            .query('{subjectContacts (filter:{' + getFilterString(filter) + '}){total data{id subject{id name} name title department{id name} email phone mobile stopped_at created_at}}}')
            .then(result => {
                if (result) {
                    setRows(result.subjectContacts?.data);
                    setTotal(result.subjectContacts?.total);
                }
                setIsLoading(false);
            })
    }

    let timeout = useRef();
    const debounce = (func, wait, immediate) => {
        return function () {
            let context = this, args = arguments;
            let later = function () {
                timeout.current = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            };
            let callNow = immediate && !timeout.current;
            clearTimeout(timeout.current);
            timeout.current = setTimeout(later, wait);
            if (callNow) {
                func.apply(context, args);
            }
        };
    };

    const debouncedUpdateResults = debounce((filter) => {
        updateSearchResults(filter);
    }, 1750);

    const updateSearchResults = (f) => {
        setIsLoading(true);
        client.query('{' +
            'subjectContacts(limit:100000,filter:{' + getFilterString(f) + '})' +
            '{total data{id}}' +
            '}').then(r => {
            if (r && r.hasOwnProperty('subjectContacts')) {
                setTotal(r.subjectContacts.total);
                setIds(r.subjectContacts.data.map(r => r.id))
            }
            setIsLoading(false);
        });
    };

    const subjectContactsUpdate = (value) => {
        client.mutate('($ids:[ID]!, $stopped:Boolean) {subjectContactsUpdate(ids:$ids, stopped:$stopped) {id stopped_at}}',
            {ids: selectedRows, stopped: value}).then(r => {
            if (r) {
                let contacts = [...rows];
                contacts = contacts.map(f => {
                    if (selectedRows.includes(f.id)) {
                        f.stopped_at = r?.subjectContactsUpdate.find(c => c.id === f.id).stopped_at;
                    }
                    return f;
                })
                setRows(contacts)
                tableRef.current.updateRows(rows)
            }
        })
    }

    const handleCopyTosubject = () => {
        client.mutate(
            '($subject_id:ID!, $contact_id:ID!) {subjectContactCopy(subject_id:$subject_id, contact_id:$contact_id)}',
            {subject_id: subject.id, contact_id: selectedRows[0]}).then(r => {
            if (r?.hasOwnProperty('subjectContactCopy') && r.subjectContactCopy > 0) {
                onCopySubjectContact({...rows.find(c => c.id === selectedRows[0]), id: r.subjectContactCopy});
                setMessage('Copied contact to subject');
            }
        });
    };

    const subjectContactsExport = () => {
        const contactIds = selectedRows.length ? selectedRows : ids;
        client.mutate('{subjectContactsDownload (contact_ids:[' + contactIds + '])}').then(r => {
                if (r && r.subjectContactsDownload) {
                    setMessage(intl.formatMessage(
                        {
                            id: 'assignment_subjects.notification.export_queued',
                            defaultMessage: 'Export job has been queued.',
                        }));
                } else {
                    setMessage(intl.formatMessage({
                        id: 'assignment_subjects.notification.export_queue_failed',
                        defaultMessage: 'Export job could not be queued!',
                    }));
                }
            }
        )
    }

    return (
        <>
            <Snackbar
                open={message.length > 0}
                autoHideDuration={7500}
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                onClose={() => setMessage('')}>
                <SnackbarContent message={message} style={{backgroundColor: themeColors.black}}/>
            </Snackbar>
            <Grid container spacing={1} style={{marginTop: 5, height: 950}}>
                <Grid item xs={12} style={{flexGrow: 1}}>
                    <Grid container spacing={3} >
                        {['subject_name', 'name', 'title', 'telephone'].map(field =>
                            <Grid item style={{width: "20%"}} key={'filter_' + field}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label={labels[field]}
                                    name={field}
                                    value={filter[field]}
                                    onChange={e => {
                                        const newFilter = {...filter, [field]: e.target.value};
                                        setFilter(newFilter);
                                        if (Object.values(newFilter).every(v => v === '')) {
                                            setRows([])
                                            setTotal(0)
                                        } else if (e.target.value.length > 1) {
                                            debouncedUpdateResults(newFilter);
                                        } else if (e.target.value.length <= 1) {
                                            debouncedUpdateResults({...filter, [field]: ''})
                                        }
                                    }}
                                />
                            </Grid>,
                        )}
                        <Grid item style={{width: "20%"}}>
                            <TextField
                                classes={{root: classes.textField}}
                                fullWidth
                                size="small"
                                select
                                label={labels.department_id}
                                id="department_id"
                                value={filter.department_id}
                                onChange={e => {
                                    const newFilter = {...filter, department_id: e.target.value};
                                    setFilter(newFilter);
                                    if (Object.values(newFilter).every(v => v === '')) {
                                        setRows([]);
                                        setTotal(0);
                                    } else {
                                        debouncedUpdateResults(newFilter);
                                    }
                                }}
                            >
                                <MenuItem key={'department-select-none'}
                                          value=""><em>{intl.formatMessage({
                                    id: 'common.select.none',
                                    defaultMessage: 'None',
                                })}</em></MenuItem>
                                {departments?.map(department =>
                                    <MenuItem key={'department-select-' + department.id}
                                              value={department.id}>{department.name}</MenuItem>,
                                )}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{marginTop: 8, marginBottom: 8}}>
                        <Grid item flexGrow={1}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Typography variant="h2">{intl.formatMessage({
                                        id: 'contacts.filter.table.heading',
                                        defaultMessage: 'Search found {total} contacts',
                                    }, {total: total})}</Typography>
                                </Grid>
                                <Grid item>
                                    <Button disabled={isLoading || total < 1}
                                            variant="contained"
                                            color="primary"
                                            onClick={fetchRows}
                                            size={'small'}>
                                        {intl.formatMessage({
                                            id: 'contacts.filter.button.show_results',
                                            defaultMessage: 'Show search results',
                                        })}
                                    </Button>
                                </Grid>
                                {isLoading && <Grid item>
                                    <CircularProgress color="primary" size="2rem"/>
                                </Grid>}
                            </Grid>
                        </Grid>
                        <Grid item flexGrow={0}>
                            <Grid container justifyContent={'right'} style={{padding: 4}}>
                                {authUser().hasRole('admin') && !subject && (ids.length > 0 || selectedRows.length > 0) &&
                                    <Grid item>
                                        <Button style={{marginRight: 8}}
                                                onClick={() => subjectContactsExport()}
                                                size={'small'}><CloudUpload
                                            style={{marginRight: 4}}/>
                                            {selectedRows.length > 0 ? intl.formatMessage({
                                                    id: 'contacts.filter.button.export_selected',
                                                    defaultMessage: 'Export selected',
                                                }) :
                                                intl.formatMessage({
                                                    id: 'contacts.filter.button.export_found',
                                                    defaultMessage: 'Export found contacts',
                                                })
                                            }
                                        </Button>
                                    </Grid>}
                                {selectedRows?.length > 0 &&
                                    <>
                                        <Grid item>
                                            {subject &&
                                                <Button style={{marginRight: 8}}
                                                        onClick={handleCopyTosubject}
                                                        size={'small'}
                                                        disabled={selectedRows?.length > 1}>
                                                    {intl.formatMessage({
                                                        id: 'contacts.filter.button.copy_to_subject',
                                                        defaultMessage: 'Copy to subject',
                                                    })}
                                                </Button>
                                            }
                                        </Grid>
                                        <Grid item>
                                            <Button style={{marginRight: 8}}
                                                    onClick={() => subjectContactsUpdate(true)}
                                                    size={'small'}>
                                                {intl.formatMessage({
                                                    id: 'contacts.filter.button.is_stopped',
                                                    defaultMessage: 'Is stopped',
                                                })}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button style={{marginRight: 8}}
                                                    onClick={() => subjectContactsUpdate(false)}
                                                    size={'small'}>
                                                {intl.formatMessage({
                                                    id: 'contacts.filter.button.is_not_stopped',
                                                    defaultMessage: 'Is not stopped',
                                                })}
                                            </Button>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {rows?.length > 0 &&
                        <Box sx={{height: 800}}>
                            <DataGridPro
                                apiRef={tableRef}
                                initialState={{
                                    sorting: {
                                        sortModel: [{field: 'created_at', sort: 'desc'}],
                                    },
                                }}
                                sx={{
                                    boxShadow: 1,
                                    border: 1,
                                    borderColor: themeColors.gray[200],
                                    '& .MuiDataGrid-cell:hover': {
                                        color: 'primary.main',
                                    },
                                    '& .MuiDataGrid-columnHeaders': {
                                        borderBottom: `1px solid ${themeColors.gray[200]}`,
                                        minHeight: '35px !important',
                                        height: '35px !important',
                                    },
                                    '& .MuiDataGrid-columnHeader': {
                                        borderRightWidth: 0,
                                        minHeight: '35px !important',
                                        height: '35px !important',
                                    },
                                    '& .MuiDataGrid-cell': {
                                        borderRight: `1px solid ${themeColors.gray[200]}`,
                                    },
                                    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                                        borderBottom: `1px solid ${themeColors.gray[200]}`,
                                    },
                                    '& .MuiDataGrid-footerContainer': {
                                        borderTop: `1px solid ${themeColors.gray[200]}`,
                                    },
                                    '& .MuiDataGrid-columnSeparator': {
                                        color: themeColors.gray[200],
                                    },
                                    '& .row-marked': {
                                        backgroundColor: lighten(themeColors.error.main, 0.8),
                                        '&.Mui-hovered': {
                                            backgroundColor: lighten(themeColors.error.main,
                                                0.7),
                                        },
                                    },
                                }}
                                rows={rows}
                                columns={columns}
                                rowSelectionModel={selectedRows}
                                onRowSelectionModelChange={setSelectedRows}
                                rowHeight={27}
                                headerHeight={35}
                                pageSize={20000}
                                tool
                            />
                        </Box>
                    }
                </Grid>
            </Grid>

        </>
    );

}
