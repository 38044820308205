import React from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {AccountTree} from "@material-ui/icons";
import {useIntl} from "react-intl";

export default function DepartmentList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'departments.list.column.name', defaultMessage:'Name'}), field: 'name'}
    ];

    return (
      <Paper>
        <EnhancedTable
          softDeletes={true}
          title={intl.formatMessage({id:'departments.list.table.heading', defaultMessage:"Departments list"})}
          columns={columns}
          query="departments"
          mutations="department"
          fields='id name'
          sorting="name"
          direction="asc"
          icon={<AccountTree/>}
          {...props}
        />
      </Paper>
    );
}