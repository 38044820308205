const themeColors = {
  white: {
    main: "#f7f7f7",
  },
  black: {
    light: "#12263F",
    main: "#000000",
  },
  transparent: {
    main: "transparent",
  },
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  primary: {
    main: "#2D667D",
    light: "#578497",
    dark: "#1F4757",
    snackbar: "#578497",
    badgeBg: "#2D667D",
    badgeBgHover: "#578497",
    badge: "#1F4757",
    text: "#ECF0F1"
  },
  secondary: {
    main: "#E2924A",
    light: "#E7A76E",
    dark: "#9E6633",
    snackbar: "#E7A76E",
    badgeBg: "#E2924A",
    badgeBgHover: "#1c1c1c",
    btnOutline: "#9E6633",
    btnActive: "#E7A76E",
    text: "#1c1c1c"
  },
  warning: {
    light: "#ffd600",
    main: "#fb6340",
    snackbar: "#fc7c5f",
    badgeBg: "#fee6e0",
    badgeBgHover: "#f93305",
    badge: "#ff3709",
  },
  error: {
    light: "#f3a4b5",
    main: "#f5365c",
    snackbar: "#f75676",
    badgeBg: "#fdd1da",
    badgeBgHover: "#e30b36",
    badge: "#f80031",
    dialogNotification: "#f56036",
  },
  info: {
    main: "#11cdef",
    snackbar: "#37d5f2",
    badgeBg: "#aaedf9",
    badgeBgHover: "#0c9cb7",
    badge: "#03acca",
  },
  background: {
    default: "#e1e1e1",
  },
  text: {
    primary: "#000",
  },
  dark: {
    tableBorder: "#1f3a68",
    tableHeadColor: "#4d7bca",
    tableHeadBgColor: "#1c345d",
    main: "#172b4d",
    dark: "#0b1526",
    snackbar: "#3c4d69",
    badgeBg: "#4172c6",
    badgeBgHover: "#09111e",
  },
  success: {
    main: "#2dce89",
    snackbar: "#4fd69c",
    badgeBg: "#b0eed3",
    badgeBgHover: "#229c68",
    badge: "#1aae6f",
  },
  colors: [
      '#db5c57',
      '#6a92e8',
      '#9bcc4d',
      '#8158f4',
      '#e0c764',
      '#eae71e',
      '#f48db8'
  ]
};

export default themeColors;
