import {useIntl} from 'react-intl';
import {Card, CardContent, CardHeader, Grid} from '@material-ui/core';
import {Assignment} from '@material-ui/icons';
import ContactsFilter from '../../components/ContactsFilter';

export default function Contacts() {
  const intl = useIntl();

  return (
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardHeader
                avatar={<Assignment color="primary"/>}
                title={intl.formatMessage({id: "contacts.heading", defaultMessage:"Contacts"})}
                titleTypographyProps={{color:"primary"}}
            />
            <CardContent>
                <ContactsFilter/>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
  );
}
