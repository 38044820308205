import {Backdrop, Button, Card, CardContent, CardHeader, Dialog, DialogContent, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Add, Edit, Mail} from '@material-ui/icons';
import EditForm from 'components/Form/EditForm';
import {useAuthDispatch} from 'contexts/Auth';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {graphQLApi, graphQLReduceFields} from 'services/GraphQLApi';
import AssignmentEmailTemplateEdit from 'views/Assignment/Email/AssignmentEmailTemplateEdit';
import placeholderButton from 'components/Editor/placeholderButton';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function AssignmentEmailSettingEdit(props) {
  const classes = useStyles();
  const intl = useIntl();
  let assignmentId = props.match.params && props.match.params.assignmentID;

  const [isLoading, setIsLoading] = useState(false);
  const [template, setTemplate] = useState(null);

  const [fields, setFields] = useState([
    {
      column: 1,
      field: "subject_sender_name",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "assignment_email_settings.edit.label.subject_sender_name",
        defaultMessage: "Subject sender name"
      }),
      input: "text"
    },
    {
      column: 1,
      field: "subject_sender_email",
      initial: "",
      type: "Email",
      label: intl.formatMessage({
        id: "assignment_email_settings.edit.label.subject_sender_email",
        defaultMessage: "Subject sender email"
      }),
      input: "email"
    },
    {
      column: 2,
      field: "report_sender_name",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "assignment_email_settings.edit.label.report_sender_name",
        defaultMessage: "Report sender name"
      }),
      input: "text"
    },
    {
      column: 2,
      field: "report_sender_email",
      initial: "",
      type: "Email",
      label: intl.formatMessage({
        id: "assignment_email_settings.edit.label.report_sender_email",
        defaultMessage: "Report sender email"
      }),
      input: "email"
    },
    {
      column: 2,
      field: "report_receiver_email",
      initial: "",
      type: "Email",
      label: intl.formatMessage({
        id: "assignment_email_settings.edit.label.report_receiver_email",
        defaultMessage: "Report receiver email"
      }),
      input: "string"
    },
    {
      column: 3,
      field: "confirmation_template{id}",
      initial: null,
      label: '',
      render: (key, data) => getTemplateEditButton('confirmation_template', key, data)
    },
    {
      column: 3,
      field: "direct_template{id}",
      initial: null,
      label: '',
      render: (key, data) => getTemplateEditButton('direct_template', key, data)
    },
    {
      column: 3,
      field: "alternative_1_template{id}",
      initial: null,
      label: '',
      render: (key, data) => getTemplateEditButton('alternative_1_template', key, data)
    },
    {
      column: 3,
      field: "alternative_2_template{id}",
      initial: null,
      label: '',
      render: (key, data) => getTemplateEditButton('alternative_2_template', key, data)
    },
  ]);

  const [data, setData] = useState(graphQLReduceFields(fields));
  const initialValidation = graphQLReduceFields(fields, 'validation');
  const [validation, setValidation] = useState(initialValidation);

  const initialPlaceholderOptions = [
    {
      id: "name",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.name",
        defaultMessage: "Name"
      })
    },
    {
      id: "assignment.name",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.assignment.name",
        defaultMessage: "Assignment"
      })
    },
    {
      id: "addressLine1",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.addressLine1",
        defaultMessage: "Address Line 1"
      })
    },
    {
      id: "addressLine2",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.addressLine2",
        defaultMessage: "Address Line 2"
      })
    },
    {
      id: "postcode",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.postcode",
        defaultMessage: "Postal Code"
      })
    },
    {
      id: "city",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.city",
        defaultMessage: "City"
      })
    },
    {
      id: "state",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.state",
        defaultMessage: "State"
      })
    },
    {
      id: "countryCode",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.country_code",
        defaultMessage: "Country code"
      })
    },
    {
      id: "meetingAddressLine1",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.meetingAddressLine1",
        defaultMessage: "Meeting Address Line 1"
      })
    },
    {
      id: "meetingAddressLine2",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.meetingAddressLine2",
        defaultMessage: "Meeting Address Line 2"
      })
    },
    {
      id: "meetingPostcode",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.meetingPostcode",
        defaultMessage: "Meeting Postal Code"
      })
    },
    {
      id: "meetingCity",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.meetingCity",
        defaultMessage: "Meeting City"
      })
    },
    {
      id: "email",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.email",
        defaultMessage: "Email"
      })
    },
    {
      id: "landlineNo",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.landlineNo",
        defaultMessage: "Landline Number"
      })
    },
    {
      id: "directNo",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.directNo",
        defaultMessage: "Direct Number"
      })
    },
    {
      id: "cellNo",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.cellNo",
        defaultMessage: "Cell Number"
      })
    },
    {
      id: "businessType",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.businessType",
        defaultMessage: "Business Type"
      })
    },
    {
      id: "employees",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.employees",
        defaultMessage: "Employees"
      })
    },
    {
      id:"www",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.www",
        defaultMessage: "Website"
      })
    },
    {
      id: "nameContact",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.nameContact",
        defaultMessage: "Name Contact"
      })
    },
    {
      id: "titleContact",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.titleContact",
        defaultMessage: "Title Contact"
      })
    },
    {
      id: "emailContact",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.emailContact",
        defaultMessage: "Email Contact"
      })
    },
    {
      id: "departmentIDContact",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.departmentIDContact",
        defaultMessage: "Department ID Contact"
      })
    },
    {
      id: "handlingComment",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.handlingComment",
        defaultMessage: "Handling Comment"
      })
    },
    {
      id: "externalComment",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.externalComment",
        defaultMessage: "External Comment"
      })
    },
    {
      id: "lastEditedByManager",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.lastEditedByManager",
        defaultMessage: "Last Edited By Manager"
      })
    },
    {
      id: "lastEditedByPhoner",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.lastEditedByPhoner",
        defaultMessage: "Last Edited By Phoner"
      })
    },
    {
      id: "meetingEstablished",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.meetingEstablished",
        defaultMessage: "Meeting Established"
      })
    },
    {
      id: "nextContact",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.nextContact",
        defaultMessage: "Next Contact"
      })
    },
    {
      id: "outcomeID",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.outcomeID",
        defaultMessage: "Outcome"
      })
    },
    {
      id: "phonerID",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.phonerID",
        defaultMessage: "Phoner"
      })
    },
    {
      id: "todaysDate",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.todaysDate",
        defaultMessage: "Todays Date"
      })
    },
    {
      id: "questionnaire",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.questionnaire",
        defaultMessage: "Questionnaire"
      })
    },
    {
      id: "allContacts",
      type: 'static',
      name: intl.formatMessage({
        id: "templates.editor.placeholder.allContacts",
        defaultMessage: "All Contacts"
      })
    },
  ];
  const [placeholderOptions, setPlaceholderOptions] = useState(initialPlaceholderOptions);

  const client = new graphQLApi(useAuthDispatch(), props.history, null, {handleErrors: (errors) => {
      if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
        setValidation({...initialValidation, ...errors[0].extensions.validation});
      }
    }});

  useEffect(() => {
    if (assignmentId) {
      setIsLoading(true);
      client.query('{response : assignmentEmailSettings(filter:{assignment_id:' + assignmentId + '}){data{' +
          'id subject_sender_name subject_sender_email report_sender_name report_sender_email report_receiver_email assignment{name subject_fields{id name} questions{id sorting text}} report_template confirmation_template{id} direct_template{id} alternative_1_template{id} alternative_2_template{id} phoner_information signature ' +
          '}}}')
        .then(result => {
          if (result && result.hasOwnProperty('response')) {
            const newPlaceholders = initialPlaceholderOptions;

            if (result.response.data[0].assignment.hasOwnProperty('subject_fields')) {
              result.response.data[0].assignment.subject_fields.sort((a, b) => a.name.localeCompare(b.name)).forEach(field => {
                newPlaceholders.push({
                  type: 'subject',
                  ...field
                });
              });
            }
            if (result.response.data[0].assignment.hasOwnProperty('questions')) {
              result.response.data[0].assignment.questions.filter(q => q.text && q.text.length).forEach((question, index) => {
                newPlaceholders.push({
                  type: 'question',
                  name: `Q${index+1}: ${question.text.length > 0 ? question.text : `!ID:${question.id}`}`,
                  ...question
                });
              });
            }

            setPlaceholderOptions(newPlaceholders);
            const signatureOptions = [
              {
                id: "subjectsCount",
                type: 'static',
                name: intl.formatMessage({
                  id: "templates.editor.placeholder.subjectsCount",
                  defaultMessage: "Number of subjects"
                })
              },
            ];
            const manuscriptOptions = [
              {
                id: "assignment.name",
                type: 'static',
                name: intl.formatMessage({
                  id: "templates.editor.placeholder.assignment.name",
                  defaultMessage: "Assignment"
                })
              },
            ];
            setData(result.response.data[0]);
            const fieldsWithDynamicOptions = [
              {
                fullWidth: true,
                field: "phoner_information",
                initial: "",
                type: "String",
                label: intl.formatMessage({
                  id: "assignment_email_settings.edit.label.phoner_information",
                  defaultMessage: "Phoner information"
                }),
                input: "html",
                config: {
                  extraButtons: [placeholderButton(intl, manuscriptOptions)]
                },
                filebrowser: {
                  model: 'assignment',
                  modelId: assignmentId
                },
              },
              {
                fullWidth: true,
                field: "report_template",
                initial: "",
                type: "String",
                label: intl.formatMessage({
                  id: "assignment_email_settings.edit.label.report_template",
                  defaultMessage: "Assignment report"
                }),
                input: "html",
                config: {
                  extraButtons: [placeholderButton(intl, placeholderOptions)]
                },
                filebrowser: {
                  model: 'assignment',
                  modelId: assignmentId
                }
              },
              {
                fullWidth: true,
                field: "signature",
                initial: "",
                type: "String",
                label: intl.formatMessage({id: "assignment_email_settings.edit.label.signature", defaultMessage: "Signature"}),
                input: "html",
                config: {
                  extraButtons: [placeholderButton(intl, signatureOptions)]
                },
                filebrowser: {
                  model: 'assignment',
                  modelId: assignmentId
                }
              },
            ];
            setValidation(state => Object.assign({}, state, graphQLReduceFields(fieldsWithDynamicOptions, 'validation')));
            setFields(state => [...state.filter(f => f.input !== "html"), ...fieldsWithDynamicOptions]);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  const TYPE_CONFIRMATION = 'confirmation_template';
  const TYPE_DIRECT = 'direct_template';
  const TYPE_ALT_1 = 'alternative_1_template';
  const TYPE_ALT_2 = 'alternative_2_template';

  const getTemplateEditButton = (type, key, data) => {
    let label = "";
    let action = data[type] ?
      intl.formatMessage({id: "action.edit", defaultMessage: "Edit"}) :
      intl.formatMessage({id: "action.create", defaultMessage: "Create"});
    switch (type) {
      default:
        break;
      case TYPE_CONFIRMATION:
        label = intl.formatMessage({
            id: "assignment_email_settings.edit.label.confirmation_template",
            defaultMessage: "{action} Confirmation Template"
          }, {action: action}
        );
        break;
      case TYPE_DIRECT:
        label = intl.formatMessage({
            id: "assignment_email_settings.edit.label.direct_template",
            defaultMessage: "{action} Direct Template"
          }, {action: action}
        );
        break;
      case TYPE_ALT_1:
        label = intl.formatMessage({
            id: "assignment_email_settings.edit.label.alternate_1_template",
            defaultMessage: "{action} Alternative 1 Template"
          }, {action: action}
        );
        break;
      case TYPE_ALT_2:
        label = intl.formatMessage({
            id: "assignment_email_settings.edit.label.alternate_2_template",
            defaultMessage: "{action} Alternative 2 Template"
          }, {action: action}
        );
        break;
    }
    return (
      <Button
        disabled={!data || !data.id}
        container
        fullWidth
        variant="outlined"
        key={"template_" + key}
        startIcon={data[type] ? <Edit/> : <Add/>}
        onClick={() => setTemplate({id: data[type] ? data[type].id : 0, type: type})}
      >
        {label}
      </Button>
    );
  }

  const handleSaving = (settings) => {
    const variables = {
      subject_sender_name: 'String',
      subject_sender_email: 'String',
      report_sender_name: 'String',
      report_sender_email: 'String',
      report_receiver_email: 'String',
      report_template: 'String',
      confirmation_template_id: 'ID',
      direct_template_id: 'ID',
      alternative_1_template_id: 'ID',
      alternative_2_template_id: 'ID',
      phoner_information: 'String',
      signature: 'String'
    };
    if (data.id) {
      variables.id = 'ID!';
    }
    setIsLoading(true);
    client.mutation('assignmentEmailSetting', variables, settings, "id")
      .then(r => {
        if (r && r.hasOwnProperty("response")) {
          setData(cur => ({...cur, id: r.response.id}));
        }
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <Grid container>
        <Backdrop className={classes.backdrop} open={isLoading}/>
        <Dialog open={template !== null} onClose={() => setTemplate(null)} maxWidth="xl">
          <DialogContent>
            <AssignmentEmailTemplateEdit
              id={template ? template.id : 0}
              assignmentId={assignmentId}
              settingsId={data ? data.id : null}
              templateType={template ? template.type : null}
              onSave={templateData => {
                setData({...data, [template.type]: templateData})
                setTemplate(null);
              }}
              options={placeholderOptions}
              buttons={[{
                label: intl.formatMessage({id: "common.button.close", defaultMessage: "Close"}),
                onClick: () => setTemplate(null)
              }]}
            />
          </DialogContent>
        </Dialog>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={<Mail color="primary"/>}
              title={intl.formatMessage({
                id: "assignment_email_settings.edit.heading",
                defaultMessage: "Assignment Email Settings edit"
              })}
              titleTypographyProps={{color: "primary"}}
            />
            <CardContent>
              <EditForm
                  fields={fields}
                  cols={3}
                  validation={validation}
                  isLoading={isLoading}
                  data={data}
                  setData={setData}
                  save={handleSaving}
                  {...props}
              />
            </CardContent>
          </Card>
        </Grid>
    </Grid>
  );
}
