import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// @material-ui/core components
// @material-ui/icons components
// javascipt plugin for creating charts
// core components
import Header from "components/Headers/Header.js";
import {authUser} from "contexts/Auth";
import React from "react";
// react plugin used to create charts
import AssignmentList from "views/Admin/Assignments/AssignmentList";

function Dashboard(props) {
  // let assignmentId = props.match.params && props.match.params.assignmentID;

  return (
    <Box mt={-3.5} ml={-3} mr={-3} mb={1}>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-10rem"
        marginX="2rem"
      >
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} style={{textAlign:"center",marginTop:35}}>
            <AssignmentList {...props} filter={"manager_id:" + authUser().id}/>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Dashboard;
