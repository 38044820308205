import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';

import {LocaleContextProvider} from 'contexts/Intl';
import {AuthProvider} from 'contexts/Auth';
import {createBrowserHistory} from 'history';
import App from 'contexts/App';
import {LicenseInfo} from '@mui/x-license-pro';
import {config} from "./config";

if (!!process.env.APP_ENV && !!config.sentryDsn) {
    Sentry.init({
        dsn: config.sentryDsn,
        integrations: [new BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.05,
        release: process.env.COMMIT_HASH ? process.env.COMMIT_HASH : '',
        environment: process.env.APP_ENV,
    });
}
LicenseInfo.setLicenseKey('cc2a644d6d486620381e0107131db028Tz05NzkwMSxFPTE3NTc2NjM5MzgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==');

// Disable missing translation message as translations will be added later.
// We can add a toggle for this later when we have most translations.

// eslint-disable-next-line
const consoleError = console.error.bind(console);
// eslint-disable-next-line
console.error = (message, ...args) => {
  if (
    String(message).indexOf('MISSING_TRANSLATION') > 0
  ) {
    return;
  }
  consoleError(message, ...args);
};

const hist = createBrowserHistory();

ReactDOM.render(
    <AuthProvider history={hist}>
        <LocaleContextProvider>
            <App history={hist}/>
        </LocaleContextProvider>
    </AuthProvider>,
    document.querySelector("#root")
);
