import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';
import {graphQLApi} from 'services/GraphQLApi';
import {useAuthDispatch} from 'contexts/Auth';
import PropTypes from 'prop-types';
import {Close} from '@material-ui/icons';

export default function SendSms({
  open,
  onClose,
  assignmentSubject,
  assignmentId,
  getSubjectContactField,
  history,
}) {
  const intl = useIntl();
  const client = new graphQLApi(useAuthDispatch(), history);
  const [_isLoading, setIsLoading] = useState(false);
  const messageDefaults = {
    number: '',
    message: '',
    assignment_id: assignmentId,
    assignment_subject_id: assignmentSubject?.id,
    subject_id: assignmentSubject?.subject.id,
    subject_contact_id: assignmentSubject?.subject_contact_id,
  };
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState(messageDefaults);

  useEffect(() => {
    setMessage(messageDefaults);
    if (!assignmentSubject) {
      return;
    }
    setIsLoading(true);
    client.mutate('{' +
        'assignmentSmsParse(assignment_subject_id:' + assignmentSubject.id + ')' +
        '{number message}' +
        '}').then(r => {
      if (r && r.hasOwnProperty('assignmentSmsParse')) {
        setMessage({...message, ...r.assignmentSmsParse});
      }
      setIsLoading(false);
    });
  }, [assignmentSubject]);

  const handleSendMessage = () => {
    setIsSending(true);
    console.log(assignmentSubject);
    client.mutate('($assignment_subject_id:ID $subject_id:ID $subject_contact_id:ID $number:String $message:String) {' +
        'smsMessageCreate(assignment_subject_id:$assignment_subject_id subject_id:$subject_id subject_contact_id:$subject_contact_id message:$message number:$number)' +
        '{id status error} }', message).then(r => {
      setIsSending(false);
      console.log(r);
      if (r && r.hasOwnProperty('smsMessageCreate')) {
        onClose(r.smsMessageCreate);
      }
    });
  };

  return <Dialog open={open} onClose={onClose} maxWidth="sm">
    <DialogTitle disableTypography
                 style={{display: 'flex', justifyContent: 'space-between'}}>
      <Typography variant="h2" style={{width: 300}}>{intl.formatMessage({
        id: 'dialer.dialogs.send_message.title',
        defaultMessage: 'Send SMS message',
      })}</Typography>

      <Box style={{width: 300, textAlign: 'right'}}>
        <IconButton size="small" onClick={onClose}><Close/></IconButton>
      </Box>
    </DialogTitle>
    <DialogContent
        style={{
          maxHeight: '80vh',
          overflow: 'auto',
        }}>
      {/*<Backdrop open={isLoading}>*/}
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <TextField
              error={!getSubjectContactField('name')}
              fullWidth
              size="small"
              variant="outlined"
              label={intl.formatMessage({
                id: 'dialer.dialogs.send_sms.fields.to_name',
                defaultMessage: 'To name',
              })}
              defaultValue={getSubjectContactField('name')}
              disabled
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
              error={!getSubjectContactField('mobile')}
              fullWidth
              size="small"
              variant="outlined"
              label={intl.formatMessage({
                id: 'dialer.dialogs.send_sms.fields.mobile',
                defaultMessage: 'Mobile',
              })}
              value={getSubjectContactField('mobile')}
              disabled
          />
        </Grid>
        <Grid item xs={2}>{isSending ? <CircularProgress/> :
            <Button
                disabled={!getSubjectContactField('mobile') || !message.message}
                variant="contained"
                color="primary"
                fullWidth
                style={{height: '100%'}}
                onClick={() => handleSendMessage()}
            >{intl.formatMessage({
              id: 'dialer.dialogs.send_sms.button',
              defaultMessage: 'Send',
            })}</Button>}
        </Grid>
        <Grid item xs={12}>
          <TextField
              fullWidth
              size="small"
              variant="outlined"
              label={intl.formatMessage({
                id: 'dialer.dialogs.send_sms.fields.message',
                defaultMessage: 'Message',
              })}
              value={message.message}
              multiline
              minRows={20}
              helperText={intl.formatMessage(
                  {id: 'dialer.dialogs.send_sms.fields.message_help', defaultMessage: 'Message length is {length} characters which counts as {count} SMS messages.'},
                  {length: message.message.length, count: Math.ceil(message.message.length / 160)})}
          />
        </Grid>
      </Grid>
      {/*</Backdrop>*/}
    </DialogContent>
  </Dialog>;
}

SendSms.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  assignmentSubject: PropTypes.number.isRequired,
  assignmentId: PropTypes.number.isRequired,
  getSubjectContactField: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};
