import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import GraphQLEditForm from 'components/GraphQL/GraphQLEditForm';
import DropzoneUploader from '../../../components/Dropzone/DropzoneUploader';
import {IconButton, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@material-ui/core';
import {AttachFile, Delete, OpenInNew} from '@material-ui/icons';
import {graphQLApi} from '../../../services/GraphQLApi';
import {useAuthDispatch} from '../../../contexts/Auth';
import {ConfirmDialog} from '../../../components/Dialogs';
import moment from 'moment';
import placeholderButton from 'components/Editor/placeholderButton';

export default function AssignmentEmailTemplateEdit(props) {
  const intl = useIntl();
  const client = new graphQLApi(useAuthDispatch(), props.history);

  const fields = [
    {
      column: 1,
      field: 'cc_email',
      initial: '',
      type: 'String',
      label: intl.formatMessage({
        id: 'assignment_email_templates.edit.label.cc_email',
        defaultMessage: 'CC',
      }),
      input: 'email',
    },
    {
      column: 2,
      field: 'bcc_email',
      initial: '',
      type: 'String',
      label: intl.formatMessage({
        id: 'assignment_email_templates.edit.label.bcc_email',
        defaultMessage: 'BCC',
      }),
      input: 'email',
    },
    {
      fullWidth: true,
      field: 'subject',
      initial: '',
      type: 'String',
      label: intl.formatMessage({
        id: 'assignment_email_templates.edit.label.subject',
        defaultMessage: 'Subject',
      }),
      input: 'text',
    },
    {
      fullWidth: true,
      field: 'body',
      initial: '',
      type: 'String',
      label: intl.formatMessage({
        id: 'assignment_email_templates.edit.label.body',
        defaultMessage: 'Body',
      }),
      input: "html",
      config: {
        extraButtons: [placeholderButton(intl, props.options)]
      },
      filebrowser: {
        model: 'assignment',
        modelId: props.assignmentId
      }
    },
  ];

  const [showConfirmDelete, setShowConfirmDelete] = useState(null);

  return (
      <GraphQLEditForm
          id={props.id}
          query={'assignmentEmailTemplates'}
          mutations={'assignmentEmailTemplate'}
          extraFields={' attachments{id file file_type file_uri file_thumb created_at}'}
          extraArgs={props.id ? '' : ' assignment_email_setting_template_type:"'+props.templateType+'" assignment_email_setting_id:'+props.settingsId}
          fields={fields}
          cols={2}
          onSave={props.onSave}
          buttons={props.buttons}
          extraComponent={(data, setData) => props.id && <DropzoneUploader
                onUploaded={d => {
                  console.log('UPLOADED', d);
                  setData({...data, attachments: [...data.attachments, ...d]});
                }}
                mutation="AssignmentEmailTemplateAttachment"
                attributes={{assignment_email_template_id: props.id}}
            ><ConfirmDialog
              onClose={() => {
                client.mutate('{assignmentEmailTemplateAttachmentDelete(id:'+showConfirmDelete.id+')}');
                setData({...data, attachments: data.attachments.filter(a => a.id !== showConfirmDelete.id)});
                setShowConfirmDelete(null);
              }}
              open={showConfirmDelete !== null}
              title={intl.formatMessage({
                id: 'assignment_email_templates.edit.confirm_delete.title',
                defaultMessage: 'Confirm deletion of attachment',
              })}
              message={intl.formatMessage({
                id: 'assignment_email_templates.edit.confirm_delete.message',
                defaultMessage: 'Please confirm that you want to delete the attachment with the filename {file}',
              }, {file: showConfirmDelete ? showConfirmDelete.file : '-'})}/>
            <InputLabel style={{marginBottom: 6}}>{intl.formatMessage({
              id: 'assignment_email_templates.edit.label.attachments',
              defaultMessage: 'Attachments',
            })}</InputLabel>{data.attachments && data.attachments.length ? <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{intl.formatMessage({
                      id: 'assignment_email_templates.edit.table.attachments.column.thumb',
                      defaultMessage: 'Thumbnail',
                    })}</TableCell>
                    <TableCell>{intl.formatMessage({
                      id: 'assignment_email_templates.edit.table.attachments.column.filename',
                      defaultMessage: 'Filename',
                    })}</TableCell>
                    <TableCell>{intl.formatMessage({
                      id: 'assignment_email_templates.edit.table.attachments.column.filetype',
                      defaultMessage: 'Type',
                    })}</TableCell>
                    <TableCell>{intl.formatMessage({
                      id: 'assignment_email_templates.edit.table.attachments.column.created_at',
                      defaultMessage: 'Created',
                    })}</TableCell>
                    <TableCell width={50}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.attachments.map(a =>
                      <TableRow key={'attachment_' + a.id}>
                        <TableCell>{a.file_thumb ? <img src={a.file_thumb} alt={a.file}
                                         height={24}/> : <AttachFile/>}</TableCell>
                        <TableCell>{a.file}</TableCell>
                        <TableCell>{a.file_type}</TableCell>
                        <TableCell>{moment(a.created_at).format(intl.formatMessage({id:"common.datetime.format"}))}</TableCell>
                        <TableCell align="right" style={{
                          display: 'flex',
                          gap: 6,
                          flexDirection: 'row',
                        }}>
                          <IconButton
                              onClick={() => window.open(a.file_uri, '_blank')}
                              size="small"><OpenInNew/></IconButton>
                          <IconButton
                              onClick={() => setShowConfirmDelete(a)}
                              size="small"><Delete/></IconButton>
                        </TableCell>
                      </TableRow>)}
                </TableBody>
              </Table>
            </TableContainer> : <Typography variant="subtitle2">{intl.formatMessage({
              id: 'assignment_email_templates.edit.table.attachments.no_attachments',
              defaultMessage: 'There are no attachments on this template',
            })}</Typography>}
            </DropzoneUploader>}
      />
  );
}
