import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import EditForm from '../Form/EditForm';
import {graphQLApi, graphQLReduceFields} from '../../services/GraphQLApi';
import {useAuthDispatch} from '../../contexts/Auth';
import {NotificationsActive} from '@material-ui/icons';
import {useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Box, Snackbar} from '@material-ui/core';

const GraphQLEditForm = forwardRef((props, ref) => {
  const {
    id,
    query,
    mutations,
    fields,
    cols,
    colSizes,
    redirectToEdit,
    extraArgs = "",
    extraFields = "",
    extraComponent = null,
    buttons = [],
    onSave,
    onLoad,
    preSave,
    ...others
  } = props;
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState(false);

  const [notification, setNotification] = useState({
    type: undefined,
    msg: "",
  });
  const hideAlert = () => {
    setNotification({ type: undefined, msg: "" });
  };
  useEffect(() => {
    if (notification) {
      setTimeout(hideAlert, 7500);
    }
  }, [notification]);

  const initialValidation = graphQLReduceFields(fields, "validation");
  const [validation, setValidation] = useState(initialValidation);
  const setValidationFromErrors = (errors) => {
    if (errors && errors[0] && errors[0]["extensions"]) {
      if (errors[0]["extensions"]["validation"]) {
        setValidation({...initialValidation, ...errors[0]["extensions"]["validation"]});
      }
      if (errors[0]["extensions"]["category"] === 'internal') {
        alert(errors[0]["message"] + '\n' + errors[0]["debugMessage"]);
      }
    }
    setIsLoading(false);
  };
  const client = new graphQLApi(
      useAuthDispatch(),
      props.history,
      setValidationFromErrors
  );

  const [data, setData] = useState(graphQLReduceFields(fields, "initial"));
  const setDataFromQuery = (qData, extraData = {}) => {
    for (let i in fields) {
      if (fields[i].key) {
        if (qData[fields[i].field] === undefined) {
          qData[fields[i].field] = {};
        }
        let value = "";
        let rawData = qData[fields[i].field];
        qData[fields[i].field] = {};
        for (let k in rawData) {
          let fieldKey = rawData[k][fields[i].key];
          if (fields[i].titleField.indexOf(".") !== -1) {
            value = fields[i].titleField
                .split(".")
                .reduce((o, k) => o[k], rawData[k]);
          } else {
            value = rawData[k][fields[i].titleField];
          }
          if (value === null || value === undefined) {
            value = fields[i].initial;
          }
          qData[fields[i].field] = {
            ...qData[fields[i].field],
            [fieldKey]: value,
          };
        }
      } else {
        if (fields[i].type === "ID") {
          let fieldName = fields[i].field.substr(0, fields[i].field.length - 3);
          if (qData.hasOwnProperty(fieldName)) {
            if (qData[fieldName] === null) {
              qData[fields[i].field] = fields[i].initial;
            } else {
              qData[fields[i].field] = qData[fieldName];
            }
          }
        }
        if (
            ["date", "datetime", "time"].indexOf(fields[i].input) !== -1 &&
            qData[fields[i].field] !== null
        ) {
          qData[fields[i].field] = moment(qData[fields[i].field]);
        }
        if (qData[fields[i].field] === null) {
          qData[fields[i].field] = fields[i].initial;
        } else if (fields[i].editor) {
          fields[i].editor.setData(qData[fields[i].field]);
        }
      }
    }
    setData({ ...data, ...qData, ...extraData });
  };

  const fetchFields = graphQLReduceFields(fields, "fields") + " " + extraFields;
  const stableSetDataFromQuery = useCallback(setDataFromQuery, []);
  const stableClient = useCallback(client, []);
  useEffect(() => {
    if (id) {
      setIsLoading(true);
      let extraQueries = "";
      let extraResults = [];
      for (let k in fields) {
        if (fields[k].key && fields[k].query) {
          extraResults.push(fields[k].field + "_" + fields[k].key);
          extraQueries +=
              fields[k].field + "_" + fields[k].key + ": " + fields[k].query;
        }
      }
      stableClient
          .query(
              `{ response: ` +
              query +
              `(filter:{id:${id}}) { data { ${fetchFields} } } ${extraQueries} }`
          )
          .then((result) => {
            if (result.response) {
              let extraData = {};
              for (let k in extraResults) {
                extraData[extraResults[k]] = result[extraResults[k]];
              }
              if (typeof onLoad === "function") {
                result.response.data[0] = onLoad(result.response.data[0], extraData);
              }
              stableSetDataFromQuery(result.response.data[0], extraData);
            }
            setIsLoading(false);
          })
          .catch((e) => {
            console.error("Caught exception", e);
          });
    }
  }, [stableClient, fetchFields, id, stableSetDataFromQuery, query, fields]);

  const save = (redirectAfterSave = true, newData = null) => {
    let changedData = newData ? newData : {...data};
    setValidation(initialValidation);
    let query =
        "(" +
        graphQLReduceFields(fields, "vars_def") +
        ") " +
        "{ response: " +
        mutations +
        "Create(" +
        graphQLReduceFields(fields, "vars") +
        (extraArgs !== null ? " " + extraArgs : "") +
        ") " +
        "{ id " +
        graphQLReduceFields(fields, "fields") + " " + extraFields +
        " } }";
    let variables = {};

    if (typeof preSave === "function") {
      changedData = preSave(data);
    }

    for (let i in fields) {
      let fieldName = fields[i].field;
      if (fieldName) {
        let fi = fieldName.indexOf('{');
        if (fi >= 0) {
          fieldName = fieldName.slice(0, fi);
        }
      }
      switch (fields[i].type) {
        case "ID":
          variables[fieldName] = changedData[fieldName] ? changedData[fieldName].id : null;
          break;
        case "[ID]":
          variables[fieldName] = changedData[fieldName]
              ? changedData[fieldName].map((v) => v.id)
              : [];
          break;
        case "Float":
          variables[fieldName] =
              changedData[fieldName] === "" ? null : parseFloat(changedData[fieldName]);
          break;
        case "Int":
        case "Integer":
          variables[fieldName] =
              changedData[fieldName] === "" ? null : parseInt(changedData[fieldName]);
          break;
        case "Json":
          variables[fieldName] = JSON.stringify(changedData[fieldName]);
          break;
        default:
          variables[fieldName] = changedData[fieldName];
          break;
      }
      switch (fields[i].input) {
        case "file":
          query = query.replace(
              "$" + fieldName + ":String",
              "$" + fieldName + ":String, $" + fieldName + "_data:String"
          );
          query = query.replace(
              fieldName + ":$" + fieldName,
              fieldName +
              ":$" +
              fieldName +
              ", " +
              fieldName +
              "_data:$" +
              fieldName +
              "_data"
          );
          variables[fieldName + "_data"] = changedData[fieldName + "_data"];
          break;
        case "date":
          if (changedData[fieldName])
            variables[fieldName] = moment(changedData[fieldName]).format("YYYY-MM-DD");
          break;
        case "datetime":
          if (changedData[fieldName])
            variables[fieldName] = moment(changedData[fieldName]).format("YYYY-MM-DD HH:mm:ss");
          break;
        case "time":
          if (changedData[fieldName])
            variables[fieldName] = moment(changedData[fieldName]).format("HH:mm:ss");
          break;
        default:
          break;
      }
      if (fields[i].required && !changedData[fieldName]) {
        let o = {};
        o[fieldName] = [intl.formatMessage({ id: "common.required_field" })];
        setValidation({ ...initialValidation, ...o });
        setNotification({
          type: "danger",
          msg: intl.formatMessage({
            id: "common.notification.missing_required",
          }),
        });
        return;
      }
    }
    if (id) {
      variables.id = id;
      // modify query from a create to an update mutation
      query =
          "($id:ID!, " + query.substr(1).replace("Create(", "Update(id:$id, ");
    }
    setIsLoading(true);
    client.mutate(query, variables).then((result) => {
      if (result) {
        if (result.response) {
          setDataFromQuery(result.response);
          setNotification({
            type: "success",
            msg: intl.formatMessage({
              id: "common.notification.success",
              defaultMessage: "Successfully saved!",
            }),
          });
          if (!onSave && redirectAfterSave) {
            if (redirectToEdit) {
              // should we redirect to edit after creation
              props.history.replace(
                  props.history.location.pathname.replace(
                      "create",
                      result.response.id
                  )
              );
            } else {
              // else when editing we redirect to list
              props.history.goBack();
            }
          }
          else if(typeof onSave === "function") {
            onSave({...variables, id: result.response.id});
          }
        }
      }
      setIsLoading(false);
    });
  };

  useImperativeHandle(ref, () => ({
    save(redirectOnSave = true) {
      save(redirectOnSave);
    }
  }), [data]);

  return (
      <Box {...others}>
        <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            color={notification.type}
            icon={NotificationsActive}
            message={notification.msg}
            open={notification.type !== undefined}
            onClose={hideAlert}
            autoHideDuration={6000}
        />
        <EditForm
            fields={fields}
            cols={cols}
            colSizes={colSizes}
            isLoading={isLoading}
            data={data}
            buttons={buttons}
            setData={setData}
            save={save}
            validation={validation}
            history={props.history}
            extraComponent={extraComponent ? extraComponent(data, setData) : null}
        />
      </Box>
  );
});

GraphQLEditForm.propTypes = {
  id: PropTypes.number,
  query: PropTypes.string.isRequired,
  mutations: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      initial: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
        PropTypes.number,
      ]),
      type: PropTypes.oneOf([
        "ID",
        "[ID]",
        "String",
        "Email",
        "Json",
        "Int",
        "Float",
        "Boolean",
      ]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      input: PropTypes.string,
      fullWidth: PropTypes.bool,
      required: PropTypes.bool,
      disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      titleField: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      query: PropTypes.string,
      filter: PropTypes.string,
      filterBy: PropTypes.string,
      accept: PropTypes.string,
      lines: PropTypes.number,
    })
  ),
  cols: PropTypes.number,
  colSizes: PropTypes.arrayOf(PropTypes.number),
};

export default GraphQLEditForm;
