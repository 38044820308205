import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Card, CardContent, CardHeader, Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from '@material-ui/core';
import {graphQLApi} from 'services/GraphQLApi';
import {useAuthDispatch} from 'contexts/Auth';
import GraphQLEditForm from 'components/GraphQL/GraphQLEditForm';
import {Add, Delete, People} from '@material-ui/icons';

export default function AssignmentQuestionEdit(props) {
  let id = Number(props.match.params.id);
  const assignmentId = props.match.params.assignmentID;

  const intl = useIntl();

  const [assignmentQuestions, setAssignmentQuestions] = useState([]);
  const client = new graphQLApi(useAuthDispatch(), props.history);
  const stableClient = useCallback(client, []);

  useEffect(() => {
    stableClient.query(`{assignmentQuestions(filter:{assignment_id:${assignmentId}}){data{id text}}}`).then(r => {
      if (r && r.hasOwnProperty('assignmentQuestions')) {
        setAssignmentQuestions(r.assignmentQuestions.data.filter(aq => parseInt(aq.id) !== id));
      }
    })
  }, [stableClient]);

  const questionAnswerCols = [
    {
      title: intl.formatMessage({
        id: "assignment_questions.edit.label.choice_text",
        defaultMessage: "Text"
      }),
      field: 'text'
    },
    {
      title: intl.formatMessage({
        id: "assignment_questions.edit.label.next_question",
        defaultMessage: "Next Question"
      }),
      type: "string",
      render: row => row.next_assignment_question ? row.next_assignment_question.text : null
    }
  ];

  const fields = [
    {
      column: 1,
      field: "text",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "assignment_questions.edit.label.text", defaultMessage: "Text"}),
      input: "text"
    },
    {
      column: 1,
      field: "sorting",
      initial: 0,
      type: "Int",
      label: intl.formatMessage({id: "assignment_questions.edit.label.sorting", defaultMessage: "Sorting"}),
      input: "number"
    },
    {
      column: 2,
      field: "is_export_allowed",
      initial: false,
      type: "Boolean",
      label: intl.formatMessage({
        id: "assignment_questions.edit.label.is_export_allowed",
        defaultMessage: "Export is allowed"
      }),
      input: "switch"
    },
    {
      column: 2,
      field: "is_answer_required",
      initial: false,
      type: "Boolean",
      label: intl.formatMessage({
        id: "assignment_questions.edit.label.is_answer_required",
        defaultMessage: "Answer is required"
      }),
      input: "switch"
    },
    {
      column: 2,
      field: "is_multiple_choice",
      initial: false,
      type: "Boolean",
      label: intl.formatMessage({
        id: "assignment_questions.edit.label.is_multiple_choice",
        defaultMessage: "Is multiple choice"
      }),
      input: "switch"
    },
    {
      fullWidth: true,
      initial: [],
      field: 'answers{id text next_assignment_question_id}',
      type: "[AssignmentQuestionAnswerInput]",
      render: (key, data, setData, _save) => data.is_multiple_choice && <TableContainer key={key}>
        <Table>
          <TableHead>
            <TableRow>{questionAnswerCols.map((c,k) =>
                <TableCell key={'answer-header-'+k}>{c.title}</TableCell>
            )}<TableCell key={'answer-header-actions'} align="right">
              <IconButton
                  onClick={() => setData(cur => ({...cur, answers: [...cur.answers, {text:''}]}))}
                  title={intl.formatMessage({id: "enhanced_table.actions.add", defaultMessage: "Add"})}
              ><Add/></IconButton>
            </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.answers && data.answers.map((a,ak) => <TableRow key={'answer-row-'+ak}>
              <TableCell key={'answer-row-'+ak+'-column-text'}>
                <TextField
                    size="small"
                    fullWidth
                    value={a.text}
                    onChange={e => setData(cur => {
                      let answers = [...cur.answers];
                      answers.splice(ak, 1, {...a, text: e.target.value});
                      return {...cur, answers: answers};
                    })}
                />
              </TableCell>
              <TableCell key={'answer-row-'+ak+'-column-next'}>
                <TextField
                    disabled={assignmentQuestions && assignmentQuestions.length === 0}
                    size="small"
                    fullWidth
                    value={a.next_assignment_question_id}
                    onChange={e => setData(cur => {
                      let answers = [...cur.answers];
                      answers.splice(ak, 1, {...a, next_assignment_question_id: e.target.value});
                      return {...cur, answers: answers};
                    })}
                    select
                ><MenuItem value={''}><em>{intl.formatMessage({id: "assignment_questions.next_question.none", defaultMessage: "No selection"})}</em></MenuItem>
                  {assignmentQuestions.filter(q => q.text !== "").map((q,i) => <MenuItem key={'row_'+ak+'_next_assignment_question_'+i} value={q.id}>{q.text}</MenuItem>)}</TextField>
              </TableCell>
              <TableCell key={'answer-row-actions'} align="right">
                <IconButton
                    onClick={() => setData(cur => ({...cur, answers: cur.answers.filter((_v, i) => i !== ak)}))}
                    title={intl.formatMessage({id: "assignment_questions.answers.delete", defaultMessage: "Delete"})}
                ><Delete/></IconButton>
              </TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
    }
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            avatar={<People color="primary"/>}
            title={intl.formatMessage({
              id: "assignment_questions.edit.heading",
              defaultMessage: "Assignment Questions edit"
            })}
            titleTypographyProps={{color: "primary"}}
          />
          <CardContent>
            <GraphQLEditForm
              {...props}
              id={id}
              query={"assignmentQuestions"}
              mutations={"assignmentQuestion"}
              fields={fields}
              extraArgs={'assignment_id:'+assignmentId}
              cols={2}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
