import React, {useRef, useState} from "react";
import {Box} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {Add, Delete, Edit, Person} from "@material-ui/icons";
import {useIntl} from "react-intl";
import ContactDialog from "./ContactDialog";
import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";
import {ConfirmDialog} from 'components/Dialogs';

export default function SubjectContactList({history, ...props}) {
    const intl = useIntl();
    const tableRef = useRef(null);
    const [openContactDialog, setOpenContactDialog] = useState(false);
    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
    const [activeRow, setActiveRow] = useState(null);

    const client = new graphQLApi(useAuthDispatch(), history);

    const columns = [
        {
            title: intl.formatMessage({id:'subject_contacts.list.column.subject.name', defaultMessage:'Subject Id'}),
            field: 'subject.name',
            type: 'string',
            filtering: false,
            width: 'auto'
        },
        {title: intl.formatMessage({id:'subject_contacts.list.column.name', defaultMessage:'Name'}), field: 'name'},
        {title: intl.formatMessage({id:'subject_contacts.list.column.title', defaultMessage:'Title'}), field: 'title'},
        {title: intl.formatMessage({id:'subject_contacts.list.column.phone', defaultMessage:'Phone'}), field: 'phone'},
        {title: intl.formatMessage({id:'subject_contacts.list.column.mobile', defaultMessage:'Mobile'}), field: 'mobile'},
        {title: intl.formatMessage({id:'subject_contacts.list.column.email', defaultMessage:'Email'}), field: 'email', type: 'string'}
    ];

    if (props.subjectId) {
        columns.shift();
    }

    function handleAddAction() {
        setOpenContactDialog(true);
    }

    function handleEditAction(row) {
        setOpenContactDialog(true);
        setActiveRow(row);
    }

    function handleDeleteAction(row) {
        setOpenConfirmDeleteDialog(true);
        setActiveRow(row);
    }

    function handleConfirmDelete(confirmed) {
        setOpenConfirmDeleteDialog(false);

        if (confirmed) {
            client.mutate(`{subjectContactDelete(id:${activeRow.id})}`)
                .then(r => {
                    if (r && r.hasOwnProperty("subjectContactDelete")) {
                        updateEnhancedTable();
                    }
                })
        }
    }

    function handleCloseContactDialog() {
        setOpenContactDialog(false);
        setActiveRow(null);
        updateEnhancedTable();
    }

    function updateEnhancedTable() {
        if (tableRef.current && tableRef.current.hasOwnProperty('update')) {
            tableRef.current.update();
        }
    }

    return (
        <Box>
            <EnhancedTable
                ref={tableRef}
                softDeletes={true}
                title={intl.formatMessage({id:'subject_contacts.list.table.heading', defaultMessage:"Contacts"})}
                columns={columns}
                query="subjectContacts"
                fields='subject{name} name title phone mobile email'
                filter={props.subjectId ? "subject_id:" + props.subjectId : ''}
                baseUrl={props.subjectId ? history.location.pathname + '/contact' : null}
                actions={[
                  {
                      icon: Add,
                      isFreeAction: true,
                      tooltip: intl.formatMessage({id: "enhanced_table.actions.add", defaultMessage: "Add"}),
                      onClick: () => handleAddAction()
                  },
                  {
                      icon: Edit,
                      rowClick: true,
                      tooltip: intl.formatMessage({id: "enhanced_table.actions.edit", defaultMessage: "Edit"}),
                      onClick: (row) => handleEditAction(row)
                  },
                  {
                      icon: Delete,
                      tooltip: intl.formatMessage({id: "enhanced_table.actions.delete", defaultMessage: "Delete"}),
                      onClick: (row) => handleDeleteAction(row)
                  }
                ]}
                icon={<Person/>}
                {...props}
            />
            <ContactDialog open={openContactDialog} row={activeRow} onClose={handleCloseContactDialog} {...props} />
            <ConfirmDialog
                onClose={handleConfirmDelete}
                open={openConfirmDeleteDialog}
                title={intl.formatMessage({
                    id: "common.dialog.delete.title",
                    defaultMessage: "Confirm deletion"
                })}
                message={intl.formatMessage({
                    id: "contact.dialog.delete.message",
                    defaultMessage: "Are you sure you want to delete this contact?",
                })}
            />
        </Box>
    );
}