import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import moment from 'moment';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {Typography} from "@mui/material";
import {authUser} from "../../../contexts/Auth";

export default function HotLeads(props) {
    const {
        hotLeads,
        setHotLeads,
        client,
        gotoHotLeadWithOutcome
    } = props;
    const intl = useIntl();
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        setHotLeads([]);
    }

    return <Dialog open={hotLeads.length > 0 && open} maxWidth={'md'}>
        <DialogTitle disableTypography style={{fontSize: 22}}>{intl.formatMessage({
            id: 'dialer.dialog.hot_leads.title',
            defaultMessage: 'You have {count} priority callbacks!',
        }, {count: hotLeads.length})}

            <IconButton
                onClick={handleClose}
                style={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    color: (theme) => theme.palette.grey[500],
                }}><Close/></IconButton></DialogTitle>
        <DialogContent style={{maxHeight:"40vh", overflow:"auto"}}>
            <Grid container spacing={2}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Typography variant="h4">{intl.formatMessage({
                            id: 'dialer.dialog.hot_leads.assignment.name',
                            defaultMessage: 'Assignment Name',
                        })}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            variant="h4">{intl.formatMessage({id: 'dialer.card.subject.name.label'})}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            variant="h4">{intl.formatMessage({id: 'assignment_subjects.edit.label.next_contact_at'})}</Typography>
                    </Grid>
                    <Grid item xs={3}> </Grid>
                </Grid>
            </Grid>
            {hotLeads.sort((a, b) => moment(b.next_contact_at).valueOf() - moment(a.next_contact_at).valueOf()).map((h, i) => <Grid container spacing={2} key={"hot_leads_" + i}>
                <Grid item xs={3}>
                    <Typography variant="h5">{h.assignment?.name ? h.assignment.name : ''}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="h5">{h.subject?.name ? h.subject.name : ''}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="h5">{moment(h.next_contact_at).isValid() ?
                        moment(h.next_contact_at).format('YYYY-MM-DD HH:mm:ss') : ''}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            gotoHotLeadWithOutcome(h.assignment?.id, h.id, h.outcome.id)
                            handleClose()
                        }}
                    >{intl.formatMessage({
                        id: 'dialer.dialog.hot_leads.button.goto_subject',
                        defaultMessage: "Go to subject"
                    })}</Button>
                </Grid>
            </Grid>)}
        </DialogContent>
        <DialogActions><Grid container spacing={2}>
            <Grid item>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handleClose}
                >{intl.formatMessage({
                    id: 'dialer.dialog.hot_leads.button.close',
                    defaultMessage: "Close"
                })}</Button>
            </Grid>
            <Grid item><Button size="small" color="secondary" variant="contained" onClick={() => {
                const settings = {}
                settings.hotlead_postponed = moment().format('YYYY-MM-DD HH:mm:ss');
                client.mutate('($userId:ID!, $settings:String) {userUpdate (id:$userId, settings:$settings) {id settings}}',
                    {userId: authUser().id, settings: JSON.stringify(settings)}).then(() => {
                    handleClose()
                })
            }}>{
                intl.formatMessage({id: 'dialer.dialog.hot_leads.button.postpone_all', defaultMessage: "POSTPONE ALL"})
            }</Button></Grid>
        </Grid></DialogActions>
    </Dialog>
}