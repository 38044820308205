import React, {useEffect, useState} from "react";
import {Card, CardContent, Grid} from "@material-ui/core";
import EditForm from "../../components/Form/EditForm";
import {graphQLApi, graphQLReduceFields} from "../../services/GraphQLApi";
import {useAuthDispatch} from "../../contexts/Auth";
import {useIntl} from "react-intl";
import {Snackbar} from "@mui/material";


export default function Notes(props) {
    const intl = useIntl();
    const fields = [
        {
            column: 1,
            field: "notes",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "assignments.edit.label.notes", defaultMessage: "Notes"}),
            input: "html",
            lines: 6,
        }
    ];

    const assignmentId = props.match.params.assignmentID;

    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const initialValidation = graphQLReduceFields(fields, 'validation');
    const [data, setData] = useState(graphQLReduceFields(fields, 'initial'))
    const [validation, setValidation] = useState(graphQLReduceFields(fields, 'validation'));
    const setValidationFromErrors = (errors) => {
        if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
            setValidation({...initialValidation, ...errors[0].extensions.validation});
        }
    };

    const client = new graphQLApi(useAuthDispatch(), props.history, null, {handleErrors: setValidationFromErrors});

    useEffect(() => {
        if (assignmentId) {
            setIsLoading(true)
            client.query('{assignments(filter:{id:'+assignmentId+'}) {data{id '+graphQLReduceFields(fields, 'fields')+'}}}').then(response => {
                if (response?.hasOwnProperty('assignments')) {
                    setData(response.assignments.data[0])
                }
            })
            setIsLoading(false)
        }
    }, []);

    return <Grid container>
        <Grid item xs={12}>
            <Snackbar
                open={!!alert}
                onClose={() => setAlert(null)}
                autoHideDuration={7500}
                message={alert || ''}
                anchorOrigin={{horizontal:"center",vertical:"top"}}
            />
            <Card>
                <CardContent>
                    <EditForm
                        fields={fields}
                        data={data}
                        setData={setData}
                        isLoading={isLoading}
                        save={() => {
                            const d = {id: assignmentId, notes: data.notes};
                            const vars = graphQLReduceFields(fields, 'variables');
                            vars.id = 'ID!';
                            client
                                .mutation('assignment', vars, d, 'id ' + graphQLReduceFields(fields, 'fields'))
                                .then(r => {
                                    if (r && r.response) {
                                        setAlert(intl.formatMessage({id:"assignments.edit.alert.saved", defaultMessage: "The notes have been saved"}))
                                    }
                                });
                        }}
                        validation={validation}
                        cols={1}
                    />
                </CardContent>
            </Card>
        </Grid>
    </Grid>
}