import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import componentStyles from "assets/theme/views/admin/dashboard.js";
// javascipt plugin for creating charts
import Chart from "chart.js";

// core components
import Header from "components/Headers/Header.js";
import React from "react";

import {chartOptions, parseOptions} from "variables/charts.js";

const useStyles = makeStyles(componentStyles);

function Dashboard() {
  const classes = useStyles();

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  return (
  <Box mt={-3.5} ml={-3} mr={-3} mb={1}>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      ><br/></Container>
  </Box>
  );
}

export default Dashboard;
