import React from 'react';
import {AppBar, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs} from '@material-ui/core/';
import TabPanel from 'views/Dialer/Components/TabPanel';
import moment from 'moment';
import {useIntl} from 'react-intl';

export default function Logs({ callLog, statusLog }) {
  const _intl = useIntl();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar
        position="static"
        style={{
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          // marginTop: "-1px",
        }}
      >
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          <Tab label="Opkalds log" />
          <Tab label="Status log" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TableContainer
          component={Paper}
          style={{
            width: "100%",
            height: 180,
          }}
        >
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">Bruger</TableCell>
                <TableCell align="center">Slut</TableCell>
                <TableCell align="center">Varighed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {callLog.sort((a,b) => {
                if (moment(a.ended_at).isAfter(b.ended_at)) return -1;
                if (moment(b.ended_at).isAfter(a.ended_at)) return 1;
                return 0;
              }).map((item,key) => (
                <TableRow key={'call-log-'+key} hover={true}>
                  <TableCell component="th" scope="log" align="left">
                    {item.user.name}
                  </TableCell>
                  <TableCell align="center">{moment(item.ended_at).format(_intl.formatMessage({id:"common.datetime.format"}))}</TableCell>
                  <TableCell align="right">{item.duration + ' secs'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableContainer
            component={Paper}
            style={{
              width: "100%",
              height: 180,
            }}
        >
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">Bruger</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Tidspunkt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statusLog.sort((a,b) => {
                if (moment(a.created_at).isAfter(b.created_at)) return -1;
                if (moment(b.created_at).isAfter(a.created_at)) return 1;
                return 0;
              }).map((item,key) => (
                  <TableRow key={'status-log-'+key} hover={true}>
                    <TableCell component="th" scope="log" align="left">
                      {item.user.name}
                    </TableCell>
                    <TableCell align="center">{item.status.name}</TableCell>
                    <TableCell align="right">{moment(item.created_at).format(_intl.formatMessage({id:"common.datetime.format"}))}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </>
  );
}
