import React, {useRef, useState} from 'react';
import {Dialog, DialogContent, Paper} from '@material-ui/core';
import EnhancedTable from 'components/DataTable/EnhancedTable';
import {Add, Delete, Score} from '@material-ui/icons';
import {useIntl} from 'react-intl';
import themeColors from 'assets/theme/colors';
import {graphQLApi} from 'services/GraphQLApi';
import {useAuthDispatch} from 'contexts/Auth';

export default function AssignmentOutcomeList(props) {
    const intl = useIntl();
    const tableRef = useRef();
    let assignmentId = props.match.params && props.match.params.assignmentID;
    const client = new graphQLApi(useAuthDispatch(), props.history);
    const [showAddOutcome, setShowAddOutcome] = useState(false);

    const columns = [
        {
            title: intl.formatMessage({id:'assignment_outcomes.list.column.outcome.name', defaultMessage:'Outcome'}),
            field: 'outcome.name',
            type: 'string',
            filtering: false,
            width: 'auto'
        },
        {title: intl.formatMessage({id:'assignment_outcomes.list.column.is_closed', defaultMessage:'Closed'}), field: 'outcome.is_closed', type: 'boolean', defaultSort: 'desc'},
        {title: intl.formatMessage({id:'assignment_outcomes.list.column.is_date_required', defaultMessage:'Date required'}), field: 'outcome.is_date_required', type: 'boolean'},
        {title: intl.formatMessage({id:'assignment_outcomes.list.column.is_target', defaultMessage:'Target'}), field: 'outcome.is_target', type: 'boolean'},
    ];

    const handleAdding = (rows) => {
        if (rows.length) {
            client.mutate('{assignmentOutcomeAdd(assignment_id:' + assignmentId + ', outcome_ids:[' + rows.map(r => r.id).join(',') + ']){id}}').then(() => {
                if (tableRef.current) {
                    tableRef.current.update();
                }
                setShowAddOutcome(false);
            });
        }
    }

    const handleRemoveOutcomes = (rows) => {
        if (rows.length) {
            client.mutate('{assignmentOutcomeRemove(assignment_id:' + assignmentId + ', outcome_ids:[' + rows.map(r => r.outcome.id).join(',') + ']){id}}').then(() => {
                if (tableRef.current) {
                    tableRef.current.clearSelected();
                    tableRef.current.update();
                }
            });
        }
    }

    return (
      <Paper>
        <EnhancedTable
          {...props}
          ref={tableRef}
          title={intl.formatMessage({id:'assignment_outcomes.list.table.heading', defaultMessage:"Assignment Outcomes list"})}
          columns={columns}
          query="assignmentOutcomes"
          fields='id assignment{name} outcome{id name is_closed is_date_required is_target} score hour_value'
          filter={'assignment_id:'+assignmentId}
          icon={<Score/>}
          urlState
          rowStyle={row => ({
              backgroundColor: row.outcome.is_closed ? themeColors.error.badgeBg : themeColors.success.badgeBg,
          })}
          actions={[
              {
                  tooltip: intl.formatMessage({id:"common.button.add"}),
                  icon: Add,
                  isFreeAction: true,
                  onClick: () => setShowAddOutcome(true),
              },
              {
                  tooltip: intl.formatMessage({id:"common.button.remove"}),
                  icon: Delete,
                  onSelected: true,
                  onClick: handleRemoveOutcomes,
              }
          ]}
        />
          {showAddOutcome && <Dialog open={showAddOutcome} onClose={() => setShowAddOutcome(false)} maxWidth="lg">
              <DialogContent>
                  <EnhancedTable
                      {...props}
                      title={intl.formatMessage({
                          id: 'assignment_outcomes.dialog.add.heading',
                          defaultMessage: "Select outcomes to add to assignment"
                      })}
                      columns={columns.map(c => ({...c, field: c.field.replace('outcome.', '')}))}
                      query="outcomes"
                      fields='id name is_closed is_date_required is_target'
                      filter={'ids_not:[' + ((tableRef && tableRef.current) ? tableRef.current.getRows().map(r => r.outcome.id) : []).join(',') + ']'}
                      icon={<Score/>}
                      urlState={false}
                      rowStyle={row => ({
                          backgroundColor: row.is_closed ? themeColors.error.badgeBg : themeColors.success.badgeBg,
                      })}
                      actions={[
                          {
                              tooltip: intl.formatMessage({
                                  id: 'assignment_outcomes.dialog.add.action.add_selected',
                                  defaultMessage: "Add the selected outcomes"
                              }),
                              label: intl.formatMessage({
                                  id: 'assignment_outcomes.dialog.add.action.add_selected',
                                  defaultMessage: "Add the selected outcomes"
                              }),
                              icon: Add,
                              onSelected: true,
                              onClick: handleAdding,
                          }
                      ]}
                  />
              </DialogContent>
          </Dialog>}
      </Paper>
    );
}