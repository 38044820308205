import Dialer from 'views/Dialer/Dialer';
import {graphQLApi} from 'services/GraphQLApi';
import {authUser, useAuthDispatch} from 'contexts/Auth';
import {SipProvider} from '@tinxdk/react-sip';
import {useEffect, useState} from 'react';
import {CircularProgress} from '@mui/material';

export default function DialerMain(props) {
  const client = new graphQLApi(useAuthDispatch(), props.history);
  const [settings, setSettings] = useState({});

  useEffect(() => {
    client.query('{ ' +
        'users(filter:{id:' + authUser().id + '}){data{sip_credential{number login auth}}} ' +
        'settings{data{key data}} ' +
        '}').then(result => {
      if (result && result.hasOwnProperty('settings') && result.hasOwnProperty('users')) {
        let set = {
          sip_host:'',
          sip_port:null,
          sip_path:'',
          sip_server:'',
          sip_username:'',
          sip_password:'',
          sip_dtmf_transport:'INFO',
          sip_debug:false,
        };
        result.settings.data.forEach(s => {
          set[s.key] = s.data;
        });
        if (result.users.data[0] && result.users.data[0].hasOwnProperty('sip_credential') && result.users.data[0].sip_credential) {
          set.sip_number = result.users.data[0].sip_credential.number;
          set.sip_username = result.users.data[0].sip_credential.login;
          set.sip_password = result.users.data[0].sip_credential.auth;
        }
        if (set.sip_socket) {
          let t = set.sip_socket.match(/([0-9a-z.]+):{0,1}(\d*)(.*)/);
          set.sip_host = t[1];
          set.sip_port = parseInt(t[2]);
          set.sip_path = t[3];
        }
        setSettings({...settings, ...set});
      }
    })
  }, []);

  return (settings.sip_host ? (!!settings.sip_username ? <SipProvider
      host={settings.sip_host}
      port={settings.sip_port}
      pathname={settings.sip_path}
      user={settings.sip_username}
      password={settings.sip_password}
      dtmfTransportType={settings.sip_dtmf_transport}
      debug={!!settings.sip_debug}
      secure
      autoRegister
  >
    <Dialer {...props}/>
  </SipProvider> : <Dialer {...props}/>) : <CircularProgress/>)
}