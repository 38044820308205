import React from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {Flare} from "@material-ui/icons";
import {useIntl} from "react-intl";

export default function OutcomeList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'outcomes.list.column.name', defaultMessage:'Name'}), field: 'name'},
        {title: intl.formatMessage({id:'outcomes.list.column.is_date_required', defaultMessage:'Require date'}), field: 'is_date_required', type: 'boolean', initial: false},
        {title: intl.formatMessage({id:'outcomes.list.column.is_closed', defaultMessage:'Closed'}), field: 'is_closed', type: 'boolean', initial: false},
        {title: intl.formatMessage({id:'outcomes.list.column.is_target', defaultMessage:'Target'}), field: 'is_target', type: 'boolean', initial: false}
    ];

    return (
      <Paper>
        <EnhancedTable
          softDeletes={true}
          title={intl.formatMessage({id:'outcomes.list.table.heading', defaultMessage:"Outcomes list"})}
          columns={columns}
          query="outcomes"
          mutations="outcome"
          fields='id name is_date_required is_closed is_target'
          icon={<Flare/>}
          {...props}
        />
      </Paper>
    );
}