import React, {useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {CloudUpload, Done, ErrorOutline} from '@material-ui/icons';

import {
  authHeader,
  authSignOut,
  getApiDomain,
  useAuthDispatch,
} from 'contexts/Auth';
import {useDropzone} from 'react-dropzone';
import axios from 'axios';
import {useIntl} from 'react-intl';
import themeColors from '../../assets/theme/colors';

const DropzoneUploader = React.forwardRef((props, ref) => {
  const {
    onUploaded,
    mutation,
    attributes,
    field,
    single,
    id
  } = props

  const intl = useIntl();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [showAddFiles, setShowAddFiles] = useState(false);
  const dispatch = useAuthDispatch();

  const {getRootProps, getInputProps, open} = useDropzone({
    multiple: !single,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    onDrop: (files) => {
      setAcceptedFiles(files.map((f, k) => {
        return {id: k, name: f.name, size: f.size, type: f.type, progress: 0, uploaded: null}
      }));
      if (files) {
        setShowAddFiles(true);
        files.forEach((f, k) => {
          let data = new FormData();
          if (id) {
            data.append('id', id);
          }
          if (field) {
            data.append('field', field);
          }
          if (attributes) {
            data.append('attributes', JSON.stringify({ ...attributes, title: f.name }))
          }
          data.append('model', mutation);
          data.append('file', f);
          axios.post('', data, {
            baseURL: getApiDomain() + '/upload',
            credentials: "include",
            headers: {
              "Accept-Language": window.language,
              "Accept": "application/json",
              ...authHeader(),
            },
            onUploadProgress: event => {
              setAcceptedFiles(files => files.map(fm => {
                if (fm.id === k) {
                  let progress = Number(event.loaded / event.total * 100).toFixed(2);
                  return {...fm, progress: progress};
                }
                return fm;
              }))
            }
          }).then(r => {
            if (r && r.data && r.data.updated) {
              setUploadedFiles(curFiles => {
                curFiles.push(r.data.updated);
                return curFiles;
              });
            }
            else if (r && r.data && r.data.created) {
              setUploadedFiles(curFiles => {
                curFiles.push(r.data.created[0]);
                return curFiles;
              });
            }
            setAcceptedFiles(curFiles => {
              curFiles[k].uploaded = true;
              let inProgress = curFiles.filter(af => !af.uploaded);
              if (curFiles.length > 0 && inProgress.length === 0) {
                setShowAddFiles(false);
                onUploaded(uploadedFiles);
                setUploadedFiles([]);
                return [];
              }
              return curFiles;
            });
          }).catch(error => {
            if (error.response.status === 401) {
              authSignOut(dispatch);
            }
          });
        });
      }
    }
  });

  useImperativeHandle(ref, () => ({}));

  return (<div {...getRootProps({style: {border:"1px dashed "+themeColors.gray['300'],borderRadius:5}})}>
    <input {...getInputProps()} />
    {props.children}
    <Box textAlign="center">
      <Button startIcon={<CloudUpload/>} onClick={open}>{intl.formatMessage({id:"dropzone-uploader.message", defaultMessage:"Drag and drop some files here, or click to select files"})}</Button><br/>
    </Box>
    <Dialog open={showAddFiles} onClose={() => setShowAddFiles(false)} maxWidth="md">
      <DialogContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{intl.formatMessage({id:"dropzone-uploader.table.filename", defaultMessage:"Filename"})}</TableCell>
                <TableCell align="right">{intl.formatMessage({id:"dropzone-uploader.table.file_size", defaultMessage:"Size"})}</TableCell>
                <TableCell align="right">{intl.formatMessage({id:"dropzone-uploader.table.file_type", defaultMessage:"Type"})}</TableCell>
                <TableCell align="right">{intl.formatMessage({id:"dropzone-uploader.table.progress", defaultMessage:"Progress"})}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {acceptedFiles.map((file, k) =>
                <TableRow key={"file-" + k}>
                  <TableCell>{file.name}</TableCell>
                  <TableCell align="right">{Number(file.size / 1024).toFixed(2)} kB</TableCell>
                  <TableCell align="right">{file.type}</TableCell>
                  <TableCell align="right" valign="bottom">{file.progress < 100 ?
                    <LinearProgress variant="determinate" value={Number(file.progress)}/> :
                    file.uploaded ? <Done/> : <ErrorOutline />
                  }</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>

  </div>);
});

DropzoneUploader.propType = {
  onUploaded: PropTypes.func.isRequired,
  onDeleted: PropTypes.func,
  mutation: PropTypes.string.isRequired,
  field: PropTypes.string,
  attributes: PropTypes.object,
  single: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default DropzoneUploader;