import {Assignment, PhoneEnabled, Settings} from '@material-ui/icons';
import {authUser} from 'contexts/Auth';
import SinglePage from 'layouts/SinglePage';
import assignmentRoutes from 'routes/assignment';
import dialerRoutes from 'routes/dialer';
import Admin from './layouts/Admin';
import Auth from './layouts/Auth';

export const rolePriorities = {
  developer: 5,
  site_manager: 10,
  admin: 25,
  manager: 50,
  accounting: 75,
  lead: 100,
  phoner: 125,
};

export const roles = (intl) => [
    {id: rolePriorities.developer, name: intl.formatMessage({id: "roles.priority.5", defaultMessage: "Is system developer"})},
    {id: rolePriorities.site_manager, name: intl.formatMessage({id: "roles.priority.10", defaultMessage: "Can edit sites"})},
    {id: rolePriorities.admin, name: intl.formatMessage({id: "roles.priority.25", defaultMessage: "Can edit settings and users"})},
    {id: rolePriorities.manager, name: intl.formatMessage({id: "roles.priority.50", defaultMessage: "Can edit assignments"})},
    {id: rolePriorities.accounting, name: intl.formatMessage({id: "roles.priority.75", defaultMessage: "Can handle assignment billing"})},
    {id: rolePriorities.lead, name: intl.formatMessage({id: "roles.priority.100", defaultMessage: "Can edit own assignments"})},
    {id: rolePriorities.phoner, name: intl.formatMessage({id: "roles.priority.125", defaultMessage: "Can only access dialer"})},
  ];

export const features = {}
// const hasFeature = (feature) => this.features.findIndex(f => f.key === feature) !== -1;

export function getLayouts(intl) {
  if (authUser().roles.length === 1 && authUser().roles[0].key === "sites_manager") {
    return {
      auth: {
        title: intl.formatMessage({id: "layouts.auth", defaultMessage: "Frontpage"}),
        authenticated: false,
        component: Auth,
        acl: null,
        features: [],
        path: "/",
        defaultRoute: "/login"
      },
      admin: {
        title: intl.formatMessage({id: "layouts.admin", defaultMessage: "Administration"}),
        icon: Settings,
        authenticated: true,
        component: Admin,
        acl: rolePriorities.site_manager,
        features: [],
        path: "/admin",
        defaultRoute: "/admin/sites"
      },
    }
  }
  return {
    auth: {
      title: intl.formatMessage({id: "layouts.auth", defaultMessage: "Frontpage"}),
      authenticated: false,
      component: Auth,
      acl: null,
      features: [],
      path: "/",
      defaultRoute: "/login"
    },
    assignment: {
      title: intl.formatMessage({id: "layouts.assignment", defaultMessage: "Administration"}),
      icon: Assignment,
      authenticated: true,
      component: Admin,
      acl: rolePriorities.manager,
      features: [],
      path: "/assignment/:assignmentID?",
      defaultRoute: "/assignment",
      routes: assignmentRoutes(intl, '/assignment/:assignmentID?'),
      // hidden: true,
    },
    dialer: {
      title: intl.formatMessage({id: "layouts.dialer", defaultMessage: "Dialer"}),
      icon: PhoneEnabled,
      authenticated: true,
      component: SinglePage,
      acl: rolePriorities.phoner,
      features: [],
      path: "/dialer/:assignmentId?",
      defaultRoute: "/dialer",
      routes: dialerRoutes(intl, '/dialer/:assignmentID?')
    },
    admin: {
      title: intl.formatMessage({id: "layouts.admin", defaultMessage: "System admin"}),
      icon: Settings,
      authenticated: true,
      component: Admin,
      acl: rolePriorities.admin,
      features: [],
      path: "/admin",
      defaultRoute: "/admin"
    },
  };
}

export const config = {
  brandLogo: require("assets/img/brand/verus-logo.svg"),
  emailVerification: true,
  layoutNavigation: "context",  // context = in the user profile context menu, horisontal = top navbar with no breadcrumb
  viewNavigation: "left",       // left = left vertical, top = top horisontal instead of breadcrumb
  auth: {
    register: false,
    rememberMe: false,
    passwordReset: true,
  },
  admin: {},
  countryCode:"DK",
  languageCode:"dansk",
  sentryDsn: 'https://d3cb620e144044eeb53c586f85f12e6f@o724124.ingest.sentry.io/6154973',
}
export const zipCodes = [
    {id: 555, name: "Data Scanning A/S, 'Læs Ind'-service"},
    {id: 800, name: "Høje Taastrup Girostrøget 1 BG-Bank A/S"},
    {id: 877, name: "Valby Vigerslev Allé 18 Aller Press (konkurrencer)"},
    {id: 900, name: "København C Københavns Postcenter + erhvervskunder"},
    {id: 910, name: "København C Ufrankerede svarforsendelser"},
    {id: 929, name: "København C Ufrankerede svarforsendelser"},
    {id: 999, name: "København C DR Byen"},
    {id: 1000, name: "København K Købmagergade 33 Købmagergade Postkontor"},
    {id: 1001, name: "København K Postboks"},
    {id: 1002, name: "København K Postboks"},
    {id: 1003, name: "København K Postboks"},
    {id: 1004, name: "København K Postboks"},
    {id: 1005, name: "København K Postboks"},
    {id: 1006, name: "København K Postboks"},
    {id: 1007, name: "København K Postboks"},
    {id: 1008, name: "København K Postboks"},
    {id: 1009, name: "København K Postboks"},
    {id: 1010, name: "København K Postboks"},
    {id: 1011, name: "København K Postboks"},
    {id: 1012, name: "København K Postboks"},
    {id: 1013, name: "København K Postboks"},
    {id: 1014, name: "København K Postboks"},
    {id: 1015, name: "København K Postboks"},
    {id: 1016, name: "København K Postboks"},
    {id: 1017, name: "København K Postboks"},
    {id: 1018, name: "København K Postboks"},
    {id: 1019, name: "København K Postboks"},
    {id: 1020, name: "København K Postboks"},
    {id: 1021, name: "København K Postboks"},
    {id: 1022, name: "København K Postboks"},
    {id: 1023, name: "København K Postboks"},
    {id: 1024, name: "København K Postboks"},
    {id: 1025, name: "København K Postboks"},
    {id: 1026, name: "København K Postboks"},
    {id: 1045, name: "København K Ufrankerede svarforsendelser"},
    {id: 1050, name: "København K Kongens Nytorv"},
    {id: 1051, name: "København K Nyhavn"},
    {id: 1052, name: "København K Herluf Trolles Gade"},
    {id: 1053, name: "København K Cort Adelers Gade"},
    {id: 1054, name: "København K Peder Skrams Gade"},
    {id: 1055, name: "København K Tordenskjoldsgade"},
    {id: 1055, name: "København K August Bournonvilles Passage"},
    {id: 1056, name: "København K Heibergsgade"},
    {id: 1057, name: "København K Holbergsgade"},
    {id: 1058, name: "København K Havnegade"},
    {id: 1059, name: "København K Niels Juels Gade"},
    {id: 1060, name: "København K Holmens Kanal"},
    {id: 1061, name: "København K Ved Stranden"},
    {id: 1062, name: "København K Boldhusgade"},
    {id: 1063, name: "København K Laksegade"},
    {id: 1064, name: "København K Asylgade"},
    {id: 1065, name: "København K Fortunstræde"},
    {id: 1066, name: "København K Admiralgade"},
    {id: 1067, name: "København K Nikolaj Plads"},
    {id: 1068, name: "København K Nikolajgade"},
    {id: 1069, name: "København K Bremerholm"},
    {id: 1070, name: "København K Vingårdstræde"},
    {id: 1071, name: "København K Dybensgade"},
    {id: 1072, name: "København K Lille Kirkestræde"},
    {id: 1073, name: "København K Store Kirkestræde"},
    {id: 1074, name: "København K Lille Kongensgade"},
    {id: 1092, name: "København K Holmens Kanal 2-12 Danske Bank A/S"},
    {id: 1093, name: "København K Havnegade 5s Nationalbank"},
    {id: 1095, name: "København K Kongens Nytorv 13 Magasin du Nord"},
    {id: 1098, name: "København K Esplanaden 50 A.P. Møller"},
    {id: 1100, name: "København K Østergade"},
    {id: 1101, name: "København K Ny Østergade"},
    {id: 1102, name: "København K Pistolstræde"},
    {id: 1103, name: "København K Hovedvagtsgade"},
    {id: 1104, name: "København K Ny Adelgade"},
    {id: 1105, name: "København K Kristen Bernikows Gade"},
    {id: 1106, name: "København K Antonigade"},
    {id: 1107, name: "København K Grønnegade"},
    {id: 1110, name: "København K Store Regnegade"},
    {id: 1111, name: "København K Christian IX's Gade"},
    {id: 1112, name: "København K Pilestræde"},
    {id: 1113, name: "København K Silkegade"},
    {id: 1114, name: "København K Kronprinsensgade"},
    {id: 1115, name: "København K Klareboderne"},
    {id: 1116, name: "København K Møntergade"},
    {id: 1117, name: "København K Gammel Mønt"},
    {id: 1118, name: "København K Sværtegade"},
    {id: 1119, name: "København K Landemærket"},
    {id: 1120, name: "København K Vognmagergade"},
    {id: 1121, name: "København K Lønporten"},
    {id: 1122, name: "København K Sjæleboderne"},
    {id: 1123, name: "København K Gothersgade"},
    {id: 1124, name: "København K Åbenrå"},
    {id: 1125, name: "København K Suhmsgade"},
    {id: 1126, name: "København K Pustervig"},
    {id: 1127, name: "København K Hauser Plads"},
    {id: 1128, name: "København K Hausergade"},
    {id: 1129, name: "København K Sankt Gertruds Stræde"},
    {id: 1130, name: "København K Rosenborggade"},
    {id: 1131, name: "København K Tornebuskegade"},
    {id: 1140, name: "København K Møntergade 19 Dagbladet Børsen"},
    {id: 1147, name: "København K Pilestræde 34 Berlingske Tidende"},
    {id: 1148, name: "København K Vognmagergade 11 Gutenberghus"},
    {id: 1150, name: "København K Købmagergade"},
    {id: 1151, name: "København K Valkendorfsgade"},
    {id: 1152, name: "København K Løvstræde"},
    {id: 1153, name: "København K Niels Hemmingsens Gade"},
    {id: 1154, name: "København K Gråbrødretorv"},
    {id: 1155, name: "København K Kejsergade"},
    {id: 1156, name: "København K Gråbrødrestræde"},
    {id: 1157, name: "København K Klosterstræde"},
    {id: 1158, name: "København K Skoubogade"},
    {id: 1159, name: "København K Skindergade"},
    {id: 1160, name: "København K Amagertorv"},
    {id: 1161, name: "København K Vimmelskaftet"},
    {id: 1162, name: "København K Jorcks Passage"},
    {id: 1163, name: "København K Klostergården"},
    {id: 1164, name: "København K Nygade"},
    {id: 1165, name: "København K Nørregade"},
    {id: 1166, name: "København K Dyrkøb"},
    {id: 1167, name: "København K Bispetorvet"},
    {id: 1168, name: "København K Frue Plads"},
    {id: 1169, name: "København K Store Kannikestræde"},
    {id: 1170, name: "København K Lille Kannikestræde"},
    {id: 1171, name: "København K Fiolstræde"},
    {id: 1172, name: "København K Krystalgade"},
    {id: 1173, name: "København K Peder Hvitfeldts Stræde"},
    {id: 1174, name: "København K Rosengården"},
    {id: 1175, name: "København K Kultorvet"},
    {id: 1200, name: "København K Højbro Plads"},
    {id: 1201, name: "København K Læderstræde"},
    {id: 1202, name: "København K Gammel Strand"},
    {id: 1203, name: "København K Nybrogade"},
    {id: 1204, name: "København K Magstræde"},
    {id: 1205, name: "København K Snaregade"},
    {id: 1206, name: "København K Naboløs"},
    {id: 1207, name: "København K Hyskenstræde"},
    {id: 1208, name: "København K Kompagnistræde"},
    {id: 1209, name: "København K Badstuestræde"},
    {id: 1210, name: "København K Knabrostræde"},
    {id: 1211, name: "København K Brolæggerstræde"},
    {id: 1212, name: "København K Vindebrogade"},
    {id: 1213, name: "København K Bertel Thorvaldsens Plads"},
    {id: 1214, name: "København K Søren Kierkegaards Plads"},
    {id: 1214, name: "København K Tøjhusgade"},
    {id: 1215, name: "København K Børsgade"},
    {id: 1216, name: "København K Slotsholmsgade"},
    {id: 1217, name: "København K Børsen"},
    {id: 1218, name: "København K Christiansborg Ridebane"},
    {id: 1218, name: "København K Proviantpassagen"},
    {id: 1218, name: "København K Christiansborg"},
    {id: 1218, name: "København K Rigsdagsgården"},
    {id: 1218, name: "København K Christiansborg Slotsplads"},
    {id: 1218, name: "København K Prins Jørgens Gård"},
    {id: 1219, name: "København K Christians Brygge ulige nr. + 2-22"},
    {id: 1220, name: "København K Frederiksholms Kanal"},
    {id: 1240, name: "København K Christiansborg Folketinget"},
    {id: 1250, name: "København K Sankt Annæ Plads"},
    {id: 1251, name: "København K Kvæsthusgade"},
    {id: 1252, name: "København K Kvæsthusbroen"},
    {id: 1253, name: "København K Toldbodgade"},
    {id: 1254, name: "København K Lille Strandstræde"},
    {id: 1255, name: "København K Store Strandstræde"},
    {id: 1256, name: "København K Amaliegade"},
    {id: 1257, name: "København K Amalienborg"},
    {id: 1258, name: "København K Larsens Plads"},
    {id: 1259, name: "København K Nordre Toldbod"},
    {id: 1259, name: "København K Trekroner"},
    {id: 1260, name: "København K Bredgade"},
    {id: 1261, name: "København K Palægade"},
    {id: 1263, name: "København K Esplanaden"},
    {id: 1263, name: "København K Churchillparken"},
    {id: 1264, name: "København K Store Kongensgade"},
    {id: 1265, name: "København K Frederiksgade"},
    {id: 1266, name: "København K Bornholmsgade"},
    {id: 1267, name: "København K Hammerensgade"},
    {id: 1268, name: "København K Jens Kofods Gade"},
    {id: 1270, name: "København K Grønningen"},
    {id: 1271, name: "København K Poul Ankers Gade"},
    {id: 1291, name: "København K Sankt Annæ Plads 28 J. Lauritzen A/S"},
    {id: 1300, name: "København K Borgergade"},
    {id: 1301, name: "København K Landgreven"},
    {id: 1302, name: "København K Dronningens Tværgade"},
    {id: 1303, name: "København K Hindegade"},
    {id: 1304, name: "København K Adelgade"},
    {id: 1306, name: "København K Kronprinsessegade"},
    {id: 1307, name: "København K Sølvgade"},
    {id: 1307, name: "København K Georg Brandes Plads"},
    {id: 1308, name: "København K Klerkegade"},
    {id: 1309, name: "København K Rosengade"},
    {id: 1310, name: "København K Fredericiagade"},
    {id: 1311, name: "København K Olfert Fischers Gade"},
    {id: 1312, name: "København K Gammelvagt"},
    {id: 1313, name: "København K Sankt Pauls Gade"},
    {id: 1314, name: "København K Sankt Pauls Plads"},
    {id: 1315, name: "København K Rævegade"},
    {id: 1316, name: "København K Rigensgade"},
    {id: 1317, name: "København K Stokhusgade"},
    {id: 1318, name: "København K Krusemyntegade"},
    {id: 1319, name: "København K Gernersgade"},
    {id: 1320, name: "København K Haregade"},
    {id: 1321, name: "København K Tigergade"},
    {id: 1322, name: "København K Suensonsgade"},
    {id: 1323, name: "København K Hjertensfrydsgade"},
    {id: 1324, name: "København K Elsdyrsgade"},
    {id: 1325, name: "København K Delfingade"},
    {id: 1326, name: "København K Krokodillegade"},
    {id: 1327, name: "København K Vildandegade"},
    {id: 1328, name: "København K Svanegade"},
    {id: 1329, name: "København K Timiansgade"},
    {id: 1349, name: "København K Sølvgade 40 DSB"},
    {id: 1350, name: "København K Øster Voldgade"},
    {id: 1352, name: "København K Rørholmsgade"},
    {id: 1353, name: "København K Øster Farimagsgade 1-19 + 2-2D"},
    {id: 1354, name: "København K Ole Suhrs Gade"},
    {id: 1355, name: "København K Gammeltoftsgade"},
    {id: 1356, name: "København K Bartholinsgade"},
    {id: 1357, name: "København K Øster Søgade 1 - 36"},
    {id: 1358, name: "København K Nørre Voldgade"},
    {id: 1359, name: "København K Ahlefeldtsgade"},
    {id: 1360, name: "København K Frederiksborggade"},
    {id: 1361, name: "København K Israels Plads"},
    {id: 1361, name: "København K Linnésgade"},
    {id: 1362, name: "København K Rømersgade"},
    {id: 1363, name: "København K Vendersgade"},
    {id: 1364, name: "København K Nørre Farimagsgade"},
    {id: 1365, name: "København K Schacksgade"},
    {id: 1366, name: "København K Nansensgade"},
    {id: 1367, name: "København K Kjeld Langes Gade"},
    {id: 1368, name: "København K Turesensgade"},
    {id: 1369, name: "København K Gyldenløvesgade Lige nr"},
    {id: 1370, name: "København K Nørre Søgade"},
    {id: 1371, name: "København K Søtorvet"},
    {id: 1390, name: "København K Nørre Voldgade 68 BG-Bank"},
    {id: 1400, name: "København K Torvegade"},
    {id: 1400, name: "København K Knippelsbro"},
    {id: 1401, name: "København K Strandgade"},
    {id: 1402, name: "København K Asiatisk Plads"},
    {id: 1402, name: "København K Johan Semps Gade"},
    {id: 1402, name: "København K Nicolai Eigtveds Gade"},
    {id: 1402, name: "København K David Balfours Gade"},
    {id: 1402, name: "København K Hammershøi Kaj"},
    {id: 1403, name: "København K Wilders Plads"},
    {id: 1404, name: "København K Krøyers Plads"},
    {id: 1405, name: "København K Grønlandske Handels Plads"},
    {id: 1406, name: "København K Christianshavns Kanal"},
    {id: 1407, name: "København K Bådsmandsstræde"},
    {id: 1408, name: "København K Wildersgade"},
    {id: 1409, name: "København K Knippelsbrogade"},
    {id: 1410, name: "København K Christianshavns Torv"},
    {id: 1411, name: "København K Langebrogade"},
    {id: 1411, name: "København K Applebys Plads"},
    {id: 1412, name: "København K Voldgården"},
    {id: 1413, name: "København K Ved Kanalen"},
    {id: 1414, name: "København K Overgaden neden Vandet"},
    {id: 1415, name: "København K Overgaden oven Vandet"},
    {id: 1416, name: "København K Sankt Annæ Gade"},
    {id: 1417, name: "København K Mikkel Vibes Gade"},
    {id: 1418, name: "København K Sofiegade"},
    {id: 1419, name: "København K Store Søndervoldstræde"},
    {id: 1420, name: "København K Dronningensgade"},
    {id: 1421, name: "København K Lille Søndervoldstræde"},
    {id: 1422, name: "København K Prinsessegade"},
    {id: 1423, name: "København K Amagergade"},
    {id: 1424, name: "København K Christianshavns Voldgade"},
    {id: 1425, name: "København K Ved Volden"},
    {id: 1426, name: "København K Voldboligerne"},
    {id: 1427, name: "København K Brobergsgade"},
    {id: 1428, name: "København K Andreas Bjørns Gade"},
    {id: 1429, name: "København K Burmeistersgade"},
    {id: 1430, name: "København K Bodenhoffs Plads"},
    {id: 1431, name: "København K Islands Plads"},
    {id: 1432, name: "København K Margretheholmsvej"},
    {id: 1432, name: "København K Refshalevej"},
    {id: 1432, name: "København K William Wains Gade"},
    {id: 1433, name: "København K Refshaleøen"},
    {id: 1433, name: "København K Quintus"},
    {id: 1433, name: "København K Flakfortet"},
    {id: 1433, name: "København K Lynetten"},
    {id: 1433, name: "København K Margretheholm"},
    {id: 1433, name: "København K Middelgrundsfortet"},
    {id: 1433, name: "København K Christiansholms Ø"},
    {id: 1434, name: "København K Danneskiold-Samsøes Allé"},
    {id: 1435, name: "København K Philip de Langes Allé"},
    {id: 1436, name: "København K Værftsbroen"},
    {id: 1436, name: "København K Søartillerivej"},
    {id: 1436, name: "København K Halvtolv"},
    {id: 1436, name: "København K Trangravsvej"},
    {id: 1436, name: "København K Arsenalvej"},
    {id: 1436, name: "København K Kuglegårdsvej"},
    {id: 1436, name: "København K Kuglegården"},
    {id: 1437, name: "København K Fabrikmestervej"},
    {id: 1437, name: "København K Masteskursvej"},
    {id: 1437, name: "København K Bohlendachvej"},
    {id: 1437, name: "København K Stibolts Kvarter"},
    {id: 1437, name: "København K Takkelloftsvej"},
    {id: 1437, name: "København K Theodor Christensens Plads"},
    {id: 1437, name: "København K Hohlenbergs Kvarter"},
    {id: 1437, name: "København K Galionsvej"},
    {id: 1437, name: "København K Krabbes Kvarter"},
    {id: 1437, name: "København K Kanonbådsvej"},
    {id: 1437, name: "København K Leo Mathisens Vej"},
    {id: 1437, name: "København K Per Knutzons Vej"},
    {id: 1437, name: "København K Eik Skaløes Plads"},
    {id: 1437, name: "København K Schifters Kvarter"},
    {id: 1438, name: "København K Benstrups Kvarter"},
    {id: 1438, name: "København K Judichærs Plads"},
    {id: 1438, name: "København K Judichærs Kvarter"},
    {id: 1438, name: "København K Dokøvej"},
    {id: 1438, name: "København K Ekvipagemestervej"},
    {id: 1438, name: "København K Orlogsværftvej"},
    {id: 1439, name: "København K Takkeladsvej"},
    {id: 1439, name: "København K Elefanten"},
    {id: 1439, name: "København K H.C. Sneedorffs Allé"},
    {id: 1439, name: "København K Eskadrevej"},
    {id: 1439, name: "København K Henrik Spans Vej"},
    {id: 1439, name: "København K Spanteloftvej"},
    {id: 1439, name: "København K Kongebrovej"},
    {id: 1439, name: "København K P. Løvenørns Vej"},
    {id: 1439, name: "København K Henrik Gerners Plads"},
    {id: 1439, name: "København K Krudtløbsvej"},
    {id: 1439, name: "København K Bradbænken"},
    {id: 1439, name: "København K A.H. Vedels Plads"},
    {id: 1440, name: "København K Tinghuset"},
    {id: 1440, name: "København K Blå Karamel"},
    {id: 1440, name: "København K Fredens Ark"},
    {id: 1440, name: "København K Sydområdet"},
    {id: 1440, name: "København K Bjørnekloen"},
    {id: 1440, name: "København K Nordområdet"},
    {id: 1440, name: "København K Mælkebøtten"},
    {id: 1440, name: "København K Fabriksområdet"},
    {id: 1440, name: "København K Løvehuset"},
    {id: 1440, name: "København K Mælkevejen"},
    {id: 1440, name: "København K Psyak"},
    {id: 1441, name: "København K Syddyssen"},
    {id: 1441, name: "København K Midtdyssen"},
    {id: 1441, name: "København K Norddyssen"},
    {id: 1448, name: "København K Asiatisk Plads 2 Udenrigsministeriet"},
    {id: 1450, name: "København K Nytorv"},
    {id: 1451, name: "København K Larslejsstræde"},
    {id: 1452, name: "København K Teglgårdstræde"},
    {id: 1453, name: "København K Sankt Peders Stræde"},
    {id: 1454, name: "København K Larsbjørnsstræde"},
    {id: 1455, name: "København K Studiestræde 1-49 + 2-42"},
    {id: 1456, name: "København K Vestergade"},
    {id: 1457, name: "København K Gammeltorv"},
    {id: 1458, name: "København K Kattesundet"},
    {id: 1459, name: "København K Frederiksberggade"},
    {id: 1460, name: "København K Mikkel Bryggers Gade"},
    {id: 1461, name: "København K Slutterigade"},
    {id: 1462, name: "København K Lavendelstræde"},
    {id: 1463, name: "København K Farvergade"},
    {id: 1464, name: "København K Hestemøllestræde"},
    {id: 1465, name: "København K Gåsegade"},
    {id: 1466, name: "København K Rådhusstræde"},
    {id: 1467, name: "København K Vandkunsten"},
    {id: 1468, name: "København K Løngangstræde"},
    {id: 1470, name: "København K Stormgade 2-16"},
    {id: 1471, name: "København K Ny Vestergade"},
    {id: 1472, name: "København K Ny Kongensgade,  til 17 + til 16"},
    {id: 1473, name: "København K Bryghusgade"},
    {id: 1500, name: "København V Bernstorffsgade 40 Vesterbro Postkontor"},
    {id: 1501, name: "København V Postboks"},
    {id: 1502, name: "København V Postboks"},
    {id: 1503, name: "København V Postboks"},
    {id: 1504, name: "København V Postboks"},
    {id: 1505, name: "København V Postboks"},
    {id: 1506, name: "København V Postboks"},
    {id: 1507, name: "København V Postboks"},
    {id: 1508, name: "København V Postboks"},
    {id: 1509, name: "København V Postboks"},
    {id: 1510, name: "København V Postboks"},
    {id: 1532, name: "København V Kystvejen 26, 2770 Kastrup Internationalt Postcenter, returforsendelser + consignment"},
    {id: 1533, name: "København V Kystvejen 26, 2770 Kastrup Internationalt Postcenter"},
    {id: 1550, name: "København V Bag Rådhuset"},
    {id: 1550, name: "København V Rådhuspladsen"},
    {id: 1551, name: "København V Jarmers Plads"},
    {id: 1552, name: "København V Vester Voldgade"},
    {id: 1553, name: "København V H.C. Andersens Boulevard"},
    {id: 1553, name: "København V Langebro"},
    {id: 1554, name: "København V Studiestræde 51-69 + 46-54"},
    {id: 1555, name: "København V Stormgade Ulige nr + 18-20"},
    {id: 1556, name: "København V Dantes Plads"},
    {id: 1557, name: "København V Ny Kongensgade, fra 18 + fra 19"},
    {id: 1558, name: "København V Christiansborggade"},
    {id: 1559, name: "København V Christians Brygge 24 - 30"},
    {id: 1560, name: "København V Kalvebod Brygge"},
    {id: 1561, name: "København V Fisketorvet"},
    {id: 1561, name: "København V Kalvebod Pladsvej"},
    {id: 1562, name: "København V Hambrosgade"},
    {id: 1563, name: "København V Otto Mønsteds Plads"},
    {id: 1564, name: "København V Rysensteensgade"},
    {id: 1566, name: "København V Tietgensgade 37 Post Danmark A/S"},
    {id: 1567, name: "København V Polititorvet"},
    {id: 1568, name: "København V Mitchellsgade"},
    {id: 1569, name: "København V Edvard Falcks Gade"},
    {id: 1570, name: "København V Banegårdspladsen"},
    {id: 1570, name: "København V Københavns Hovedbanegård"},
    {id: 1571, name: "København V Otto Mønsteds Gade"},
    {id: 1572, name: "København V Anker Heegaards Gade"},
    {id: 1573, name: "København V Puggaardsgade"},
    {id: 1574, name: "København V Niels Brocks Gade"},
    {id: 1575, name: "København V Ved Glyptoteket"},
    {id: 1576, name: "København V Stoltenbergsgade"},
    {id: 1577, name: "København V Bernstorffsgade"},
    {id: 1590, name: "København V Jarmers Plads 2 Realkredit Danmark"},
    {id: 1592, name: "København V Bernstorffsgade 17-19 Københavns Socialdirektorat"},
    {id: 1599, name: "København V Rådhuspladsen Københavns Rådhus"},
    {id: 1600, name: "København V Gyldenløvesgade Ulige nr."},
    {id: 1601, name: "København V Vester Søgade"},
    {id: 1602, name: "København V Nyropsgade"},
    {id: 1603, name: "København V Dahlerupsgade"},
    {id: 1604, name: "København V Kampmannsgade"},
    {id: 1605, name: "København V Herholdtsgade"},
    {id: 1606, name: "København V Vester Farimagsgade"},
    {id: 1607, name: "København V Staunings Plads"},
    {id: 1608, name: "København V Jernbanegade"},
    {id: 1609, name: "København V Axeltorv"},
    {id: 1610, name: "København V Gammel Kongevej 1-51 + 2-10"},
    {id: 1611, name: "København V Hammerichsgade"},
    {id: 1612, name: "København V Ved Vesterport"},
    {id: 1613, name: "København V Meldahlsgade"},
    {id: 1614, name: "København V Trommesalen"},
    {id: 1615, name: "København V Sankt Jørgens Allé"},
    {id: 1616, name: "København V Stenosgade"},
    {id: 1617, name: "København V Bagerstræde"},
    {id: 1618, name: "København V Tullinsgade"},
    {id: 1619, name: "København V Værnedamsvej Lige nr."},
    {id: 1620, name: "København V Vesterbros Torv"},
    {id: 1620, name: "København V Vesterbrogade 1-151 + 2-150"},
    {id: 1621, name: "København V Frederiksberg Allé 1 - 13B"},
    {id: 1622, name: "København V Boyesgade Ulige nr"},
    {id: 1623, name: "København V Kingosgade 1-9 + 2-6"},
    {id: 1624, name: "København V Brorsonsgade"},
    {id: 1630, name: "København V Vesterbrogade 3 Tivoli A/S"},
    {id: 1631, name: "København V Herman Triers Plads"},
    {id: 1632, name: "København V Julius Thomsens Gade Lige nr"},
    {id: 1633, name: "København V Kleinsgade"},
    {id: 1634, name: "København V Rosenørns Allé 2-18"},
    {id: 1635, name: "København V Åboulevard 1-13"},
    {id: 1639, name: "København V Gyldenløvesgade 15 Københavns Skatteforvaltning"},
    {id: 1640, name: "København V Dahlerupsgade 6 Københavns Folkeregister"},
    {id: 1650, name: "København V Istedgade"},
    {id: 1651, name: "København V Reventlowsgade"},
    {id: 1652, name: "København V Colbjørnsensgade"},
    {id: 1653, name: "København V Helgolandsgade"},
    {id: 1654, name: "København V Abel Cathrines Gade"},
    {id: 1655, name: "København V Viktoriagade"},
    {id: 1656, name: "København V Gasværksvej"},
    {id: 1657, name: "København V Eskildsgade"},
    {id: 1658, name: "København V Absalonsgade"},
    {id: 1659, name: "København V Svendsgade"},
    {id: 1660, name: "København V Otto Krabbes Plads"},
    {id: 1660, name: "København V Dannebrogsgade"},
    {id: 1661, name: "København V Westend"},
    {id: 1662, name: "København V Saxogade"},
    {id: 1663, name: "København V Oehlenschlægersgade"},
    {id: 1664, name: "København V Kaalundsgade"},
    {id: 1665, name: "København V Valdemarsgade"},
    {id: 1666, name: "København V Matthæusgade"},
    {id: 1667, name: "København V Frederiksstadsgade"},
    {id: 1668, name: "København V Mysundegade"},
    {id: 1669, name: "København V Flensborggade"},
    {id: 1670, name: "København V Enghave Plads"},
    {id: 1671, name: "København V Tove Ditlevsens Plads"},
    {id: 1671, name: "København V Haderslevgade"},
    {id: 1672, name: "København V Broagergade"},
    {id: 1673, name: "København V Ullerupgade"},
    {id: 1674, name: "København V Enghavevej, til 79 + til 78"},
    {id: 1675, name: "København V Kongshøjgade"},
    {id: 1676, name: "København V Sankelmarksgade"},
    {id: 1677, name: "København V Gråstensgade"},
    {id: 1699, name: "København V Staldgade"},
    {id: 1700, name: "København V Halmtorvet"},
    {id: 1701, name: "København V Reverdilsgade"},
    {id: 1702, name: "København V Stampesgade"},
    {id: 1703, name: "København V Lille Colbjørnsensgade"},
    {id: 1704, name: "København V Tietgensgade"},
    {id: 1705, name: "København V Ingerslevsgade"},
    {id: 1706, name: "København V Lille Istedgade"},
    {id: 1707, name: "København V Maria Kirkeplads"},
    {id: 1708, name: "København V Eriksgade"},
    {id: 1709, name: "København V Skydebanegade"},
    {id: 1710, name: "København V Kvægtorvsgade"},
    {id: 1711, name: "København V Flæsketorvet"},
    {id: 1712, name: "København V Høkerboderne"},
    {id: 1713, name: "København V Kvægtorvet"},
    {id: 1714, name: "København V Kødboderne"},
    {id: 1715, name: "København V Slagtehusgade"},
    {id: 1716, name: "København V Slagterboderne"},
    {id: 1717, name: "København V Skelbækgade"},
    {id: 1718, name: "København V Sommerstedgade"},
    {id: 1719, name: "København V Krusågade"},
    {id: 1720, name: "København V Sønder Boulevard"},
    {id: 1721, name: "København V Dybbølsgade"},
    {id: 1722, name: "København V Godsbanegade"},
    {id: 1723, name: "København V Letlandsgade"},
    {id: 1724, name: "København V Estlandsgade"},
    {id: 1725, name: "København V Esbern Snares Gade"},
    {id: 1726, name: "København V Arkonagade"},
    {id: 1727, name: "København V Asger Rygs Gade"},
    {id: 1728, name: "København V Skjalm Hvides Gade"},
    {id: 1729, name: "København V Sigerstedgade"},
    {id: 1730, name: "København V Knud Lavards Gade"},
    {id: 1731, name: "København V Erik Ejegods Gade"},
    {id: 1732, name: "København V Bodilsgade"},
    {id: 1733, name: "København V Palnatokesgade"},
    {id: 1734, name: "København V Heilsgade"},
    {id: 1735, name: "København V Røddinggade"},
    {id: 1736, name: "København V Bevtoftgade"},
    {id: 1737, name: "København V Bustrupgade"},
    {id: 1738, name: "København V Stenderupgade"},
    {id: 1739, name: "København V Enghave Passage"},
    {id: 1748, name: "København V Kammasvej 2"},
    {id: 1749, name: "København V Rahbeks Allé 3-15"},
    {id: 1750, name: "København V Vesterfælledvej"},
    {id: 1751, name: "København V Sundevedsgade"},
    {id: 1752, name: "København V Tøndergade"},
    {id: 1753, name: "København V Ballumgade"},
    {id: 1754, name: "København V Hedebygade"},
    {id: 1755, name: "København V Møgeltøndergade"},
    {id: 1756, name: "København V Amerikavej"},
    {id: 1757, name: "København V Trøjborggade"},
    {id: 1758, name: "København V Lyrskovgade"},
    {id: 1759, name: "København V Rejsbygade"},
    {id: 1760, name: "København V Ny Carlsberg Vej"},
    {id: 1761, name: "København V Ejderstedgade"},
    {id: 1762, name: "København V Slesvigsgade"},
    {id: 1763, name: "København V Dannevirkegade"},
    {id: 1764, name: "København V Alsgade"},
    {id: 1765, name: "København V Angelgade"},
    {id: 1766, name: "København V Slien"},
    {id: 1770, name: "København V Carstensgade"},
    {id: 1771, name: "København V Lundbyesgade"},
    {id: 1772, name: "København V Ernst Meyers Gade"},
    {id: 1773, name: "København V Bissensgade"},
    {id: 1774, name: "København V Küchlersgade"},
    {id: 1775, name: "København V Freundsgade"},
    {id: 1777, name: "København V Jerichausgade"},
    {id: 1778, name: "København V Pasteursvej"},
    {id: 1780, name: "København V  Erhvervskunder"},
    {id: 1782, name: "København V Ufrankerede svarforsendelser"},
    {id: 1784, name: "København V Gerdasgade 37 Forlagsgruppen (ufrankerede svarforsendelser)"},
    {id: 1785, name: "København V Rådhuspladsen 33 og 37 Politiken og Ekstrabladet"},
    {id: 1786, name: "København V Vesterbrogade 8 Unibank"},
    {id: 1787, name: "København V H.C. Andersens Boulevard 18 Dansk Industri"},
    {id: 1788, name: "København V  Erhvervskunder"},
    {id: 1789, name: "København V H.C. Andersens Boulevard 12 Star Tour A/S"},
    {id: 1790, name: "København V  Erhvervskunder"},
    {id: 1795, name: "København V Gerdasgade 35-37 Bogklubforlag"},
    {id: 1799, name: "København V Vester Fælledvej 100 Carlsberg"},
    {id: 1800, name: "Frederiksberg C Vesterbrogade, fra 152 og 153"},
    {id: 1801, name: "Frederiksberg C Rahbeks Allé 2-36 + 17-23"},
    {id: 1802, name: "Frederiksberg C Halls Allé"},
    {id: 1803, name: "Frederiksberg C Brøndsteds Allé"},
    {id: 1804, name: "Frederiksberg C Bakkegårds Allé"},
    {id: 1805, name: "Frederiksberg C Kammasvej 1-3 + 4"},
    {id: 1806, name: "Frederiksberg C Jacobys Allé"},
    {id: 1807, name: "Frederiksberg C Schlegels Allé"},
    {id: 1808, name: "Frederiksberg C Asmussens Allé"},
    {id: 1809, name: "Frederiksberg C Frydendalsvej"},
    {id: 1810, name: "Frederiksberg C Platanvej"},
    {id: 1811, name: "Frederiksberg C Asgårdsvej"},
    {id: 1812, name: "Frederiksberg C Kochsvej"},
    {id: 1813, name: "Frederiksberg C Henrik Ibsens Vej"},
    {id: 1814, name: "Frederiksberg C Carit Etlars Vej"},
    {id: 1815, name: "Frederiksberg C Paludan Müllers Vej"},
    {id: 1816, name: "Frederiksberg C Engtoftevej"},
    {id: 1817, name: "Frederiksberg C Carl Bernhards Vej"},
    {id: 1818, name: "Frederiksberg C Kingosgade 8-12 + 11-17"},
    {id: 1819, name: "Frederiksberg C Værnedamsvej Ulige nr."},
    {id: 1820, name: "Frederiksberg C Frederiksberg Allé 15-65 + 2-104"},
    {id: 1822, name: "Frederiksberg C Boyesgade Lige nr"},
    {id: 1823, name: "Frederiksberg C Haveselskabetsvej"},
    {id: 1824, name: "Frederiksberg C Sankt Thomas Allé"},
    {id: 1825, name: "Frederiksberg C Hauchsvej"},
    {id: 1826, name: "Frederiksberg C Alhambravej"},
    {id: 1827, name: "Frederiksberg C Mynstersvej"},
    {id: 1828, name: "Frederiksberg C Martensens Allé"},
    {id: 1829, name: "Frederiksberg C Madvigs Allé"},
    {id: 1835, name: "Frederiksberg C Postboks inkl. Frederiksberg C Postkontor"},
    {id: 1850, name: "Frederiksberg C Gammel Kongevej 85-179 + 60-178"},
    {id: 1851, name: "Frederiksberg C Nyvej"},
    {id: 1852, name: "Frederiksberg C Amicisvej"},
    {id: 1853, name: "Frederiksberg C Maglekildevej"},
    {id: 1854, name: "Frederiksberg C Dr. Priemes Vej"},
    {id: 1855, name: "Frederiksberg C Hollændervej"},
    {id: 1856, name: "Frederiksberg C Edisonsvej"},
    {id: 1857, name: "Frederiksberg C Hortensiavej"},
    {id: 1860, name: "Frederiksberg C Christian Winthers Vej"},
    {id: 1861, name: "Frederiksberg C Sagasvej"},
    {id: 1862, name: "Frederiksberg C Rathsacksvej"},
    {id: 1863, name: "Frederiksberg C Ceresvej"},
    {id: 1864, name: "Frederiksberg C Grundtvigsvej"},
    {id: 1865, name: "Frederiksberg C Grundtvigs Sidevej"},
    {id: 1866, name: "Frederiksberg C Henrik Steffens Vej"},
    {id: 1867, name: "Frederiksberg C Acaciavej"},
    {id: 1868, name: "Frederiksberg C Bianco Lunos Allé"},
    {id: 1870, name: "Frederiksberg C Bülowsvej"},
    {id: 1871, name: "Frederiksberg C Thorvaldsensvej"},
    {id: 1872, name: "Frederiksberg C Bomhoffs Have"},
    {id: 1873, name: "Frederiksberg C Helenevej"},
    {id: 1874, name: "Frederiksberg C Harsdorffsvej"},
    {id: 1875, name: "Frederiksberg C Amalievej"},
    {id: 1876, name: "Frederiksberg C Kastanievej"},
    {id: 1877, name: "Frederiksberg C Lindevej"},
    {id: 1878, name: "Frederiksberg C Uraniavej"},
    {id: 1879, name: "Frederiksberg C H.C. Ørsteds Vej"},
    {id: 1900, name: "Frederiksberg C Vodroffsvej"},
    {id: 1901, name: "Frederiksberg C Tårnborgvej"},
    {id: 1902, name: "Frederiksberg C Lykkesholms Allé"},
    {id: 1903, name: "Frederiksberg C Sankt Knuds Vej"},
    {id: 1904, name: "Frederiksberg C Forhåbningsholms Allé"},
    {id: 1905, name: "Frederiksberg C Svanholmsvej"},
    {id: 1906, name: "Frederiksberg C Schønbergsgade"},
    {id: 1908, name: "Frederiksberg C Prinsesse Maries Allé"},
    {id: 1909, name: "Frederiksberg C Vodroffs Tværgade"},
    {id: 1910, name: "Frederiksberg C Danasvej"},
    {id: 1911, name: "Frederiksberg C Niels Ebbesens Vej"},
    {id: 1912, name: "Frederiksberg C Svend Trøsts Vej"},
    {id: 1913, name: "Frederiksberg C Carl Plougs Vej"},
    {id: 1914, name: "Frederiksberg C Vodroffslund"},
    {id: 1915, name: "Frederiksberg C Danas Plads"},
    {id: 1916, name: "Frederiksberg C Norsvej"},
    {id: 1917, name: "Frederiksberg C Sveasvej"},
    {id: 1920, name: "Frederiksberg C Forchhammersvej"},
    {id: 1921, name: "Frederiksberg C Sankt Markus Plads"},
    {id: 1922, name: "Frederiksberg C Sankt Markus Allé"},
    {id: 1923, name: "Frederiksberg C Johnstrups Allé"},
    {id: 1924, name: "Frederiksberg C Steenstrups Allé"},
    {id: 1925, name: "Frederiksberg C Julius Thomsens Plads"},
    {id: 1926, name: "Frederiksberg C Martinsvej"},
    {id: 1927, name: "Frederiksberg C Suomisvej"},
    {id: 1928, name: "Frederiksberg C Filippavej"},
    {id: 1931, name: "Frederiksberg C Ufrankerede svarforsendelser"},
    {id: 1950, name: "Frederiksberg C Hostrupsvej"},
    {id: 1951, name: "Frederiksberg C Christian Richardts Vej"},
    {id: 1952, name: "Frederiksberg C Falkonervænget"},
    {id: 1953, name: "Frederiksberg C Sankt Nikolaj Vej"},
    {id: 1954, name: "Frederiksberg C Hostrups Have"},
    {id: 1955, name: "Frederiksberg C Dr. Abildgaards Allé"},
    {id: 1956, name: "Frederiksberg C L.I. Brandes Allé"},
    {id: 1957, name: "Frederiksberg C N.J. Fjords Allé"},
    {id: 1958, name: "Frederiksberg C Rolighedsvej"},
    {id: 1959, name: "Frederiksberg C Falkonergårdsvej"},
    {id: 1960, name: "Frederiksberg C Åboulevard 15-55"},
    {id: 1961, name: "Frederiksberg C J.M. Thieles Vej"},
    {id: 1962, name: "Frederiksberg C Fuglevangsvej"},
    {id: 1963, name: "Frederiksberg C Bille Brahes Vej"},
    {id: 1964, name: "Frederiksberg C Ingemannsvej"},
    {id: 1965, name: "Frederiksberg C Erik Menveds Vej"},
    {id: 1966, name: "Frederiksberg C Steenwinkelsvej"},
    {id: 1967, name: "Frederiksberg C Svanemosegårdsvej"},
    {id: 1970, name: "Frederiksberg C Rosenørns Allé 1-65 + 20-70"},
    {id: 1971, name: "Frederiksberg C Adolph Steens Allé"},
    {id: 1972, name: "Frederiksberg C Worsaaesvej"},
    {id: 1973, name: "Frederiksberg C Jakob Dannefærds Vej"},
    {id: 1974, name: "Frederiksberg C Julius Thomsens Gade Ulige nr"},
    {id: 1999, name: "Frederiksberg C Rosenørns Allé 22s Radio"},
    {id: 2000, name: "Frederiksberg"},
    {id: 2100, name: "København Ø"},
    {id: 2200, name: "København N"},
    {id: 2300, name: "København S"},
    {id: 2400, name: "København NV"},
    {id: 2450, name: "København SV"},
    {id: 2500, name: "Valby"},
    {id: 2600, name: "Glostrup"},
    {id: 2605, name: "Brøndby"},
    {id: 2610, name: "Rødovre"},
    {id: 2620, name: "Albertslund"},
    {id: 2625, name: "Vallensbæk"},
    {id: 2630, name: "Taastrup"},
    {id: 2633, name: "Taastrup Erhvervskunder"},
    {id: 2635, name: "Ishøj"},
    {id: 2640, name: "Hedehusene"},
    {id: 2650, name: "Hvidovre"},
    {id: 2660, name: "Brøndby Strand"},
    {id: 2665, name: "Vallensbæk Strand"},
    {id: 2670, name: "Greve"},
    {id: 2680, name: "Solrød Strand"},
    {id: 2690, name: "Karlslunde"},
    {id: 2700, name: "Brønshøj"},
    {id: 2720, name: "Vanløse"},
    {id: 2730, name: "Herlev"},
    {id: 2740, name: "Skovlunde"},
    {id: 2750, name: "Ballerup"},
    {id: 2760, name: "Måløv"},
    {id: 2765, name: "Smørum"},
    {id: 2770, name: "Kastrup"},
    {id: 2791, name: "Dragør"},
    {id: 2800, name: "Kongens Lyngby"},
    {id: 2820, name: "Gentofte"},
    {id: 2830, name: "Virum"},
    {id: 2840, name: "Holte"},
    {id: 2850, name: "Nærum"},
    {id: 2860, name: "Søborg"},
    {id: 2870, name: "Dyssegård"},
    {id: 2880, name: "Bagsværd"},
    {id: 2900, name: "Hellerup"},
    {id: 2920, name: "Charlottenlund"},
    {id: 2930, name: "Klampenborg"},
    {id: 2942, name: "Skodsborg"},
    {id: 2950, name: "Vedbæk"},
    {id: 2960, name: "Rungsted Kyst"},
    {id: 2970, name: "Hørsholm"},
    {id: 2980, name: "Kokkedal"},
    {id: 2990, name: "Nivå"},
    {id: 3000, name: "Helsingør"},
    {id: 3050, name: "Humlebæk"},
    {id: 3060, name: "Espergærde"},
    {id: 3070, name: "Snekkersten"},
    {id: 3080, name: "Tikøb"},
    {id: 3100, name: "Hornbæk"},
    {id: 3120, name: "Dronningmølle"},
    {id: 3140, name: "Ålsgårde"},
    {id: 3150, name: "Hellebæk"},
    {id: 3200, name: "Helsinge"},
    {id: 3210, name: "Vejby"},
    {id: 3220, name: "Tisvildeleje"},
    {id: 3230, name: "Græsted"},
    {id: 3250, name: "Gilleleje"},
    {id: 3300, name: "Frederiksværk"},
    {id: 3310, name: "Ølsted"},
    {id: 3320, name: "Skævinge"},
    {id: 3330, name: "Gørløse"},
    {id: 3360, name: "Liseleje"},
    {id: 3370, name: "Melby"},
    {id: 3390, name: "Hundested"},
    {id: 3400, name: "Hillerød"},
    {id: 3450, name: "Allerød"},
    {id: 3460, name: "Birkerød"},
    {id: 3480, name: "Fredensborg"},
    {id: 3490, name: "Kvistgård"},
    {id: 3500, name: "Værløse"},
    {id: 3520, name: "Farum"},
    {id: 3540, name: "Lynge"},
    {id: 3550, name: "Slangerup"},
    {id: 3600, name: "Frederikssund"},
    {id: 3630, name: "Jægerspris"},
    {id: 3650, name: "Ølstykke"},
    {id: 3660, name: "Stenløse"},
    {id: 3670, name: "Veksø Sjælland"},
    {id: 3700, name: "Rønne"},
    {id: 3720, name: "Aakirkeby"},
    {id: 3730, name: "Nexø"},
    {id: 3740, name: "Svaneke"},
    {id: 3751, name: "Østermarie"},
    {id: 3760, name: "Gudhjem"},
    {id: 3770, name: "Allinge"},
    {id: 3782, name: "Klemensker"},
    {id: 3790, name: "Hasle"},
    {id: 4000, name: "Roskilde"},
    {id: 4040, name: "Jyllinge"},
    {id: 4050, name: "Skibby"},
    {id: 4060, name: "Kirke Såby"},
    {id: 4070, name: "Kirke Hyllinge"},
    {id: 4100, name: "Ringsted"},
    {id: 4105, name: "Ringsted Midtsjællands Postcenter + erhvervskunder"},
    {id: 4129, name: "Ringsted Ufrankerede svarforsendelser"},
    {id: 4130, name: "Viby Sjælland"},
    {id: 4140, name: "Borup"},
    {id: 4160, name: "Herlufmagle"},
    {id: 4171, name: "Glumsø"},
    {id: 4173, name: "Fjenneslev"},
    {id: 4174, name: "Jystrup Midtsj"},
    {id: 4180, name: "Sorø"},
    {id: 4190, name: "Munke Bjergby"},
    {id: 4200, name: "Slagelse"},
    {id: 4220, name: "Korsør"},
    {id: 4230, name: "Skælskør"},
    {id: 4241, name: "Vemmelev"},
    {id: 4242, name: "Boeslunde"},
    {id: 4243, name: "Rude"},
    {id: 4250, name: "Fuglebjerg"},
    {id: 4261, name: "Dalmose"},
    {id: 4262, name: "Sandved"},
    {id: 4270, name: "Høng"},
    {id: 4281, name: "Gørlev"},
    {id: 4291, name: "Ruds Vedby"},
    {id: 4293, name: "Dianalund"},
    {id: 4295, name: "Stenlille"},
    {id: 4296, name: "Nyrup"},
    {id: 4300, name: "Holbæk"},
    {id: 4320, name: "Lejre"},
    {id: 4330, name: "Hvalsø"},
    {id: 4340, name: "Tølløse"},
    {id: 4350, name: "Ugerløse"},
    {id: 4360, name: "Kirke Eskilstrup"},
    {id: 4370, name: "Store Merløse"},
    {id: 4390, name: "Vipperød"},
    {id: 4400, name: "Kalundborg"},
    {id: 4420, name: "Regstrup"},
    {id: 4440, name: "Mørkøv"},
    {id: 4450, name: "Jyderup"},
    {id: 4460, name: "Snertinge"},
    {id: 4470, name: "Svebølle"},
    {id: 4480, name: "Store Fuglede"},
    {id: 4490, name: "Jerslev Sjælland"},
    {id: 4500, name: "Nykøbing Sj"},
    {id: 4520, name: "Svinninge"},
    {id: 4532, name: "Gislinge"},
    {id: 4534, name: "Hørve"},
    {id: 4540, name: "Fårevejle"},
    {id: 4550, name: "Asnæs"},
    {id: 4560, name: "Vig"},
    {id: 4571, name: "Grevinge"},
    {id: 4572, name: "Nørre Asmindrup"},
    {id: 4573, name: "Højby"},
    {id: 4581, name: "Rørvig"},
    {id: 4583, name: "Sjællands Odde"},
    {id: 4591, name: "Føllenslev"},
    {id: 4592, name: "Sejerø"},
    {id: 4593, name: "Eskebjerg"},
    {id: 4600, name: "Køge"},
    {id: 4621, name: "Gadstrup"},
    {id: 4622, name: "Havdrup"},
    {id: 4623, name: "Lille Skensved"},
    {id: 4632, name: "Bjæverskov"},
    {id: 4640, name: "Fakse"},
    {id: 4652, name: "Hårlev"},
    {id: 4653, name: "Karise"},
    {id: 4654, name: "Fakse Ladeplads"},
    {id: 4660, name: "Store Heddinge"},
    {id: 4671, name: "Strøby"},
    {id: 4672, name: "Klippinge"},
    {id: 4673, name: "Rødvig Stevns"},
    {id: 4681, name: "Herfølge"},
    {id: 4682, name: "Tureby"},
    {id: 4683, name: "Rønnede"},
    {id: 4684, name: "Holmegaard"},
    {id: 4690, name: "Haslev"},
    {id: 4700, name: "Næstved"},
    {id: 4720, name: "Præstø"},
    {id: 4733, name: "Tappernøje"},
    {id: 4735, name: "Mern"},
    {id: 4736, name: "Karrebæksminde"},
    {id: 4750, name: "Lundby"},
    {id: 4760, name: "Vordingborg"},
    {id: 4771, name: "Kalvehave"},
    {id: 4772, name: "Langebæk"},
    {id: 4773, name: "Stensved"},
    {id: 4780, name: "Stege"},
    {id: 4791, name: "Borre"},
    {id: 4792, name: "Askeby"},
    {id: 4793, name: "Bogø By"},
    {id: 4800, name: "Nykøbing F"},
    {id: 4840, name: "Nørre Alslev"},
    {id: 4850, name: "Stubbekøbing"},
    {id: 4862, name: "Guldborg"},
    {id: 4863, name: "Eskilstrup"},
    {id: 4871, name: "Horbelev"},
    {id: 4872, name: "Idestrup"},
    {id: 4873, name: "Væggerløse"},
    {id: 4874, name: "Gedser"},
    {id: 4880, name: "Nysted"},
    {id: 4891, name: "Toreby L"},
    {id: 4892, name: "Kettinge"},
    {id: 4894, name: "Øster Ulslev"},
    {id: 4895, name: "Errindlev"},
    {id: 4900, name: "Nakskov"},
    {id: 4912, name: "Harpelunde"},
    {id: 4913, name: "Horslunde"},
    {id: 4920, name: "Søllested"},
    {id: 4930, name: "Maribo"},
    {id: 4941, name: "Bandholm"},
    {id: 4943, name: "Torrig L"},
    {id: 4944, name: "Fejø"},
    {id: 4951, name: "Nørreballe"},
    {id: 4952, name: "Stokkemarke"},
    {id: 4953, name: "Vesterborg"},
    {id: 4960, name: "Holeby"},
    {id: 4970, name: "Rødby"},
    {id: 4983, name: "Dannemare"},
    {id: 4990, name: "Sakskøbing"},
    {id: 5000, name: "Odense C"},
    {id: 5029, name: "Odense C Ufrankerede svarforsendelser"},
    {id: 5090, name: "Odense C Erhvervskunder"},
    {id: 5100, name: "Odense C Postboks"},
    {id: 5200, name: "Odense V"},
    {id: 5210, name: "Odense NV"},
    {id: 5220, name: "Odense SØ"},
    {id: 5230, name: "Odense M"},
    {id: 5240, name: "Odense NØ"},
    {id: 5250, name: "Odense SV"},
    {id: 5260, name: "Odense S"},
    {id: 5270, name: "Odense N"},
    {id: 5290, name: "Marslev"},
    {id: 5300, name: "Kerteminde"},
    {id: 5320, name: "Agedrup"},
    {id: 5330, name: "Munkebo"},
    {id: 5350, name: "Rynkeby"},
    {id: 5370, name: "Mesinge"},
    {id: 5380, name: "Dalby"},
    {id: 5390, name: "Martofte"},
    {id: 5400, name: "Bogense"},
    {id: 5450, name: "Otterup"},
    {id: 5462, name: "Morud"},
    {id: 5463, name: "Harndrup"},
    {id: 5464, name: "Brenderup Fyn"},
    {id: 5466, name: "Asperup"},
    {id: 5471, name: "Søndersø"},
    {id: 5474, name: "Veflinge"},
    {id: 5485, name: "Skamby"},
    {id: 5491, name: "Blommenslyst"},
    {id: 5492, name: "Vissenbjerg"},
    {id: 5500, name: "Middelfart"},
    {id: 5540, name: "Ullerslev"},
    {id: 5550, name: "Langeskov"},
    {id: 5560, name: "Aarup"},
    {id: 5580, name: "Nørre Aaby"},
    {id: 5591, name: "Gelsted"},
    {id: 5592, name: "Ejby"},
    {id: 5600, name: "Faaborg"},
    {id: 5610, name: "Assens"},
    {id: 5620, name: "Glamsbjerg"},
    {id: 5631, name: "Ebberup"},
    {id: 5642, name: "Millinge"},
    {id: 5672, name: "Broby"},
    {id: 5683, name: "Haarby"},
    {id: 5690, name: "Tommerup"},
    {id: 5700, name: "Svendborg"},
    {id: 5750, name: "Ringe"},
    {id: 5762, name: "Vester Skerninge"},
    {id: 5771, name: "Stenstrup"},
    {id: 5772, name: "Kværndrup"},
    {id: 5792, name: "Årslev"},
    {id: 5800, name: "Nyborg"},
    {id: 5853, name: "Ørbæk"},
    {id: 5854, name: "Gislev"},
    {id: 5856, name: "Ryslinge"},
    {id: 5863, name: "Ferritslev Fyn"},
    {id: 5871, name: "Frørup"},
    {id: 5874, name: "Hesselager"},
    {id: 5881, name: "Skårup Fyn"},
    {id: 5882, name: "Vejstrup"},
    {id: 5883, name: "Oure"},
    {id: 5884, name: "Gudme"},
    {id: 5892, name: "Gudbjerg Sydfyn"},
    {id: 5900, name: "Rudkøbing"},
    {id: 5932, name: "Humble"},
    {id: 5935, name: "Bagenkop"},
    {id: 5953, name: "Tranekær"},
    {id: 5960, name: "Marstal"},
    {id: 5970, name: "Ærøskøbing"},
    {id: 5985, name: "Søby Ærø"},
    {id: 6000, name: "Kolding"},
    {id: 6040, name: "Egtved"},
    {id: 6051, name: "Almind"},
    {id: 6052, name: "Viuf"},
    {id: 6064, name: "Jordrup"},
    {id: 6070, name: "Christiansfeld"},
    {id: 6091, name: "Bjert"},
    {id: 6092, name: "Sønder Stenderup"},
    {id: 6093, name: "Sjølund"},
    {id: 6094, name: "Hejls"},
    {id: 6100, name: "Haderslev"},
    {id: 6200, name: "Aabenraa"},
    {id: 6230, name: "Rødekro"},
    {id: 6240, name: "Løgumkloster"},
    {id: 6261, name: "Bredebro"},
    {id: 6270, name: "Tønder"},
    {id: 6280, name: "Højer"},
    {id: 6300, name: "Gråsten"},
    {id: 6310, name: "Broager"},
    {id: 6320, name: "Egernsund"},
    {id: 6330, name: "Padborg"},
    {id: 6340, name: "Kruså"},
    {id: 6360, name: "Tinglev"},
    {id: 6372, name: "Bylderup-Bov"},
    {id: 6392, name: "Bolderslev"},
    {id: 6400, name: "Sønderborg"},
    {id: 6430, name: "Nordborg"},
    {id: 6440, name: "Augustenborg"},
    {id: 6470, name: "Sydals"},
    {id: 6500, name: "Vojens"},
    {id: 6510, name: "Gram"},
    {id: 6520, name: "Toftlund"},
    {id: 6534, name: "Agerskov"},
    {id: 6535, name: "Branderup J"},
    {id: 6541, name: "Bevtoft"},
    {id: 6560, name: "Sommersted"},
    {id: 6580, name: "Vamdrup"},
    {id: 6600, name: "Vejen"},
    {id: 6621, name: "Gesten"},
    {id: 6622, name: "Bække"},
    {id: 6623, name: "Vorbasse"},
    {id: 6630, name: "Rødding"},
    {id: 6640, name: "Lunderskov"},
    {id: 6650, name: "Brørup"},
    {id: 6660, name: "Lintrup"},
    {id: 6670, name: "Holsted"},
    {id: 6682, name: "Hovborg"},
    {id: 6683, name: "Føvling"},
    {id: 6690, name: "Gørding"},
    {id: 6700, name: "Esbjerg"},
    {id: 6701, name: "Esbjerg Postboks"},
    {id: 6705, name: "Esbjerg Ø"},
    {id: 6710, name: "Esbjerg V"},
    {id: 6715, name: "Esbjerg N"},
    {id: 6720, name: "Fanø"},
    {id: 6731, name: "Tjæreborg"},
    {id: 6740, name: "Bramming"},
    {id: 6752, name: "Glejbjerg"},
    {id: 6753, name: "Agerbæk"},
    {id: 6760, name: "Ribe"},
    {id: 6771, name: "Gredstedbro"},
    {id: 6780, name: "Skærbæk"},
    {id: 6792, name: "Rømø"},
    {id: 6800, name: "Varde"},
    {id: 6818, name: "Årre"},
    {id: 6823, name: "Ansager"},
    {id: 6830, name: "Nørre Nebel"},
    {id: 6840, name: "Oksbøl"},
    {id: 6851, name: "Janderup Vestj"},
    {id: 6852, name: "Billum"},
    {id: 6853, name: "Vejers Strand"},
    {id: 6854, name: "Henne"},
    {id: 6855, name: "Outrup"},
    {id: 6857, name: "Blåvand"},
    {id: 6862, name: "Tistrup"},
    {id: 6870, name: "Ølgod"},
    {id: 6880, name: "Tarm"},
    {id: 6893, name: "Hemmet"},
    {id: 6900, name: "Skjern"},
    {id: 6920, name: "Videbæk"},
    {id: 6933, name: "Kibæk"},
    {id: 6940, name: "Lem St"},
    {id: 6950, name: "Ringkøbing"},
    {id: 6960, name: "Hvide Sande"},
    {id: 6971, name: "Spjald"},
    {id: 6973, name: "Ørnhøj"},
    {id: 6980, name: "Tim"},
    {id: 6990, name: "Ulfborg"},
    {id: 7000, name: "Fredericia"},
    {id: 7007, name: "Fredericia Sydjyllands Postcenter + erhvervskunder"},
    {id: 7029, name: "Fredericia Ufrankerede svarforsendelser"},
    {id: 7080, name: "Børkop"},
    {id: 7100, name: "Vejle"},
    {id: 7120, name: "Vejle Øst"},
    {id: 7130, name: "Juelsminde"},
    {id: 7140, name: "Stouby"},
    {id: 7150, name: "Barrit"},
    {id: 7160, name: "Tørring"},
    {id: 7171, name: "Uldum"},
    {id: 7173, name: "Vonge"},
    {id: 7182, name: "Bredsten"},
    {id: 7183, name: "Randbøl"},
    {id: 7184, name: "Vandel"},
    {id: 7190, name: "Billund"},
    {id: 7200, name: "Grindsted"},
    {id: 7250, name: "Hejnsvig"},
    {id: 7260, name: "Sønder Omme"},
    {id: 7270, name: "Stakroge"},
    {id: 7280, name: "Sønder Felding"},
    {id: 7300, name: "Jelling"},
    {id: 7321, name: "Gadbjerg"},
    {id: 7323, name: "Give"},
    {id: 7330, name: "Brande"},
    {id: 7361, name: "Ejstrupholm"},
    {id: 7362, name: "Hampen"},
    {id: 7400, name: "Herning"},
    {id: 7401, name: "Herning Erhvervskunder"},
    {id: 7429, name: "Herning Ufrankerede svarforsendelser"},
    {id: 7430, name: "Ikast"},
    {id: 7441, name: "Bording"},
    {id: 7442, name: "Engesvang"},
    {id: 7451, name: "Sunds"},
    {id: 7470, name: "Karup J"},
    {id: 7480, name: "Vildbjerg"},
    {id: 7490, name: "Aulum"},
    {id: 7500, name: "Holstebro"},
    {id: 7540, name: "Haderup"},
    {id: 7550, name: "Sørvad"},
    {id: 7560, name: "Hjerm"},
    {id: 7570, name: "Vemb"},
    {id: 7600, name: "Struer"},
    {id: 7620, name: "Lemvig"},
    {id: 7650, name: "Bøvlingbjerg"},
    {id: 7660, name: "Bækmarksbro"},
    {id: 7673, name: "Harboøre"},
    {id: 7680, name: "Thyborøn"},
    {id: 7700, name: "Thisted"},
    {id: 7730, name: "Hanstholm"},
    {id: 7741, name: "Frøstrup"},
    {id: 7742, name: "Vesløs"},
    {id: 7752, name: "Snedsted"},
    {id: 7755, name: "Bedsted Thy"},
    {id: 7760, name: "Hurup Thy"},
    {id: 7770, name: "Vestervig"},
    {id: 7790, name: "Thyholm"},
    {id: 7800, name: "Skive"},
    {id: 7830, name: "Vinderup"},
    {id: 7840, name: "Højslev"},
    {id: 7850, name: "Stoholm Jyll"},
    {id: 7860, name: "Spøttrup"},
    {id: 7870, name: "Roslev"},
    {id: 7884, name: "Fur"},
    {id: 7900, name: "Nykøbing M"},
    {id: 7950, name: "Erslev"},
    {id: 7960, name: "Karby"},
    {id: 7970, name: "Redsted M"},
    {id: 7980, name: "Vils"},
    {id: 7990, name: "Øster Assels"},
    {id: 8000, name: "Århus C"},
    {id: 8100, name: "Århus C Postboks"},
    {id: 8200, name: "Århus N"},
    {id: 8210, name: "Århus V"},
    {id: 8220, name: "Brabrand"},
    {id: 8229, name: "Risskov Ø Ufrankerede svarforsendelser"},
    {id: 8230, name: "Åbyhøj"},
    {id: 8240, name: "Risskov"},
    {id: 8245, name: "Risskov Ø Østjyllands Postcenter + erhvervskunder"},
    {id: 8250, name: "Egå"},
    {id: 8260, name: "Viby J"},
    {id: 8270, name: "Højbjerg"},
    {id: 8300, name: "Odder"},
    {id: 8305, name: "Samsø"},
    {id: 8310, name: "Tranbjerg J"},
    {id: 8320, name: "Mårslet"},
    {id: 8330, name: "Beder"},
    {id: 8340, name: "Malling"},
    {id: 8350, name: "Hundslund"},
    {id: 8355, name: "Solbjerg"},
    {id: 8361, name: "Hasselager"},
    {id: 8362, name: "Hørning"},
    {id: 8370, name: "Hadsten"},
    {id: 8380, name: "Trige"},
    {id: 8381, name: "Tilst"},
    {id: 8382, name: "Hinnerup"},
    {id: 8400, name: "Ebeltoft"},
    {id: 8410, name: "Rønde"},
    {id: 8420, name: "Knebel"},
    {id: 8444, name: "Balle"},
    {id: 8450, name: "Hammel"},
    {id: 8462, name: "Harlev J"},
    {id: 8464, name: "Galten"},
    {id: 8471, name: "Sabro"},
    {id: 8472, name: "Sporup"},
    {id: 8500, name: "Grenaa"},
    {id: 8520, name: "Lystrup"},
    {id: 8530, name: "Hjortshøj"},
    {id: 8541, name: "Skødstrup"},
    {id: 8543, name: "Hornslet"},
    {id: 8544, name: "Mørke"},
    {id: 8550, name: "Ryomgård"},
    {id: 8560, name: "Kolind"},
    {id: 8570, name: "Trustrup"},
    {id: 8581, name: "Nimtofte"},
    {id: 8585, name: "Glesborg"},
    {id: 8586, name: "Ørum Djurs"},
    {id: 8592, name: "Anholt"},
    {id: 8600, name: "Silkeborg"},
    {id: 8620, name: "Kjellerup"},
    {id: 8632, name: "Lemming"},
    {id: 8641, name: "Sorring"},
    {id: 8643, name: "Ans By"},
    {id: 8653, name: "Them"},
    {id: 8654, name: "Bryrup"},
    {id: 8660, name: "Skanderborg"},
    {id: 8670, name: "Låsby"},
    {id: 8680, name: "Ry"},
    {id: 8700, name: "Horsens"},
    {id: 8721, name: "Daugård"},
    {id: 8722, name: "Hedensted"},
    {id: 8723, name: "Løsning"},
    {id: 8732, name: "Hovedgård"},
    {id: 8740, name: "Brædstrup"},
    {id: 8751, name: "Gedved"},
    {id: 8752, name: "Østbirk"},
    {id: 8762, name: "Flemming"},
    {id: 8763, name: "Rask Mølle"},
    {id: 8765, name: "Klovborg"},
    {id: 8766, name: "Nørre Snede"},
    {id: 8781, name: "Stenderup"},
    {id: 8783, name: "Hornsyld"},
    {id: 8800, name: "Viborg"},
    {id: 8830, name: "Tjele"},
    {id: 8831, name: "Løgstrup"},
    {id: 8832, name: "Skals"},
    {id: 8840, name: "Rødkærsbro"},
    {id: 8850, name: "Bjerringbro"},
    {id: 8860, name: "Ulstrup"},
    {id: 8870, name: "Langå"},
    {id: 8881, name: "Thorsø"},
    {id: 8882, name: "Fårvang"},
    {id: 8883, name: "Gjern"},
    {id: 8900, name: "Randers"},
    {id: 8950, name: "Ørsted"},
    {id: 8961, name: "Allingåbro"},
    {id: 8963, name: "Auning"},
    {id: 8970, name: "Havndal"},
    {id: 8981, name: "Spentrup"},
    {id: 8983, name: "Gjerlev J"},
    {id: 8990, name: "Fårup"},
    {id: 9000, name: "Aalborg"},
    {id: 9020, name: "Aalborg Erhvervskunder"},
    {id: 9029, name: "Aalborg Ufrankerede svarforsendelser"},
    {id: 9100, name: "Aalborg Postboks"},
    {id: 9200, name: "Aalborg SV"},
    {id: 9210, name: "Aalborg SØ"},
    {id: 9220, name: "Aalborg Øst"},
    {id: 9230, name: "Svenstrup J"},
    {id: 9240, name: "Nibe"},
    {id: 9260, name: "Gistrup"},
    {id: 9270, name: "Klarup"},
    {id: 9280, name: "Storvorde"},
    {id: 9293, name: "Kongerslev"},
    {id: 9300, name: "Sæby"},
    {id: 9310, name: "Vodskov"},
    {id: 9320, name: "Hjallerup"},
    {id: 9330, name: "Dronninglund"},
    {id: 9340, name: "Asaa"},
    {id: 9352, name: "Dybvad"},
    {id: 9362, name: "Gandrup"},
    {id: 9370, name: "Hals"},
    {id: 9380, name: "Vestbjerg"},
    {id: 9381, name: "Sulsted"},
    {id: 9382, name: "Tylstrup"},
    {id: 9400, name: "Nørresundby"},
    {id: 9430, name: "Vadum"},
    {id: 9440, name: "Aabybro"},
    {id: 9460, name: "Brovst"},
    {id: 9480, name: "Løkken"},
    {id: 9490, name: "Pandrup"},
    {id: 9492, name: "Blokhus"},
    {id: 9493, name: "Saltum"},
    {id: 9500, name: "Hobro"},
    {id: 9510, name: "Arden"},
    {id: 9520, name: "Skørping"},
    {id: 9530, name: "Støvring"},
    {id: 9541, name: "Suldrup"},
    {id: 9550, name: "Mariager"},
    {id: 9560, name: "Hadsund"},
    {id: 9574, name: "Bælum"},
    {id: 9575, name: "Terndrup"},
    {id: 9600, name: "Aars"},
    {id: 9610, name: "Nørager"},
    {id: 9620, name: "Aalestrup"},
    {id: 9631, name: "Gedsted"},
    {id: 9632, name: "Møldrup"},
    {id: 9640, name: "Farsø"},
    {id: 9670, name: "Løgstør"},
    {id: 9681, name: "Ranum"},
    {id: 9690, name: "Fjerritslev"},
    {id: 9700, name: "Brønderslev"},
    {id: 9740, name: "Jerslev J"},
    {id: 9750, name: "Østervrå"},
    {id: 9760, name: "Vrå"},
    {id: 9800, name: "Hjørring"},
    {id: 9830, name: "Tårs"},
    {id: 9850, name: "Hirtshals"},
    {id: 9870, name: "Sindal"},
    {id: 9881, name: "Bindslev"},
    {id: 9900, name: "Frederikshavn"},
    {id: 9940, name: "Læsø"},
    {id: 9970, name: "Strandby"},
    {id: 9981, name: "Jerup"},
    {id: 9982, name: "Ålbæk"},
    {id: 9990, name: "Skagen"},
];