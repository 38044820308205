import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography
} from '@material-ui/core'
import { CloudDownload, Delete } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { FormattedMessage, useIntl } from 'react-intl'
import { graphQLApi } from '../../services/GraphQLApi'
import { useAuthDispatch } from '../../contexts/Auth'

const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    label: {
        margin: '8px 0'
    },
    input: {
        marginLeft: 10
    },
    dimensions: {
        width: '100%'
    },
    downloadAction: {
        marginRight: 4
    },
    deleteAction: {
        marginLeft: 4
    },
    saveBtn: {
        maxWidth: 100,
        margin: '40px 0'
    }
});

export default function ImageCropper (props) {
    const { title, onClose, open, selectedItem, ...other } = props;

    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useAuthDispatch();

    const [keepRatio, setKeepRatio] = useState(false);
    const [dimensionTitle, setDimensionTitle] = useState('');
    const [cropData, setCropData] = useState([]);
    const [toggleRefresh, setToggleRefresh] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(true);

    const cropperRef = useRef(null);
    const cropWidthRef = useRef(0);
    const cropHeightRef = useRef(0);
    const cropTopRef = useRef(0);
    const cropLeftRef = useRef(0);
    const cropTopInputRef = useRef(0);
    const cropLeftInputRef = useRef(0);
    const cropWidthInputRef = useRef(0);
    const cropHeightInputRef = useRef(0);
    const cropOutputWidthInputRef = useRef(0);
    const cropOutputHeightInputRef = useRef(0);
    const originalWidthRef = useRef(0);
    const originalHeightRef = useRef(0);
    const outputWidthRef = useRef(0);
    const outputHeightRef = useRef(0);

    const timeoutRef = useRef(null);

    useEffect(() => {
        if (!selectedItem || !selectedItem.id) return;
        setDimensionTitle('');
        const client = new graphQLApi(dispatch, props.history);
        client.query('query {' +
            'assetCrops(filter:{asset_id:'+selectedItem.id+'})\n' +
            '  {\n' +
            '    data{\n' +
            '      id\n' +
            '      asset{id file file_uri width height}\n' +
            '      title\n' +
            '      crop_top\n' +
            '      crop_left\n' +
            '      crop_width\n' +
            '      crop_height\n' +
            '      crop_keep_ratio\n' +
            '      output_width\n' +
            '      output_height\n' +
            '      output_ready\n' +
            '      output_uri\n' +
            '    }\n' +
            '  }\n' +
            'assets(filter:{id:'+selectedItem.id+'})\n' +
            '  {\n' +
            '    data{\n' +
            '      id\n' +
            '      file\n' +
            '      width\n' +
            '      height\n' +
            '    }\n' +
            '  }' +
            '}', {id: 0})
            .then(r => {
                if (!r) return setShowAlert({ show: true, type: 'error' });
                if (r.assets.data[0]) {
                    setLoading(false);
                    originalWidthRef.current = r.assets.data[0].width;
                    originalHeightRef.current = r.assets.data[0].height;
                    if (!(
                        cropOutputWidthInputRef.current || cropOutputWidthInputRef.current.children[0] || cropOutputWidthInputRef.current.children[0].children[0] ||
                        cropOutputHeightInputRef.current || cropOutputHeightInputRef.current.children[0] || cropOutputHeightInputRef.current.children[0].children[0]
                    )) return;
                    cropOutputWidthInputRef.current.children[0].children[0].value = Math.floor(r.assets.data[0].width / 2);
                    cropOutputHeightInputRef.current.children[0].children[0].value = Math.floor(r.assets.data[0].height / 2);
                    outputWidthRef.current = Math.floor(r.assets.data[0].width / 2);
                    outputHeightRef.current = Math.floor(r.assets.data[0].height / 2);
                }
                const allCrops = [];
                for (const data of r.assetCrops.data) {
                    const asset = data.asset;
                    const id = data.id;
                    const title = data.title;
                    const cropWidth = data.crop_width;
                    const cropHeight = data.crop_height;
                    const cropKeepRatio = data.crop_keep_ratio;
                    const cropLeft = data.crop_left;
                    const cropTop = data.crop_top;
                    const outputHeight = data.output_height;
                    const outputReady = data.output_ready;
                    const outputUri = data.output_uri;
                    const outputWidth = data.output_width;
                    allCrops.push({
                        asset: asset,
                        id: id,
                        title: title,
                        width: cropWidth,
                        height: cropHeight,
                        top: cropTop,
                        left: cropLeft,
                        keepRatio: cropKeepRatio,
                        outputWidth: outputWidth,
                        outputHeight:outputHeight,
                        outputUri: outputUri,
                        outputReady: outputReady
                    });
                }
                setCropData(allCrops);
            });
    },[selectedItem, toggleRefresh]);

    useEffect(() => {
        if (!cropperRef.current) return;
        if (!originalWidthRef.current || !originalHeightRef.current) return;
        let imageAspectRatio = reduceFraction(originalWidthRef.current, originalHeightRef.current);
        imageAspectRatio = imageAspectRatio[0] / imageAspectRatio[1];
        if (keepRatio) {
            cropperRef.current.cropper.options.aspectRatio = imageAspectRatio;
        }
        else {
            cropperRef.current.cropper.options.aspectRatio = NaN;
        }
    }, [keepRatio]);

    useEffect(() => {
        if (!loading) onCrop();
    }, [loading]);

    const onCrop = () => {
        if (!originalWidthRef.current || !originalHeightRef.current) return;
        const imageElement = cropperRef.current;
        const cropper = imageElement.cropper;

        if (!cropper || !cropper.cropBoxData) return;

        const widthMultiplier = cropper.imageData.width / originalWidthRef.current;
        const heightMultiplier = cropper.imageData.height / originalHeightRef.current;
        const transformedCropWidth = Math.round(cropper.cropBoxData.width / widthMultiplier);
        const transformedCropHeight = Math.round(cropper.cropBoxData.height / heightMultiplier);
        const transformedCropLeft = Math.round(cropper.cropBoxData.left / widthMultiplier);
        const transformedCropTop = Math.round(cropper.cropBoxData.top / heightMultiplier);
        cropWidthRef.current = transformedCropWidth;
        cropHeightRef.current = transformedCropHeight;
        cropTopRef.current = transformedCropTop;
        cropLeftRef.current = transformedCropLeft;

        if (
            !cropTopInputRef.current || !cropTopInputRef.current.children[0] || !cropTopInputRef.current.children[0].children[0] ||
            !cropLeftInputRef.current || !cropLeftInputRef.current.children[0] || !cropLeftInputRef.current.children[0].children[0] ||
            !cropWidthInputRef.current || !cropWidthInputRef.current.children[0] || !cropWidthInputRef.current.children[0].children[0] ||
            !cropHeightInputRef.current || !cropHeightInputRef.current.children[0] || !cropHeightInputRef.current.children[0].children[0]
        ) return;

        cropTopInputRef.current.children[0].children[0].value = transformedCropTop;
        cropLeftInputRef.current.children[0].children[0].value = transformedCropLeft;
        cropWidthInputRef.current.children[0].children[0].value = transformedCropWidth;
        cropHeightInputRef.current.children[0].children[0].value = transformedCropHeight;
    };

    const inputHandler = (event, target, value) => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => manuallyUpdateValues(event, target, value), 1000);
    };

    const manuallyUpdateValues = (event, target, value) => {
        if (!originalWidthRef.current || !originalHeightRef.current) return;
        if (!/^\d+$/.test(value)) {
            if (value.length > 0) event.target.value = event.target.value.slice(0, event.target.value.length - 1);
            return;
        }
        value = +value;
        const imageElement = cropperRef.current;
        const cropper = imageElement.cropper;

        const widthMultiplier = cropper.imageData.width / originalWidthRef.current;
        const heightMultiplier = cropper.imageData.height / originalHeightRef.current;

        if (target === 'top') {
            value = value * heightMultiplier;
            cropper.setCropBoxData({
                left: cropper.cropBoxData.left,
                top: value,
                width: cropper.cropBoxData.width,
                height: cropper.cropBoxData.height
            });
        }
        if (target === 'left') {
            value = value * widthMultiplier;
            cropper.setCropBoxData({
                left: value,
                top: cropper.cropBoxData.top,
                width: cropper.cropBoxData.width,
                height: cropper.cropBoxData.height
            });
        }
        if (target === 'width') {
            value = value * widthMultiplier;
            cropper.setCropBoxData({
                left: cropper.cropBoxData.left,
                top: cropper.cropBoxData.top,
                width: value,
                height: cropper.cropBoxData.height
            });
        }
        if (target === 'height') {
            value = value * heightMultiplier;
            cropper.setCropBoxData({
                left: cropper.cropBoxData.left,
                top: cropper.cropBoxData.top,
                width: cropper.cropBoxData.width,
                height: value
            });
        }
        if (target === 'output-width') {
            outputWidthRef.current = value;
        }
        if (target === 'output-height') {
            outputHeightRef.current = value;
        }
    };

    const saveHandler = () => {
        if (!originalWidthRef.current || !originalHeightRef.current) return;
        if (!outputWidthRef.current || !outputHeightRef.current) return;
        if (!cropData) return;
        if (!dimensionTitle || dimensionTitle === '') return setShowAlert({ show: true, type: 'title' });
        const newCropData = {
            cropWidth: cropWidthRef.current,
            cropHeight: cropHeightRef.current,
            cropTop: cropTopRef.current,
            cropLeft:cropLeftRef.current,
            title: dimensionTitle
        };
        const client = new graphQLApi(dispatch, props.history);
        client.mutate('($id:ID!,$title:String!,$top:Int!,$left:Int!,$width:Int!,$height:Int!,$keepRatio:Boolean!,$outputWidth:Int!,$outputHeight:Int!,$outputReady:Boolean!) {assetCropCreate(' +
            '               asset_id:$id,' +
            '               title:$title,' +
            '               crop_top:$top,' +
            '               crop_left:$left,' +
            '               crop_width:$width,' +
            '               crop_height:$height,' +
            '               crop_keep_ratio:$keepRatio,' +
            '               output_width:$outputWidth,' +
            '               output_height:$outputHeight,' +
            '               output_ready:$outputReady' +
            '             ) {id}}', {
            id: selectedItem.id,
            title: dimensionTitle,
            top: newCropData.cropTop,
            left: newCropData.cropLeft,
            width: newCropData.cropWidth,
            height: newCropData.cropHeight,
            keepRatio: keepRatio,
            outputWidth: outputWidthRef.current,
            outputHeight: outputHeightRef.current,
            outputReady: false
            })
            .then(r => {
                if (!r) return setShowAlert({ show: true, type: 'error' });
                setToggleRefresh(toggleRefresh + 1);
            });
    };

    const reduceFraction = (numerator, denominator) => {
        let gcd = function gcd(a,b){
            return b ? gcd(b, a%b) : a;
        };
        gcd = gcd(numerator, denominator);
        return [numerator/gcd, denominator/gcd];
    }

    const downloadCropHandler = (downloadLink) => {
        window.open(downloadLink, '_blank');
    };

    const deleteCropHandler = (id) => {
        const client = new graphQLApi(dispatch, props.history);
        client.mutate('($id:ID!) {assetCropDelete(id:$id)}', {id: id})
            .then(() => {
                const transformedCropData = cropData.filter(cropItem => cropItem.id !== id);
                setCropData(transformedCropData);
            });
    };

    const closeAlertHandler = () => {
        setShowAlert(false);
    };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle disableTypography id="confirmation-dialog-title"><Typography variant="h2">{title}</Typography></DialogTitle>
            <DialogContent dividers>
                <Cropper
                  src={selectedItem.file_thumb}
                  guides={false}
                  crop={onCrop}
                  ref={cropperRef}
                  zoomable={false}
                  minCropBoxWidth={1}
                  minCropBoxHeight={1}
                  viewMode={1}
                />
                <form noValidate autoComplete='off'>
                    <FormControl>
                    <Grid container>
                        <Grid item xs={6}>
                            <FormControlLabel
                              className={classes.label}
                              control={(
                                <TextField
                                  id="crop-top"
                                  className={classes.input}
                                  onChange={event => inputHandler(event, 'top', event.target.value)}
                                  ref={cropTopInputRef}
                                  label=""
                                />
                              )}
                              label='Top (px)'
                              labelPlacement='start'
                            />
                            <FormControlLabel
                              className={classes.label}
                              control={(
                                <TextField
                                  id="crop-left"
                                  className={classes.input}
                                  onChange={event => inputHandler(event, 'left', event.target.value)}
                                  ref={cropLeftInputRef}
                                  label=""
                                />
                              )}
                              label='Left (px)'
                              labelPlacement='start'
                            />
                            <FormControlLabel
                              className={classes.label}
                              control={(
                                <TextField
                                  id="crop-width"
                                  className={classes.input}
                                  onChange={event => inputHandler(event, 'width', event.target.value)}
                                  ref={cropWidthInputRef}
                                  label=""
                                />
                              )}
                              label='Crop Width (px)'
                              labelPlacement='start'
                            />
                            <FormControlLabel
                              className={classes.label}
                              control={(
                                <TextField
                                  id="crop-height"
                                  className={classes.input}
                                  onChange={event => inputHandler(event, 'height', event.target.value)}
                                  ref={cropHeightInputRef}
                                  label=""
                                />
                              )}
                              label='Crop Height (px)'
                              labelPlacement='start'
                            />
                            <FormControlLabel
                              value='keepRatio'
                              control={<Checkbox checked={keepRatio} onChange={event => setKeepRatio(event.target.checked)} color='primary' />}
                              label='Keep ratio'
                              labelPlacement='end'
                            />
                        </Grid>
                        <Grid item xs={6}><FormControlLabel
                          className={classes.label}
                          control={<TextField id="dimension-title" className={classes.input} label="" />}
                          label='Title'
                          labelPlacement='start'
                          value={dimensionTitle}
                          onChange={(event) => setDimensionTitle(event.target.value)}
                        />
                            <FormControlLabel
                              className={classes.label}
                              control={(
                                <TextField
                                  id="output-width"
                                  className={classes.input}
                                  onChange={event => inputHandler(event, 'output-width', event.target.value)}
                                  ref={cropOutputWidthInputRef}
                                  label=""
                                />
                              )}
                              label='Output width (px)'
                              labelPlacement='start'
                            />
                            <FormControlLabel
                              className={classes.label}
                              control={(
                                <TextField
                                  fullwidth
                                  id="output-height"
                                  className={classes.input}
                                  onChange={event => inputHandler(event, 'output-height', event.target.value)}
                                  ref={cropOutputHeightInputRef}
                                  label=""
                                />
                              )}
                              label='Output Height (px)'
                              labelPlacement='start'
                            />
                            <Button className={classes.saveBtn} onClick={saveHandler}>
                                <FormattedMessage id="common.button.save" />
                            </Button>
                        </Grid>
                    </Grid>
                    </FormControl>
                    <FormControl className={classes.dimensions}>
                        <Typography variant="h4">{intl.formatMessage({id: "enhanced_table.edit.dimensions", defaultMessage: "Saved dimensions"})}</Typography>
                        <List dense={true}>
                            {cropData.map((crop, index) => (
                                <ListItem key={index}>
                                    <ListItemText
                                        primary={crop.title + ' - ' + crop.width + ' x ' + crop.height}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            className={classes.downloadAction}
                                            onClick={() => downloadCropHandler(crop.outputUri)}
                                            edge='end'
                                            aria-label='download'
                                        >
                                            <CloudDownload />
                                        </IconButton>
                                        <IconButton
                                            className={classes.deleteAction}
                                            onClick={() => deleteCropHandler(crop.id)}
                                            edge='start'
                                            aria-label='delete'
                                        >
                                            <Delete />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus color="default">
                    <FormattedMessage id="common.button.cancel" />
                </Button>
                <Button onClick={onClose} color="primary">
                    <FormattedMessage id="common.button.ok" />
                </Button>
            </DialogActions>
            <Dialog
                open={showAlert && showAlert.show}
                onClose={closeAlertHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle disableTypography id="alert-confirmation-dialog-title">
                    <Typography variant="h2">
                        {showAlert && showAlert.type === 'title' && intl.formatMessage({id: "enhanced_table.edit.invalid_input_title", defaultMessage: "Invalid title"})}
                        {showAlert && showAlert.type === 'error' && intl.formatMessage({id: "enhanced_table.edit.error_title", defaultMessage: "Error"})}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {showAlert && showAlert.type === 'title' && intl.formatMessage({id: "enhanced_table.edit.invalid_title_message", defaultMessage: "Please enter a valid title."})}
                        {showAlert && showAlert.type === 'error' && intl.formatMessage({id: "enhanced_table.edit.error_message", defaultMessage: "An error has occured."})}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAlertHandler} color="primary" autoFocus>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </Dialog>
    );
}

ImageCropper.propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedItem: PropTypes.object.isRequired,
};
