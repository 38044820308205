import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Card, CardContent, CardHeader, Grid} from '@material-ui/core';
import GraphQLEditForm from 'components/GraphQL/GraphQLEditForm';
import {assignmentSubjectColumns, subjectColumns} from 'variables/subjects';
import {graphQLApi} from 'services/GraphQLApi';
import {useAuthDispatch} from 'contexts/Auth';

export default function MappingEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.id);

    const [systemFields, setSystemFields] = useState([]);
    const client = new graphQLApi(useAuthDispatch(), props.history);
    useEffect(() => {
        client.query('{' +
            'subjectFields(' +
            '   sorting:"name"' +
            '   direction:"asc"' +
            ')' +
            '{data {id name} }' +
            '}').then(r => {
            if (r && r.subjectFields) {
                let cols = assignmentSubjectColumns(intl, 0, '').filter(c => !['id', 'assignment.name'].includes(c.id));
                cols = [
                    ...cols,
                    ...subjectColumns(intl, cols.length, 'subjects.').filter(c => c.id !== 'subjects.id'),
                ];
                cols = [
                    ...cols,
                    ...r.subjectFields.data.map((c, i) => ({id: 'subject_field_data.'+c.id, name: c.name, sorting: i + cols.length}))
                ];
                setSystemFields(cols);
            }
        });
    }, []);

    const fields = [
        {
            field: "system_column",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "mappings.edit.label.system_column", defaultMessage: "System Column"}),
            input: "text",
            options: systemFields,
        },
        {
            field: "file_column",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "mappings.edit.label.file_column",
                defaultMessage: "File_column"
            }),
            input: "text",
        }
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        title={intl.formatMessage({id: "mappings.edit.heading", defaultMessage: "Edit"})}
                        titleTypographyProps={{color:"primary"}}
                    />
                    <CardContent>
                        <GraphQLEditForm
                            id={id}
                            query={"mappings"}
                            mutations={"mapping"}
                            fields={fields}
                            cols={2}
                            {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}