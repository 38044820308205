export default function placeholderButton(intl, options) {
    const delimiters = {first: '&#171;', last: '&#187;'};

    return {
        placeholders: options,
        name: intl.formatMessage({
            id: "jodit.buttons.placeholders",
            defaultMessage: "Placeholders"
        }),
        list: options ? options.map(option => option.name) : [],
        exec: (instance, currentNode, {control}) => {
            if (control?.name === intl.formatMessage({
                id: "jodit.buttons.placeholders",
                defaultMessage: "Placeholders"
            })) return;
            const newElement = document.createElement('span');
            newElement.innerHTML = `${delimiters.first}${control.name}${delimiters.last}`;
            instance.s.insertNode(newElement);
        },
        template: function (key, value) {
            return '<div>' + value + '</div>';
        }
    };
}
