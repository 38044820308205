import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'

import {Delete, HighlightOff} from '@material-ui/icons';

// date time
import moment from 'moment'
import 'moment/locale/da'
import {useIntl} from "react-intl";
import {ConfirmDialog} from '../../../components/Dialogs';
import {useState} from 'react';

const AllSubjectContacts = ({
  open,
  onClose,
  contacts,
  departments,
  selectedContact,
  setSelectedContact,
  updateSubjectContact
}) => {
  // init date format and locale for created contacts
  const intl = useIntl();
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [deleteContact, setDeleteContact] = useState(null);

  const handleDeletion = (confirmed) => {
    if (confirmed) {
      updateSubjectContact('delete', deleteContact)
    }
    setDeleteContact(null);
    setOpenConfirmDeleteDialog(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
    >
      <Card elevation={0}>
        <CardHeader
          title="Alle Kontakter"
          action={
            <IconButton color="primary" onClick={onClose}>
              <HighlightOff />
            </IconButton>
          }
        />
        <CardContent>
          <TableContainer style={{maxHeight:"calc(100vh - 400px)"}}>
            <Table stickyHeader size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell>Navn</TableCell>
                  <TableCell>Titel</TableCell>
                  <TableCell>Ansvarsområde</TableCell>
                  <TableCell>Direkte</TableCell>
                  <TableCell>Mobil</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Oprettet</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contacts.map((row, idx) => (
                  <TableRow
                    key={'all-contacts-'+idx}
                    hover
                    onClick={() => setSelectedContact(selectedContact && selectedContact.id === row.id ? null : row.id)}
                    style={{ cursor: 'pointer' }}
                    selected={selectedContact && selectedContact.id === row.id}
                  >
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.department_id ? departments.find(d => d.id === row.department_id)?.name : ''}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.mobile}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{moment(row.created_at).format(intl.formatMessage({id:"common.datetime.format"}))}</TableCell>
                    <TableCell>
                      <IconButton size="small" onClick={() => {
                        setDeleteContact(row)
                        setOpenConfirmDeleteDialog(true);
                      }}><Delete/></IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <ConfirmDialog onClose={handleDeletion}
                     open={openConfirmDeleteDialog} title={intl.formatMessage({
                        id: 'dialer.all_contacts.list.confirm_deletion.title',
                        defaultMessage: 'Confirm deletion',
                      })}
                     message={intl.formatMessage({
                       id: 'dialer.all_contacts.list.confirm_deletion.message',
                       defaultMessage: 'Please confirm that you want to delete {name}?',
                     }, {name: deleteContact?.name})}/>
    </Dialog>
  );
};

export default AllSubjectContacts;
