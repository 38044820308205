import {Paper} from "@material-ui/core";
import {Domain, OpenInBrowser} from "@material-ui/icons";
import EnhancedTable from "components/DataTable/EnhancedTable";
import React from "react";
import {useIntl} from "react-intl";

export default function SiteList(props) {
  const intl = useIntl();
  const columns = [
    {
      title: intl.formatMessage({
        id: "sites.list.column.reference",
        defaultMessage: "Reference",
      }),
      field: "reference",
    },
    {
      title: intl.formatMessage({
        id: "sites.list.column.name",
        defaultMessage: "Name",
      }),
      field: "name",
    },
    {
      title: intl.formatMessage({
        id: "sites.list.column.is_active",
        defaultMessage: "Is Active",
      }),
      field: "is_active",
      type: "boolean",
      initial: false,
    },
    {
      title: intl.formatMessage({
        id: "sites.list.column.schema",
        defaultMessage: "Schema",
      }),
      field: "schema",
    },
    {
      title: intl.formatMessage({
        id: "sites.list.column.package",
        defaultMessage: "Package",
      }),
      field: "package",
    },
  ];

  return (
    <Paper>
      <EnhancedTable
        {...props}
        title={intl.formatMessage({
          id: "sites.list.table.heading",
          defaultMessage: "Sites list",
        })}
        columns={columns}
        query="sites"
        mutations="site"
        fields="id reference name is_active schema package domains{name is_active}"
        icon={<Domain/>}
        actions={[
          {
            icon: OpenInBrowser,
            tooltip: "Goto",
            onClick: (row) => {
              if (row.domains) {
                let dom = row.domains.find(d => d.is_active);
                if (dom) {
                  window.open('https://' + dom.name, '_blank');
                }
              } else {
                alert('No domain was found');
              }
            }
          }
        ]}
        urlState
      />
    </Paper>
  );
}
