import React, {useRef, useState} from 'react';
import {CloudDownload, Delete, ListAlt, NotificationsActive, Remove} from '@material-ui/icons';
import {useIntl} from 'react-intl';
import {Button, CardActions, CardContent, Dialog, DialogTitle, Paper, Snackbar, Typography} from '@material-ui/core';
import {authHeader, getApiDomain, useAuthDispatch} from 'contexts/Auth';
import moment from 'moment';
import EnhancedTable from 'components/DataTable/EnhancedTable';
import {Box} from "@mui/system";
import axios from "axios";
import {graphQLApi} from "../../../services/GraphQLApi";
import {ConfirmDialog} from "../../../components/Dialogs";

moment.locale(window.language);

export default function SipList(props) {
    const intl = useIntl();
    const tableRef = useRef();
    const [showImportDialog, setShowImportDialog] = useState(false);
    const [file, setFile] = useState('');
    const [importing, setImporting] = useState(false);
    const [response, setResponse] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteRows, setDeleteRows] = useState([]);

    const client = new graphQLApi(useAuthDispatch(), props.history);

    const columns = [
        {
            title: intl.formatMessage({
                id: "sip_credentials.list.column.sip_number",
                defaultMessage: "sip number",
            }),
            field: "number",
        },
        {
            title: intl.formatMessage({
                id: "sip_credentials.list.column.login",
                defaultMessage: "login",
            }),
            field: "login",
        },
        {
            title: intl.formatMessage({
                id: "sip_credentials.list.column.users_count",
                defaultMessage: "Total users",
            }),
            field: "users_count",
            sortable: false
        },
        {
            title: intl.formatMessage({
                id: "sip_credentials.list.column.users",
                defaultMessage: "users",
            }),
            field: "users",
            sortable: false,
            render: row => { return row.users.map((r) => r.name).join(', ');},
        },
    ]
    const actions = [
        {
            icon: CloudDownload,
            tooltip: intl.formatMessage({id: "sip_credentials.list.actions.import", defaultMessage: "Import"}),
            isFreeAction: true,
            onClick: () => {
                setShowImportDialog(true)
            }
        },
        {
            icon: Delete,
            tooltip: 'Delete',
            onSelected: true,
            onClick: (rows) => {
                setDeleteRows(rows)
                setConfirmDelete(true)
            },
        },
        {
            icon: Remove,
            tooltip: intl.formatMessage({id: "sip_credentials.list.actions.unassign_users", defaultMessage: "Remove users"}),
            onClick: (row) => {
                client.mutate('($id:ID!) {userSipCredentialsRemove(id:$id)}',
                    {id: row.id}).then(r => {
                    r ? tableRef.current.update() : setShowAlert(true)
                })
            }
        }
    ]
    const handleOnChangeFile = (e) => {
        setFile(e.target.files[0])
    };

    const handleDeletion =(confirmed) => {
        if(confirmed) {
            client.mutate('($ids:[ID]!) {sipCredentialDelete(ids:$ids)}',
                {ids: deleteRows.map(r => r.id)}).then(r => {
                    if (r) tableRef.current.update()
                }
            )
            setConfirmDelete(false);
        }else {
            setConfirmDelete(false);
            setDeleteRows([])
            tableRef.current.update()
        }
    }
    const handleImportFile = (e) => {
        e.preventDefault();
        if (file) {
            setFile('')
            setImporting(true)
            const formData = new FormData();
            formData.append('file', file);
            const headers = authHeader();
            headers['Content-Type'] = 'multipart/form-data'
            axios.post(getApiDomain() + '/sip/import', formData, {headers: headers},
            ).then(result => {
                setResponse(result.data)
                setImporting(false)
            })
        }
    }

    return (<Paper>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                color="warning"
                icon={NotificationsActive}
                message={intl.formatMessage({
                    id: "sip_credentials.list.alert.unassign-user",
                    defaultMessage: "Unassign users failed!",
                })}
                open={showAlert}
                onClose={() => setShowAlert(false)}
                autoHideDuration={6000}
            />
            <ConfirmDialog onClose={handleDeletion} open={confirmDelete}
                           title={intl.formatMessage({
                               id: 'sip_credentials.list.confirm_deletion.title',
                               defaultMessage: 'Confirm deletion',
                           })}
                           message={intl.formatMessage({
                               id: 'sip_credentials.list.confirm_deletion.message',
                               defaultMessage: 'Please confirm that you want to delete {count} sip credentials?',
                           }, {count:deleteRows.length})}
            />
            <Dialog open={showImportDialog}>
                <DialogTitle disableTypography style={{'display': 'flex',
                    'justify-content': 'space-between'}}>
                    <Typography variant="h2" color="primary">{intl.formatMessage({
                        id: 'importFile.heading',
                        defaultMessage: "Import File"
                    })}</Typography>
                </DialogTitle>
                <CardContent>
                    {response ?
                        response :
                        importing ?
                            intl.formatMessage({
                                id: "jobs_checker.import.progress_message",
                                defaultMessage: "Importing ..."
                            })
                            :
                            <input type={"file"} accept={".xlsx, .xls"} size="small" onChange={handleOnChangeFile}/>
                    }
                </CardContent>
                <CardActions style={{display: "flex", justifyContent: "flex-end"}}>
                    <Box>
                        {response ?
                            <Button onClick={() => {
                                setShowImportDialog(false)
                                setResponse(null)
                                tableRef.current.update()
                            }} variant="outlined" color="default">{intl.formatMessage({
                                id: "common.button.close",
                                defaultMessage: "close"
                            })}</Button>
                            :
                            !importing &&
                            <>
                                <Button onClick={() => {
                                    setShowImportDialog(false)
                                }} variant="outlined" color="default">{intl.formatMessage({
                                    id: "common.button.cancel",
                                    defaultMessage: "Cancel"
                                })}</Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button onClick={handleImportFile} variant="outlined" color="primary"
                                        disabled={!file}>{intl.formatMessage({
                                    id: "importFile.file.upload",
                                    defaultMessage: "Upload File"
                                })}</Button>
                            </>

                        }
                    </Box>
                </CardActions>
            </Dialog>
            <EnhancedTable
                {...props}
                title={
                    intl.formatMessage({
                        id: "sip_credentials.list.heading",
                        defaultMessage: "Sip list",
                    })
                }
                columns={columns}
                query="sipCredentials"
                fields="id login number users{name}"
                counts={["users"]}
                icon={<ListAlt/>}
                actions={actions}
                direction="asc"
                ref={tableRef}
            />

        </Paper>
    );
}
