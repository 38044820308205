import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
// @material-ui/icons components
import Search from '@material-ui/icons/Search';

import componentStyles from 'assets/theme/layouts/admin.js';
import NavbarDropdown from 'components/Dropdowns/NavbarDropdown.js';
import AdminFooter from 'components/Footers/AdminFooter.js';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import {authUser} from 'contexts/Auth';
import React from 'react';
import {useIntl} from 'react-intl';
import {Route, Switch} from 'react-router-dom';

import getRoutesDef from 'routes/admin';
import {config} from '../config';

const useStyles = makeStyles(componentStyles);

const Admin = (props) => {
  const {history, match} = props;
  const intl = useIntl();
  const classes = useStyles();
  const routes = props.routes ? props.routes : getRoutesDef(intl);

  const getRoutes = (routes, prefix = "") => {
    return routes.map((prop, key) => {
      if (prop.roles && !authUser().hasRole(prop.roles)) return null;
      if (prop.allow_priority && !authUser().isAllowed(prop.allow_priority)) return null;
      let rs = [];
      if (prop.views) {
        rs = getRoutes(prop.views, prefix + prop.path);
      }
      rs.push(<Route
          path={!prop.shared ? prefix + prop.path : prop.path}
          component={prop.component}
          key={prefix + key}
          exact={prop.exact}
      />);
      return rs;
    });
  };

  return (
    <>
      <>
        <Sidebar
          {...props}
          routes={routes}
          dropdown={<NavbarDropdown />}
          input={
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-search-responsive">
                Search
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-search-responsive"
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <Box
                      component={Search}
                      width="1.25rem!important"
                      height="1.25rem!important"
                    />
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          }
        />
        <Box position="relative" className={classes.mainContent}>
          <AdminNavbar
            routes={routes}
            history={history}
            match={match}
            logo={{
              innerLink: "/",
              imgSrc: (authUser().site && authUser().site.logo_uri)
                ? authUser().site.logo_uri
                : config.brandLogo.default,
              imgAlt: intl.formatMessage({id:"navbar.brand_text"}),
            }}
          />
          <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
            id='main-content'
          >
            <Container classes={{ root: classes.mainRoot }}>
              <Switch>
                {getRoutes(routes)}
              </Switch>
            </Container>
            <AdminFooter />
          </Container>
        </Box>
      </>
    </>
  );
};

export default Admin;
