const componentStyles = (theme) => ({
  header: {
    background:
      "linear-gradient(87deg," + theme.palette.primary.main + ", " + theme.palette.secondary.main + ")",
    paddingTop: "5rem",
    paddingBottom: "2.5rem",
    [theme.breakpoints.up("md")]: {
      paddingTop: "8rem",
    },
  },
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "39px",
      paddingRight: "39px",
    },
  },
});

export default componentStyles;
