export const localeTexts = (intl) => ({
  // Root
  noRowsLabel: intl.formatMessage({id:"common.datagrid.noRowsLabel", defaultMessage:'No rows'}),
  noResultsOverlayLabel: intl.formatMessage({id:"common.datagrid.noResultsOverlayLabel", defaultMessage:'No results found.'}),
  errorOverlayDefaultLabel: intl.formatMessage({id:"common.datagrid.errorOverlayDefaultLabel", defaultMessage:'An error occurred.'}),

  // Density selector toolbar button text
  toolbarDensity: intl.formatMessage({id:"common.datagrid.toolbarDensity", defaultMessage:'Density'}),
  toolbarDensityLabel: intl.formatMessage({id:"common.datagrid.toolbarDensityLabel", defaultMessage:'Density'}),
  toolbarDensityCompact: intl.formatMessage({id:"common.datagrid.toolbarDensityCompact", defaultMessage:'Compact'}),
  toolbarDensityStandard: intl.formatMessage({id:"common.datagrid.toolbarDensityStandard", defaultMessage:'Standard'}),
  toolbarDensityComfortable: intl.formatMessage({id:"common.datagrid.toolbarDensityComfortable", defaultMessage:'Comfortable'}),

  // Columns selector toolbar button text
  toolbarColumns: intl.formatMessage({id:"common.datagrid.toolbarColumns", defaultMessage:'Columns'}),
  toolbarColumnsLabel: intl.formatMessage({id:"common.datagrid.toolbarColumnsLabel", defaultMessage:'Select columns'}),

  // Filters toolbar button text
  toolbarFilters: intl.formatMessage({id:"common.datagrid.toolbarFilters", defaultMessage:'Filters'}),
  toolbarFiltersLabel: intl.formatMessage({id:"common.datagrid.toolbarFiltersLabel", defaultMessage:'Show filters'}),
  toolbarFiltersTooltipHide: intl.formatMessage({id:"common.datagrid.toolbarFiltersTooltipHide", defaultMessage:'Hide filters'}),
  toolbarFiltersTooltipShow: intl.formatMessage({id:"common.datagrid.toolbarFiltersTooltipShow", defaultMessage:'Show filters'}),
  toolbarFiltersTooltipActive: (count) =>
    count !== 1
      ? intl.formatMessage({id:"common.datagrid.toolbarFiltersTooltipActive.plural", defaultMessage:'{count} active filters'}, {count: count.toLocaleString()})
      : intl.formatMessage({id:"common.datagrid.toolbarFiltersTooltipActive.singular", defaultMessage:'{count} active filter'}, {count: count.toLocaleString()}),

  // Export selector toolbar button text
  toolbarExport: intl.formatMessage({id:"common.datagrid.toolbarExport", defaultMessage:'Export'}),
  toolbarExportLabel: intl.formatMessage({id:"common.datagrid.toolbarExportLabel", defaultMessage:'Export'}),
  toolbarExportCSV: intl.formatMessage({id:"common.datagrid.toolbarExportCSV", defaultMessage:'Download as CSV'}),
  toolbarExportPrint: intl.formatMessage({id:"common.datagrid.toolbarExportPrint", defaultMessage:'Print'}),
  toolbarExportExcel: intl.formatMessage({id:"common.datagrid.toolbarExportExcel", defaultMessage:'Download as Excel'}),

  // Columns panel text
  columnsPanelTextFieldLabel: intl.formatMessage({id:"common.datagrid.columnsPanelTextFieldLabel", defaultMessage:'Find column'}),
  columnsPanelTextFieldPlaceholder: intl.formatMessage({id:"common.datagrid.columnsPanelTextFieldPlaceholder", defaultMessage:'Column title'}),
  columnsPanelDragIconLabel: intl.formatMessage({id:"common.datagrid.columnsPanelDragIconLabel", defaultMessage:'Reorder column'}),
  columnsPanelShowAllButton: intl.formatMessage({id:"common.datagrid.columnsPanelShowAllButton", defaultMessage:'Show all'}),
  columnsPanelHideAllButton: intl.formatMessage({id:"common.datagrid.columnsPanelHideAllButton", defaultMessage:'Hide all'}),

  // Filter panel text
  filterPanelAddFilter: intl.formatMessage({id:"common.datagrid.filterPanelAddFilter", defaultMessage:'Add filter'}),
  filterPanelDeleteIconLabel: intl.formatMessage({id:"common.datagrid.filterPanelDeleteIconLabel", defaultMessage:'Delete'}),
  filterPanelLinkOperator: intl.formatMessage({id:"common.datagrid.filterPanelLinkOperator", defaultMessage:'Logic operator'}),
  filterPanelOperators: intl.formatMessage({id:"common.datagrid.filterPanelOperators", defaultMessage:'Operator'}), // TODO v6: rename to filterPanelOperator
  filterPanelOperatorAnd: intl.formatMessage({id:"common.datagrid.filterPanelOperatorAnd", defaultMessage:'And'}),
  filterPanelOperatorOr: intl.formatMessage({id:"common.datagrid.filterPanelOperatorOr", defaultMessage:'Or'}),
  filterPanelColumns: intl.formatMessage({id:"common.datagrid.filterPanelColumns", defaultMessage:'Columns'}),
  filterPanelInputLabel: intl.formatMessage({id:"common.datagrid.filterPanelInputLabel", defaultMessage:'Value'}),
  filterPanelInputPlaceholder: intl.formatMessage({id:"common.datagrid.filterPanelInputPlaceholder", defaultMessage:'Filter value'}),

  // Filter operators text
  filterOperatorContains: intl.formatMessage({id:"common.datagrid.filterOperatorContains", defaultMessage:'contains'}),
  filterOperatorEquals: intl.formatMessage({id:"common.datagrid.filterOperatorEquals", defaultMessage:'equals'}),
  filterOperatorStartsWith: intl.formatMessage({id:"common.datagrid.filterOperatorStartsWith", defaultMessage:'starts with'}),
  filterOperatorEndsWith: intl.formatMessage({id:"common.datagrid.filterOperatorEndsWith", defaultMessage:'ends with'}),
  filterOperatorIs: intl.formatMessage({id:"common.datagrid.filterOperatorIs", defaultMessage:'is'}),
  filterOperatorNot: intl.formatMessage({id:"common.datagrid.filterOperatorNot", defaultMessage:'is not'}),
  filterOperatorAfter: intl.formatMessage({id:"common.datagrid.filterOperatorAfter", defaultMessage:'is after'}),
  filterOperatorOnOrAfter: intl.formatMessage({id:"common.datagrid.filterOperatorOnOrAfter", defaultMessage:'is on or after'}),
  filterOperatorBefore: intl.formatMessage({id:"common.datagrid.filterOperatorBefore", defaultMessage:'is before'}),
  filterOperatorOnOrBefore: intl.formatMessage({id:"common.datagrid.filterOperatorOnOrBefore", defaultMessage:'is on or before'}),
  filterOperatorIsEmpty: intl.formatMessage({id:"common.datagrid.filterOperatorIsEmpty", defaultMessage:'is empty'}),
  filterOperatorIsNotEmpty: intl.formatMessage({id:"common.datagrid.filterOperatorIsNotEmpty", defaultMessage:'is not empty'}),
  filterOperatorIsAnyOf: intl.formatMessage({id:"common.datagrid.filterOperatorIsAnyOf", defaultMessage:'is any of'}),

  // Filter values text
  filterValueAny: intl.formatMessage({id:"common.datagrid.filterValueAny", defaultMessage:'any'}),
  filterValueTrue: intl.formatMessage({id:"common.datagrid.filterValueTrue", defaultMessage:'true'}),
  filterValueFalse: intl.formatMessage({id:"common.datagrid.filterValueFalse", defaultMessage:'false'}),

  // Column menu text
  columnMenuLabel: intl.formatMessage({id:"common.datagrid.columnMenuLabel", defaultMessage:'Menu'}),
  columnMenuShowColumns: intl.formatMessage({id:"common.datagrid.columnMenuShowColumns", defaultMessage:'Show columns'}),
  columnMenuFilter: intl.formatMessage({id:"common.datagrid.columnMenuFilter", defaultMessage:'Filter'}),
  columnMenuHideColumn: intl.formatMessage({id:"common.datagrid.columnMenuHideColumn", defaultMessage:'Hide'}),
  columnMenuUnsort: intl.formatMessage({id:"common.datagrid.columnMenuUnsort", defaultMessage:'Unsort'}),
  columnMenuSortAsc: intl.formatMessage({id:"common.datagrid.columnMenuSortAsc", defaultMessage:'Sort by ASC'}),
  columnMenuSortDesc: intl.formatMessage({id:"common.datagrid.columnMenuSortDesc", defaultMessage:'Sort by DESC'}),

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1
      ? intl.formatMessage({id:"common.datagrid.columnHeaderFiltersTooltipActive.plural", defaultMessage:'{count} active filters'}, {count: count.toLocaleString()})
      : intl.formatMessage({id:"common.datagrid.columnHeaderFiltersTooltipActive.singular", defaultMessage:'{count} active filter'}, {count: count.toLocaleString()}),
  columnHeaderFiltersLabel: intl.formatMessage({id:"common.datagrid.columnHeaderFiltersLabel", defaultMessage:'Show filters'}),
  columnHeaderSortIconLabel: intl.formatMessage({id:"common.datagrid.columnHeaderSortIconLabel", defaultMessage:'Sort'}),

  // Rows selected footer text
  footerRowSelected: (count) =>
    count !== 1
      ? intl.formatMessage({id:"common.datagrid.footerTotalRows.plural", defaultMessage:'{count} rows selected'}, {count: count.toLocaleString()})
      : intl.formatMessage({id:"common.datagrid.footerTotalRows.singular", defaultMessage:'{count} row selected'}, {count: count.toLocaleString()}),

  // Total row amount footer text
  footerTotalRows: intl.formatMessage({id:"common.datagrid.footerTotalRows", defaultMessage:'Total Rows:'}),

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    intl.formatMessage({id:"common.datagrid.checkboxSelectionHeaderName", defaultMessage:'{visibleCount} of {totalCount}'},
      {visibleCount: visibleCount.toLocaleString(), totalCount: totalCount.toLocaleString()}),

  // Checkbox selection text
  checkboxSelectionHeaderName: intl.formatMessage({id:"common.datagrid.checkboxSelectionHeaderName", defaultMessage:'Checkbox selection'}),
  checkboxSelectionSelectAllRows: intl.formatMessage({id:"common.datagrid.checkboxSelectionSelectAllRows", defaultMessage:'Select all rows'}),
  checkboxSelectionUnselectAllRows: intl.formatMessage({id:"common.datagrid.checkboxSelectionUnselectAllRows", defaultMessage:'Unselect all rows'}),
  checkboxSelectionSelectRow: intl.formatMessage({id:"common.datagrid.checkboxSelectionSelectRow", defaultMessage:'Select row'}),
  checkboxSelectionUnselectRow: intl.formatMessage({id:"common.datagrid.checkboxSelectionUnselectRow", defaultMessage:'Unselect row'}),

  // Boolean cell text
  booleanCellTrueLabel: intl.formatMessage({id:"common.datagrid.booleanCellTrueLabel", defaultMessage:'yes'}),
  booleanCellFalseLabel: intl.formatMessage({id:"common.datagrid.booleanCellFalseLabel", defaultMessage:'no'}),

  // Actions cell more text
  actionsCellMore: intl.formatMessage({id:"common.datagrid.actionsCellMore", defaultMessage:'more'}),

  // Column pinning text
  pinToLeft: intl.formatMessage({id:"common.datagrid.pinToLeft", defaultMessage:'Pin to left'}),
  pinToRight: intl.formatMessage({id:"common.datagrid.pinToRight", defaultMessage:'Pin to right'}),
  unpin: intl.formatMessage({id:"common.datagrid.unpin", defaultMessage:'Unpin'}),

  // Tree Data
  treeDataGroupingHeaderName: intl.formatMessage({id:"common.datagrid.treeDataGroupingHeaderName", defaultMessage:'Group'}),
  treeDataExpand: intl.formatMessage({id:"common.datagrid.treeDataExpand", defaultMessage:'see children'}),
  treeDataCollapse: intl.formatMessage({id:"common.datagrid.treeDataCollapse", defaultMessage:'hide children'}),

  // Grouping columns
  groupingColumnHeaderName: intl.formatMessage({id:"common.datagrid.groupingColumnHeaderName", defaultMessage:'Group'}),
  groupColumn: (name) => intl.formatMessage({id:"common.datagrid.groupColumn", defaultMessage:'Group by {name}'},{name:name}),
  unGroupColumn: (name) => intl.formatMessage({id:"common.datagrid.unGroupColumn", defaultMessage:'Stop grouping by {name}'},{name:name}),

  // Master/detail
  expandDetailPanel: intl.formatMessage({id:"common.datagrid.expandDetailPanel", defaultMessage:'Expand'}),
  collapseDetailPanel: intl.formatMessage({id:"common.datagrid.collapseDetailPanel", defaultMessage:'Collapse'}),

  // Used core components translation keys
  MuiTablePagination: {},

  // Row reordering text
  rowReorderingHeaderName: intl.formatMessage({id:"common.datagrid.rowReorderingHeaderName", defaultMessage:'Row reordering'}),
});