import React from "react";
import {Paper} from "@material-ui/core";
import {Language} from "@material-ui/icons";
import {useIntl} from "react-intl";
import EnhancedTable from "components/DataTable/EnhancedTable";

export default function LanguageList(props) {
  const intl = useIntl();
  const columns = [
    {title: intl.formatMessage({id: 'languages.list.column.name', defaultMessage: 'Name'}), field: 'name'},
    {title: intl.formatMessage({id: 'languages.list.column.country_code', defaultMessage: 'Country Code'}), field: 'country_code'},
    {title: intl.formatMessage({id: 'languages.list.column.locale', defaultMessage: 'Locale'}), field: 'locale'}
  ];

  return (
    <Paper>
      <EnhancedTable
        title={intl.formatMessage({id: 'languages.list.table.heading', defaultMessage: "Languages list"})}
        columns={columns}
        query="languages"
        mutations="language"
        fields='id name country_code locale'
        sorting="name"
        direction="desc"
        icon={<Language/>}
        urlState
        {...props}
      />
    </Paper>
  );
}