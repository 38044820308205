import React, {useEffect} from 'react';
import moment from 'moment';
import 'moment/locale/da.js';
import 'moment/locale/en-gb.js';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';

export const LocaleContext = React.createContext();

export const LocaleContextProvider = (props) => {
  const getLanguageFromBrowser = () => {
    let lang = navigator.language.split(/[-_]/)[0];
    if (lang !== "da") {
      lang = 'en';
    }
    return lang;
  }
  const [locale, setLocale] = React.useState(getLanguageFromBrowser());
  useEffect(() => {
    document.documentElement.lang = locale;
    window.language = locale;
    moment.locale(locale);
  }, [locale]);
  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={locale}
    >
      <LocaleContext.Provider value={[locale, setLocale]}>
        {props.children}
      </LocaleContext.Provider>
    </MuiPickersUtilsProvider>
  );
};
