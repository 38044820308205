import React from 'react';
import {getFontColorFromColor} from 'variables/general';
import {Chip} from '@material-ui/core';

export default function Pill({ color, label, onClick }) {
  let l = null;
  if (label === undefined) {
    l = color;
  } else {
    l = label;
  }
  let style = {
    cursor: onClick === undefined ? "inherit" : "cursor",
    transition: "inherit",
  };
  if (/^#[a-f0-9]{3,6}/i.test(color)) {
    style.backgroundColor = color;
    style.color = getFontColorFromColor(color);
  } else {
    style.background = "inherit";
  }

  if (l !== null) {
    return <Chip size="small" style={style} label={l} onClick={onClick} />;
  } else {
    return "";
  }
}
