import React, {useState} from 'react';
import {AppBar, Card, Grid, Tab, Tabs, TextField} from '@material-ui/core/';
import TabPanel from 'views/Dialer/Components/TabPanel';
import {Badge, Checkbox, FormControlLabel, FormGroup, InputLabel} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import themeColors from '../../../assets/theme/colors';

const useStyles = makeStyles({
  root: {
    backgroundColor: themeColors.secondary.badgeBg,
    color: themeColors.secondary.text,
    fontSize: '0.8rem',
    fontWeight: '700',
    transform: 'unset',
    top: 3,
    right: -55,
  },
  badge: {
    border: 'none',
  },
});

export default function Comments({
                                   assignmentSubject,
                                   updateAssignmentSubject,
                                   questions = [],
                                   setAnswer = () => {
                                   },
                                   prependNewComments = false
                                 }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAnswer = (question) => {
    let i = assignmentSubject.answers.findIndex(a => a.assignment_question_id === question.id);
    if (i < 0) return '';
    return assignmentSubject.answers[i].text;
  };

  return (
      <Card elevation={4}
            style={{
              width: '100%',
              height: '100%',
            }}>
        <AppBar
            position="static"
            style={{
              borderTopLeftRadius: '5px',
              borderTopRightRadius: '5px',
            }}
        >
          <Tabs value={value} onChange={handleChange} variant="fullWidth">
            <Tab label="Internt"/>
            <Tab label={<><Badge classes={classes} badgeContent={questions.length} color="secondary" overlap="rectangular"/>Spørgsmål</>}/>
            <Tab label="Eksternt"/>
          </Tabs>
        </AppBar>
        <TabPanel p={1} value={value} index={0}>
          <Grid container spacing={2} style={{flexFlow: 'column nowrap', height: '100%'}}>
            {prependNewComments && <Grid item style={{flexGrow: 0}}>
              <TextField
                  label={'Intern kommentar'}
                  value={assignmentSubject.new_comment ? assignmentSubject.new_comment : ''}
                  onChange={e => updateAssignmentSubject('new_comment', e.target.value)}
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={4}
                  style={{height: '100%'}}
                  InputProps={{
                    style: {
                      height: '100%',
                    },
                  }}
                  inputProps={{
                    style: {
                      verticalAlign: 'top',
                      height: '100%',
                      overflow: 'auto',
                    },
                  }}
              />
            </Grid>}
            <Grid item style={{flexGrow: 1, marginTop: 5}}>
              <TextField
                  label={prependNewComments ? 'Tidligere kommentar' : 'Intern kommentar'}
                  value={assignmentSubject.comment ? assignmentSubject.comment : ''}
                  onChange={e => updateAssignmentSubject('comment', e.target.value)}
                  variant="outlined"
                  fullWidth
                  multiline
                  style={{height: '100%'}}
                  InputProps={{
                    style: {
                      height: '100%',
                    },
                  }}
                  inputProps={{
                    style: {
                      verticalAlign: 'top',
                      height: '100%',
                      overflow: 'auto',
                      color: '#3b3b3b'
                    },
                  }}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel p={1} value={value} index={1}
                  style={{maxHeight: 'calc(100vh - 504px)', overflow: 'auto'}}>
          <Grid container spacing={2}>{questions.map((q, k) =>
              <Grid item key={'questions-' + k} xs={12}>
                <InputLabel style={{marginBottom: '8px'}}>{(k + 1) + '. ' + q.text}</InputLabel>
                {!q.is_multiple_choice ?
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={getAnswer(q)}
                        onChange={e => setAnswer(q.id, e.target.value)}
                    />
                    :
                    <Grid container item xs={12}>
                      {q.answers && q.answers.map((a, k) =>
                          <FormGroup key={'answers-' + k}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={getAnswer(q).split('|||').includes(a.text)}
                                    name={'role-'}
                                    color={'primary'}
                                    value={a.text}
                                    onChange={(e) => {
                                      let answers = getAnswer(q).split('|||');
                                      console.log(answers);
                                      if (e.target.checked) {
                                        answers.push(a.text);
                                      }
                                      else {
                                        answers = answers.filter(v => v !== a.text);
                                      }
                                      setAnswer(q.id, answers.length ? answers.join('|||') : '');
                                    }}
                                />}
                                label={a.text}
                            />
                          </FormGroup>,
                      )}
                    </Grid>
                }
              </Grid>)}
          </Grid>
        </TabPanel>
        <TabPanel p={1} value={value} index={2}>
          <Grid container spacing={2} style={{flexFlow: 'column nowrap', height: '100%'}}>
            <Grid item style={{flexGrow: 1}}>
              <TextField
                  label={'Ekstern kommentar'}
                  value={assignmentSubject.ext_comment ? assignmentSubject.ext_comment : ''}
                  onChange={e => updateAssignmentSubject('ext_comment', e.target.value)}
                  disabled={!assignmentSubject.id}
                  variant="outlined"
                  fullWidth
                  multiline
                  style={{height: '100%'}}
                  InputProps={{
                    style: {
                      height: '100%',
                    },
                  }}
                  inputProps={{
                    style: {
                      verticalAlign: 'top',
                      height: '100%',
                      overflow: 'auto'
                    },
                  }}
              />
            </Grid>
          </Grid>
        </TabPanel>
      </Card>
  );
}
