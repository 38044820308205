import React from "react";
import {useIntl} from "react-intl";
import {Grid, Card, CardHeader, CardContent} from "@material-ui/core";
import {Flare} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function OutcomeEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.id);

    const fields = [
        {
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "outcomes.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            field: "is_date_required",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "outcomes.edit.label.is_date_required", defaultMessage: "Require date"}),
            input: "switch"
        },
        {
            field: "is_closed",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "outcomes.edit.label.is_closed", defaultMessage: "Closed"}),
            input: "switch"
        },
        {
            field: "is_target",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "outcomes.edit.label.is_target", defaultMessage: "Target"}),
            input: "switch"
        }
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        avatar={<Flare color="primary"/>}
                        title={intl.formatMessage({id: "outcomes.edit.heading", defaultMessage:"Outcomes edit"})}
                        titleTypographyProps={{color:"primary"}}
                    />
                    <CardContent>
                        <GraphQLEditForm id={id}
                                         query={"outcomes"}
                                         mutations={"outcome"}
                                         fields={fields}
                                         cols={2}
                                         {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
