import React from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {Business} from "@material-ui/icons";
import {useIntl} from "react-intl";

export default function CustomerList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'customers.list.column.name', defaultMessage:'Name'}), field: 'name'},
        {title: intl.formatMessage({id:'customers.list.column.phone', defaultMessage:'Phone'}), field: 'phone'},
        {title: intl.formatMessage({id:'customers.list.column.email', defaultMessage:'Email'}), field: 'email', type: 'string'},
        {
            title: intl.formatMessage({id:'customers.list.column.contact.name', defaultMessage:'Contact'}), 
            field: 'contact.name', 
            type: 'string', 
            filtering: false,
            width: 'auto'
        },
        {
            title: intl.formatMessage({id:"customers.list.column.last_billed_at", defaultMessage:"Last Billed At"}),
            field: "last_billed_at", 
            type: "datetime",
           // render: row => row.last_billed_at ? moment(row.last_billed_at).format("YYYY-MM-DD HH:mm") : ""
            render: row => row.last_billed_at
        }
    ];

    return (
      <Paper>
        <EnhancedTable
          softDeletes={true}
          title={intl.formatMessage({id:'customers.list.table.heading', defaultMessage:"Customers"})}
          columns={columns}
          query="customers"
          mutations="customer"
          fields='id name phone email contact{name} last_billed_at(relative:true)'
          icon={<Business/>}
          sorting="name"
          direction="asc"
          {...props}
        />
      </Paper>
    );
}