import React from "react";
import {useIntl} from "react-intl";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import {Dns, Domain} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";
import GraphQLTable from "components/GraphQL/GraphQLTable";

export default function SiteEdit(props) {
  const intl = useIntl();

  let id = Number(props.match.params.id);

  const fields = [
    {
      field: "name",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.name",
        defaultMessage: "Name",
      }),
      input: "text",
    },
    {
      field: "reference",
      initial: "-",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.reference",
        defaultMessage: "Reference",
      }),
      input: "text",
    },
    {
      field: "package",
      initial: "full/demo",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.package",
        defaultMessage: "Package",
      }),
      input: "text",
    },
    {
      field: "is_active",
      initial: false,
      type: "Boolean",
      label: intl.formatMessage({
        id: "sites.edit.label.is_active",
        defaultMessage: "Is Active",
      }),
      input: "switch",
    },
    {
      field: "logo",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.logo",
        defaultMessage: "Logo",
      }),
      input: "file",
    },
  ];

  const domainColumns = [
    {
      title: intl.formatMessage({
        id: "domains.list.column.name",
        defaultMessage: "Name",
      }),
      field: "name",
    },
    {
      title: intl.formatMessage({
        id: "domains.list.column.is_active",
        defaultMessage: "Is Active",
      }),
      field: "is_active",
      type: "boolean",
      initial: false,
    },
    {
      title: intl.formatMessage({
        id: "domains.list.column.is_verified",
        defaultMessage: "Is Verified",
      }),
      field: "is_verified",
      type: "boolean",
      initial: false,
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            color="primary"
            avatar={<Domain/>}
            title={intl.formatMessage({id: "sites.edit.heading"})}/>
          <CardContent>
            <GraphQLEditForm
              id={id}
              query={"sites"}
              mutations={"site"}
              fields={fields}
              cols={3}
              {...props}
            />
          </CardContent>
        </Card>
      </Grid>
      {id ?
      <Grid item xs={12}>
        <GraphQLTable
            {...props}
            title={intl.formatMessage({
              id: "domains.list.table.heading",
              defaultMessage: "Domains list",
            })}
            columns={domainColumns}
            query="domains"
            mutations="domain"
            filter={id ? "site_id:"+id : ""}
            fields="id site{name} name is_active is_verified"
            icon={<Dns />}
            options={{
              pageSize: 10,
            }}
            baseUri={"/admin/sites/" + id + "/domains"}
        />
      </Grid>
          : ""}
    </Grid>
  );
}
