import React from 'react';
import {
    Badge,
    Button,
    Card,
    CardContent,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    Typography
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import themeColors from 'assets/theme/colors';
import {useIntl} from 'react-intl';
import hexToRgb from 'assets/theme/hex-to-rgb';

const useStyles = makeStyles({
  active: {
    outline: "4px solid " + themeColors.success.main,
    // fontWeight: "normal",
    textTransform: "uppercase",
    display: "flex",
    justifyContent: "space-between",
    maxHeight:34,
    marginTop:12,
  },
  button: {
    // fontWeight: "normal",
    textTransform: "uppercase",
    display: "flex",
    justifyContent: "space-between",
    maxHeight:34,
    marginTop:12,
  }
});

const badgeStyles = makeStyles({
  root: {
    backgroundColor: themeColors.secondary.badgeBg,
    color: themeColors.secondary.text,
    fontSize: "0.8rem",
    fontWeight: "700",
    transform: "unset",
    top: -10,
    right: -10,
  },
  badge: {
    border: "none",
  },
});

const outcomesDefault = [
  {
    id: 0,
    name: "Klar til behandling",
    is_closed: false,
    count: 0,
  },
];

export default function Outcomes({
                                   onCallState,
                                   outcomes = outcomesDefault,
                                   activeOutcome,
                                   setActiveOutcome,
                                   current,
                                   currentSubject,
                                   total,
                                   setOutcome,
                                 }) {
    const classes = useStyles();
    const classesBadge = badgeStyles();
    const intl = useIntl();
    const first = outcomes.filter(x => ['1', '3', '38'].some(y => y === x.id))
    const last = outcomes.filter(x => !['1', '3', '38'].some(y => y === x.id))
    outcomes = [...first, ...last]

  const handleButtonClick = (event) => {
    setOutcome(event.currentTarget.value);
  };

  const isOutcomeIdCurrentSubjectsOutcome = (id) => {
    return id === activeOutcome || (currentSubject && currentSubject.outcome && id === currentSubject.outcome.id);
  }

  return (

    <Card
      elevation={4}
      style={{
        width: "100%",
          height: "calc(100% - 38px)",
          display:"flex",
          flexDirection:"column",
      }}
    >
      <CardContent style={{paddingBottom:16, paddingTop:8, flexGrow:0}}>
        <Grid container style={{
            flexDirection:"column",
        }}>
          <Grid item>
            <Typography variant="h2">{intl.formatMessage({id: "dialer.cards.outcomes.active_list.label", defaultMessage: "Active list"})}</Typography>
          </Grid>
          <Grid item>
            <FormControl fullWidth variant="outlined" size="small">
              {activeOutcome ? <Select
                id="active_list"
                name="active_list"
                value={outcomes.length ? activeOutcome : ''}
                onChange={e => setActiveOutcome(e.target.value)}
              >
                {outcomes.map(o => <MenuItem key={'active_list_item_' + o.id} value={o.id}>{o.name}</MenuItem>)}
              </Select> : <Typography>{intl.formatMessage({id:"dialer.cards.outcomes.editing_subjects", defaultMessage:"Editing {count} subjects"}, {count: total})}</Typography>}
              <FormHelperText>{intl.formatMessage(
                {id: "dialer.cards.outcomes.active_list.helper", defaultMessage: "Subject {current} of {total}"},
                {current: current, total: total})}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent
        style={{
          flexGrow:1,
          overflow: "auto",
          paddingTop: 8,
          paddingBottom: 8,
          borderTop: "1px solid rgba(" + hexToRgb(themeColors.black.main) + ",.05)"
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          style={{
            flexFlow:"column",
            height:"100%"
          }}
          spacing={2}
        >

        <Grid
          item
        >
          <Typography
            variant="body1"
            style={{display:"flex", justifySelf: "flex-start", fontWeight: "700"}}
          >
            Åbne udfald
          </Typography>
          {outcomes
            .filter((o) => !o.is_closed)
            .map((o) => (
              <Button
                key={'outcome-'+o.id}
                variant="contained"
                color="primary"
                fullWidth
                className={isOutcomeIdCurrentSubjectsOutcome(o.id) ? classes.active : classes.button}
                value={o.id}
                disabled={onCallState}
                onClick={handleButtonClick}
                size="small"
              >
                {o.name}
                <Badge classes={classesBadge} badgeContent={o.count} max={9999} showZero overlap="rectangular"/>
              </Button>
            ))}
        </Grid>
        {!activeOutcome &&
          <Grid item>
            <Button fullWidth color="secondary" variant="contained" onClick={() => setOutcome(currentSubject.outcome ? null : 1, 4)}>
              {intl.formatMessage({id: "dialer.cards.outcomes.button.approved", defaultMessage: "Status: Aprroved"})}
            </Button>
          </Grid>
        }
        <Grid
          item
        >
          <Typography
            variant="body1"
            style={{justifySelf: "flex-start", fontWeight: "700"}}
          >
            Lukkede udfald
          </Typography>
          {outcomes
            .filter((o) => o.is_closed)
            .map((o) => (
              <Button
                key={'outcome-'+o.id}
                variant="contained"
                color="primary"
                fullWidth
                className={isOutcomeIdCurrentSubjectsOutcome(o.id) ? classes.active : classes.button}
                value={o.id}
                disabled={onCallState}
                onClick={handleButtonClick}
                size="small"
              >
                {o.name}
                <Badge classes={classesBadge} badgeContent={o.count} max={9999} showZero overlap="rectangular"/>
              </Button>
            ))}
        </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
