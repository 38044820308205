import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import componentStyles from 'assets/theme/components/admin-footer.js';

const useStyles = makeStyles(componentStyles);

export default function FooterLinks() {
    const classes = useStyles();
    return <Grid container>
        <Grid item xs={6} alignItems="center">
            <Box
                component={List}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.flexDirectionColumn}
            >
                <ListItem
                    component="a"
                    href="https://tele-mark.dk/"
                    rel="noopener noreferrer"
                    target="_blank"
                    classes={{
                        root: classes.listItemRoot,
                    }}
                >
                    © {new Date().getFullYear()}{" "} Tele-Mark A/S
                </ListItem>
            </Box>
        </Grid>

        <Grid
            item
            xs={6}
            justifyContent={"flex-end"}
        >
            <Box
                component={List}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                className={classes.flexDirectionColumn}
            >
                <ListItem
                    component="a"
                    href="https://tinx.dk"
                    rel="noopener noreferrer"
                    target="_blank"
                    classes={{
                        root: classes.listItemRoot,
                    }}
                >
                    TINXDK ApS
                </ListItem>

                <ListItem
                    component="a"
                    href={process.env.REACT_APP_URL}
                    rel="noopener noreferrer"
                    target="_blank"
                    classes={{
                        root: classes.listItemRoot,
                    }}
                >
                    {process.env.REACT_APP_NAME}
                </ListItem>
            </Box>
        </Grid>
    </Grid>;
}