const componentStyles = (theme) => ({
  mainContent: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "250px",
    },
  },
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      marginTop: "5rem",
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  mainRoot: {
    minHeight: "calc(100vh - 132px);",
    maxWidth: "100% !important",
  },
});

export default componentStyles;
