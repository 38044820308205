import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import {useIntl} from 'react-intl';
import {graphQLApi} from 'services/GraphQLApi';
import {useAuthDispatch} from 'contexts/Auth';
import PropTypes from 'prop-types';
import {Close} from '@material-ui/icons';
import Editor from 'components/Editor';

export default function SendMail({
  open,
  onClose,
  assignmentSubject,
  assignmentId,
  getSubjectContactField,
  template,
  history,
}) {
  const intl = useIntl();
  const client = new graphQLApi(useAuthDispatch(), history);
  const [_isLoading, setIsLoading] = useState(false);
  const mailDefault = {
    subject: '',
    body: '',
    cc_email: '',
    bcc_email: '',
    from_name: '',
    from_email: '',
  };
  const [isSendingMail, setIsSendingMail] = useState(false);
  const [mail, setMail] = useState(mailDefault);

  useEffect(() => {
    console.log(assignmentId, assignmentSubject, getSubjectContactField('email'));
    setMail(mailDefault);
    if (!template || !assignmentSubject) {
      return;
    }
    setIsLoading(true);
    client.mutate('{' +
        'assignmentMailParse(assignment_subject_id:' + assignmentSubject.id +
        ' template:"' + template + '")' +
        '{subject body cc_email bcc_email from_name from_email attachments{id file file_uri}}' +
        '}').then(r => {
      if (r && r.hasOwnProperty('assignmentMailParse')) {
        setMail(r.assignmentMailParse);
      }
      setIsLoading(false);
    });
  }, [template, assignmentSubject]);

  const handleSendMail = () => {
    setIsSendingMail(true);
    client
      .mutate('($id:ID $template:String $body:String $cc:String $bcc:String) {' +
        'assignmentSubjectEmailSend(assignment_subject_id:$id template:$template body:$body cc:$cc bcc:$bcc)' +
        '}', {
        id: assignmentSubject.id,
        template: template,
        body: mail.body,
        cc:mail.cc_email,
        bcc:mail.bcc_email
      })
      .then(r => {
        setIsSendingMail(false);
        if (r && r.hasOwnProperty('assignmentSubjectEmailSend')) {
          onClose(null, r.assignmentSubjectEmailSend);
        }
      });
  }

  return <Dialog open={open} onClose={onClose} maxWidth="xl">
    <DialogTitle disableTypography
                 style={{display: 'flex', justifyContent: 'space-between'}}>
      <Typography variant="h2" style={{width: 300}}>{intl.formatMessage({
        id: 'dialer.dialogs.send_mail.title',
        defaultMessage: 'Send mail to subject',
      })}</Typography>
      {isSendingMail ? <CircularProgress/> :
      <Button
        disabled={!getSubjectContactField('email') || !mail.from_email}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => handleSendMail()}
      >{intl.formatMessage({
          id: 'dialer.dialogs.send_mail.button',
          defaultMessage: 'Send mail',
        })}</Button>}
      <Box style={{width: 300, textAlign: 'right'}}><IconButton size="small"
                                                                onClick={onClose}><Close/></IconButton></Box>
    </DialogTitle>
    <DialogContent
        style={{
          maxHeight: '80vh',
          overflow: 'auto',
        }}>
      {/*<Backdrop open={isLoading}>*/}
      <Grid container spacing={1}>
        <Grid item container xs={8} spacing={1}>
          <Grid item xs={12}>
            <TextField
                fullWidth
                size="small"
                variant="outlined"
                label={intl.formatMessage({
                  id: 'dialer.dialogs.send_mail.fields.subject',
                  defaultMessage: 'Subject',
                })}
                value={mail.subject}
                disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
                error={!getSubjectContactField('name')}
                fullWidth
                size="small"
                variant="outlined"
                label={intl.formatMessage({
                  id: 'dialer.dialogs.send_mail.fields.to_name',
                  defaultMessage: 'To name',
                })}
                defaultValue={getSubjectContactField('name')}
                disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
                error={!getSubjectContactField('email')}
                fullWidth
                size="small"
                variant="outlined"
                label={intl.formatMessage({
                  id: 'dialer.dialogs.send_mail.fields.to_email',
                  defaultMessage: 'To email',
                })}
                value={getSubjectContactField('email')}
                type="email"
                disabled
                InputProps={{
                  startAdornment: <InputAdornment
                      position="start">{'<'}</InputAdornment>,
                  endAdornment: <InputAdornment
                      position="end">{'>'}</InputAdornment>,
                }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
                error={!mail.from_name}
                fullWidth
                size="small"
                variant="outlined"
                label={intl.formatMessage({
                  id: 'dialer.dialogs.send_mail.fields.from_name',
                  defaultMessage: 'From name',
                })}
                value={mail.from_name}
                disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
                error={!mail.from_email}
                fullWidth
                size="small"
                type="email"
                variant="outlined"
                label={intl.formatMessage({
                  id: 'dialer.dialogs.send_mail.fields.from_email',
                  defaultMessage: 'From email',
                })}
                value={mail.from_email}
                disabled
                InputProps={{
                  startAdornment: <InputAdornment
                      position="start">{'<'}</InputAdornment>,
                  endAdornment: <InputAdornment
                      position="end">{'>'}</InputAdornment>,
                }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
                fullWidth
                size="small"
                type="email"
                variant="outlined"
                label={intl.formatMessage({
                  id: 'dialer.dialogs.send_mail.fields.cc_email',
                  defaultMessage: 'Cc email',
                })}
                value={mail.cc_email}
                onChange={(e) =>setMail({...mail, cc_email:e.target.value})}
                InputProps={{
                  startAdornment: <InputAdornment
                      position="start">{'<'}</InputAdornment>,
                  endAdornment: <InputAdornment
                      position="end">{'>'}</InputAdornment>,
                }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
                fullWidth
                size="small"
                type="email"
                variant="outlined"
                label={intl.formatMessage({
                  id: 'dialer.dialogs.send_mail.fields.bcc_email',
                  defaultMessage: 'Bcc email',
                })}
                value={mail.bcc_email}
                onChange={(e) =>setMail({...mail, bcc_email:e.target.value})}
                InputProps={{
                  startAdornment: <InputAdornment
                      position="start">{'<'}</InputAdornment>,
                  endAdornment: <InputAdornment
                      position="end">{'>'}</InputAdornment>,
                }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={4}>
          <Grid item xs={12}>
            <InputLabel style={{marginBottom: 6}}>{intl.formatMessage({
              id: 'assignment_email_templates.edit.label.attachments',
              defaultMessage: 'Attachments',
            })}</InputLabel>
            {mail.attachments && mail.attachments.map(
                a => <Link key={'attachment_' + a.id}
                           href={a.file_uri} style={{display:'block'}}>{a.file}</Link>,
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InputLabel style={{marginBottom: 10}}>{intl.formatMessage({
            id: 'dialer.dialogs.send_mail.fields.body',
            defaultMessage: 'Body',
          })}</InputLabel>
          <FormControl
              fullWidth
              style={{overflow: 'clip'}}
          >
            <Editor
                value={mail.body}
                onChange={newContent => setMail(
                    cur => ({...cur, body: newContent}))}
                filebrowser
                model={'assignment'}
                modelId={assignmentId}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/*</Backdrop>*/}
    </DialogContent>
  </Dialog>;
}

SendMail.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  assignmentSubject: PropTypes.number.isRequired,
  template: PropTypes.oneOf(
      ['confirmation', 'direct', 'alternative_2', 'alternative_2']).isRequired,
  history: PropTypes.object.isRequired,
};
