import React from 'react';
import {Box} from '@material-ui/core';
import PropTypes from "prop-types";

export default function TabPanel({children, value, index, ...rest}) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...rest}
        >
            <Box mt={1} display={value !== index ? 'none' : 'block'}>{children}</Box>
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
