const componentStyles = (_theme) => ({
  textField: {
    '& label[data-shrink="false"]': {
      transform: 'translate(14px, 7px) scale(1) !important',
      fontSize: "0.9rem",
    },
    '& input': {
      paddingTop: 7,
      paddingBottom: 5,
      fontSize: "0.9rem",
    },
    '& svg': {
      height: "1.1rem",
      width: "1.1rem",
    },
    '& .MuiSelect-outlined': {
      padding: "5px 14px",
    }
  },
});

export default componentStyles;
