import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {AppBar, Card, CardContent, CardHeader, Tab, Tabs} from '@material-ui/core';
import {Business} from '@material-ui/icons';
import GraphQLEditForm from 'components/GraphQL/GraphQLEditForm';
import CustomerContactList from './CustomerContactList';
import {TabPanel} from 'components/Tabs';
import {zipCodes} from "../../../config";

export default function CustomerEdit(props) {
    const intl = useIntl();
    const customerFormRef = useRef(null);
    const [selectedTab, setSelectedTab] = useState(0);

    function handleTabChange(_event, newTab) {
        if(selectedTab === 0 && customerFormRef.current && customerFormRef.current.hasOwnProperty('save')) {
            customerFormRef.current.save(false);
        }
        setSelectedTab(newTab);
    }

    let id = props.match.params.id;

    function a11yProps(index) {
        return {
            id: `customer-edit-tab-${index}`,
            'aria-controls': `customer-edit-tabpanel-${index}`,
        };
    }

    const fields = [
        {
            column: 1,
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            column: 1,
            field: "address",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.address", defaultMessage: "Address"}),
            input: "text"
        },
        {
            column: 1,
            field: "zip",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.zip", defaultMessage: "Zip"}),
            input: "text",
            onBlur: (event, d, setData) => {
                const city = zipCodes.find(zip => zip.id === parseInt(event.target.value))
                setData({...d, city: city ? city.name : ''})
            },
        },
        {
            column: 1,
            field: "city",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.city", defaultMessage: "City"}),
            input: "text"
        },
        {
            column: 1,
            field: "country_code",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.country_code", defaultMessage: "Country"}),
            input: "country",
            inputCode: "Alpha-2"
        },
        {
            column: 1,
            field: "language_code",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.language_code", defaultMessage: "Language"}),
            input: "text"
        },
        {
            column: 1,
            field: "phone",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.phone", defaultMessage: "Phone"}),
            input: "text"
        },
        {
            column: 1,
            field: "email",
            initial: "",
            type: "Email",
            label: intl.formatMessage({id: "customers.edit.label.email", defaultMessage: "Email"}),
            input: "email"
        },
        {
            column: 1,
            field: "contact_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "customers.edit.label.contact_id", defaultMessage: "Contact"}),
            help: id
                ? intl.formatMessage({id: "customers.edit.help.contact_id", defaultMessage: "Create a consultant before you can select one of them to be contact"})
                : intl.formatMessage({id: "customers.edit.help_creating.contact_id", defaultMessage: "Create the customer and then a consultant before you can select one of them to be contact"}),
            query: "customerContacts",
            filter: "customer_id:" + (id ? id : null),
            titleField: "name",
            disabled: !(id > 0)
        },
        {
            column: 1,
            field: "reference",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.reference", defaultMessage: "Reference"}),
            input: "text"
        },
        {
            column: 1,
            field: "vat_number",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.vat_number", defaultMessage: "Vat Number"}),
            input: "text"
        },
        {
            column: 1,
            field: "ean_number",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.ean_number", defaultMessage: "Ean Number"}),
            input: "text"
        },
        {
            column: 2,
            input: "heading",
            label: intl.formatMessage({id: "customers.edit.heading.billing", defaultMessage: "Billing information"}),
        },
        {
            column: 2,
            field: "billing_email",
            initial: "",
            type: "Email",
            label: intl.formatMessage({id: "customers.edit.label.billing_email", defaultMessage: "Billing Email"}),
            input: "email"
        },
        {
            column: 2,
            field: "billing_external_id",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "customers.edit.label.billing_external_id",
                defaultMessage: "Billing External Id"
            }),
            input: "text"
        },
        {
            column: 2,
            field: "billing_reference",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "customers.edit.label.billing_reference",
                defaultMessage: "Billing Reference"
            }),
            input: "text"
        },
        {
            column: 2,
            field: "last_billed_at",
            initial: null,
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.last_billed_at", defaultMessage: "Last Billed At"}),
            input: "datetime"
        },
        {
            column: 2,
            field: "external_id",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.external_id", defaultMessage: "External Id"}),
            input: "text"
        },
        {
            column: 2,
            input: "heading",
            label: intl.formatMessage({id: "customers.edit.heading.mail_server", defaultMessage: "Mail server setup"}),
        },
        {
            column: 2,
            field: "mailserver_login",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "customers.edit.label.mailserver_login",
                defaultMessage: "Login"
            }),
            input: "text"
        },
        {
            column: 2,
            field: "mailserver_password",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "customers.edit.label.mailserver_password",
                defaultMessage: "Password"
            }),
            input: "text"
        },
        {
            column: 2,
            field: "mailserver_host",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "customers.edit.label.mailserver_host",
                defaultMessage: "Hostname"
            }),
            input: "text"
        },
        {
            column: 2,
            field: "mailserver_port",
            initial: 0,
            type: "Int",
            label: intl.formatMessage({id: "customers.edit.label.mailserver_port", defaultMessage: "Port"}),
            input: "number"
        },
        {
            column: 2,
            field: "mailserver_ssl",
            initial: 0,
            type: "Int",
            label: intl.formatMessage({
                id: "customers.edit.label.mailserver_ssl",
                defaultMessage: "Encryption"
            }),
            input: "switch"
        },
    ];

    return (
        <>
            <AppBar position="static">
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label={intl.formatMessage({
                        id: "customers.tabs.aria_label",
                        defaultMessage: "Customer edit tabs."
                    })}
                >
                    <Tab
                        label={intl.formatMessage({
                            id: "customers.edit.tab.general",
                            defaultMessage: "General"
                        })}
                        {...a11yProps(0)}
                    />
                    {id && <Tab
                        label={intl.formatMessage({
                            id: 'customers.edit.tab.contacts',
                            defaultMessage: 'Customer contacts',
                        })}
                        {...a11yProps(1)}
                    />}
                </Tabs>
            </AppBar>
            <TabPanel value={selectedTab} index={0}>
                <Card>
                    <CardHeader
                        avatar={<Business color="primary"/>}
                        title={id ? intl.formatMessage({id: "customers.edit.heading", defaultMessage: "Edit customer"}) :
                            intl.formatMessage({id: "customers.create.heading", defaultMessage: "Create customer"})}
                        titleTypographyProps={{color: "primary"}}
                    />
                    <CardContent>
                        <GraphQLEditForm
                            {...props}
                            ref={customerFormRef}
                            id={id}
                            query={"customers"}
                            mutations={"customer"}
                            fields={fields}
                            cols={2}
                            redirectToEdit={true}
                        />
                    </CardContent>
                </Card>
            </TabPanel>
            {id && <TabPanel value={selectedTab} index={1}>
                <CustomerContactList
                    {...props}
                    customerId={id}
                />
            </TabPanel>}
        </>
    );
}
