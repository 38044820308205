import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Grid, IconButton, Link, Snackbar, SnackbarContent} from '@material-ui/core';
import {graphQLApi} from '../../services/GraphQLApi';
import {authUser, useAuthDispatch} from '../../contexts/Auth';
import themeColors from '../../assets/theme/colors';
import {useIntl} from 'react-intl';
import moment from 'moment';
import {Close} from '@material-ui/icons';

export default function JobsChecker(props) {
  const intl = useIntl();
  const checkedAtTimer = useRef(null);
  const [jobs, setJobs] = useState([]);
  const [completedJobs, setCompletedJobs] = useState([]);
  const client = new graphQLApi(useAuthDispatch(), props.history);

  const checkJobs = () => {
    if (authUser().isAllowed(50)) {
      client.query('{' +
          'jobs(user_id:' + authUser().id + ' available_at_lt:"' + moment().format("YYYY-MM-DD HH:mm:ss") + '")' +
          '{id queue name description reserved_at available_at user_id total_jobs}' +
          'completedJobs(filter:{user_id:' + authUser().id + ' dismissed_at:null}) {data{id job_id command message message output_file output_file_uri output_link error started_at completed_at}}' +
          '}').then(r => {
        if (r) {
          if (r.jobs) setJobs(r.jobs);
          if (r.completedJobs) setCompletedJobs(r.completedJobs.data);
        }
      });
    }
  };

  useEffect(() => {
    checkJobs();
    checkedAtTimer.current = setInterval(() => {
      checkJobs();
    }, 2500);
    return () => {
      clearInterval(checkedAtTimer.current);
    };
  }, []);

  const dismissCompletedJob = (job) => {
    setCompletedJobs(cur => cur.filter(j => j.id !== job.id));
    return client.mutate('{completedJobUpdate(id:'+job.id+' dismissed_at:"'+moment().format("YYYY-MM-DD HH:mm:ss")+'"){id}}');
  }

  return <>{props.showJobQueue && <Snackbar
      open={jobs.length > 0}
      anchorOrigin={{horizontal: 'right', vertical: 'bottom'}} color={themeColors.info.snackbar}
      action={jobs.length
          ? <Box m={1}>{intl.formatMessage({id: 'jobs_checker.jobs_in_queue', defaultMessage: '{count} job(s) on queue'}, {count: jobs[0].total_jobs})}</Box>
          : null}
      message={<Grid container spacing={1} style={{flexFlow: 'column'}}>{jobs.map((job, k) =>
          <Grid item key={'job-check-' + k}>#{job.id}: {(job.description && job.description.length) ? job.description :
              job.reserved_at
                  ? intl.formatMessage({
                    id: 'jobs_checker.job_has_started',
                    defaultMessage: 'Job "{name}" #{id} was created {available_at} and was started {reserved_at}.',
                  }, {id: job.id, name: job.name, available_at: job.available_at, reserved_at: job.reserved_at})
                  : intl.formatMessage({
                    id: 'jobs_checker.job_not_started',
                    defaultMessage: 'Job "{name}" #{id} was created {available_at} and has not started yet.',
                  }, {id: job.id, name: job.name, available_at: job.available_at})}</Grid>)}</Grid>}
  />}{(completedJobs.filter(j => !j.error).length > 0) && <Snackbar
      open={completedJobs.length > 0}
      anchorOrigin={{horizontal:"left", vertical:"bottom"}}
      ><SnackbarContent
      style={{backgroundColor:themeColors.success.badgeBg, color:themeColors.black.light}}
      message={<Grid container spacing={2} style={{flexFlow:"column"}}>
    {completedJobs.filter(j => !j.error).map((job, index) => <Grid item key={'completed-job-'+index} container justifyContent="space-between">
      <Grid item style={{paddingTop:5}}>#{job.job_id}: {job.message}</Grid>
      <Grid item style={{paddingTop:5}}>{(job.output_file_uri && job.output_file) ?
          <Link href={job.output_file_uri} target="_blank">{job.output_file}</Link> :
          job.output_link ? <Button
              size="small"
              color="secondary"
              variant="contained"
              style={{marginTop:-6}}
              onClick={() => {
                dismissCompletedJob(job).then(() => {
                  if (window.location.pathname === job.output_link) window.location.reload()
                  else window.location.href = job.output_link;
                });
              }}
          >{intl.formatMessage({id:"jobs_checker.completed_job.button", defaultMessage:"See result"})}</Button> : ''
      }</Grid>
      <Grid item>
        <IconButton
            size="small"
            title={intl.formatMessage({id:'jobs_checker.completed_job.dismiss', defaultMessage:"Dismiss"})}
            onClick={() => dismissCompletedJob(job)}
        ><Close/></IconButton>
      </Grid>
    </Grid>)}</Grid>}>
      </SnackbarContent></Snackbar>}
    {(completedJobs.filter(j => j.error).length > 0) && <Snackbar
        open={completedJobs.length > 0}
        anchorOrigin={{horizontal:"left", vertical:"bottom"}}
    ><SnackbarContent
        style={{backgroundColor:themeColors.error.badgeBg, color:themeColors.black.light}}
        message={<Grid container spacing={2} style={{flexFlow:"column"}}>
          {completedJobs.filter(j => j.error).map((job, index) => <Grid item key={'completed-job-'+index} container justifyContent="space-between">
            <Grid item style={{paddingTop:5}}>#{job.job_id}: {intl.formatMessage({id:"jobs_checker.completed_job.failed", defaultMessage:'The "{command}" job failed with the message: {error}'}, job)}</Grid>
            <Grid item>
              <IconButton
                  size="small"
                  title={intl.formatMessage({id:'jobs_checker.completed_job.dismiss', defaultMessage:"Dismiss"})}
                  onClick={() => dismissCompletedJob(job)}
              ><Close/></IconButton>
            </Grid>
          </Grid>)}</Grid>}>
    </SnackbarContent></Snackbar>}
  </>;
}