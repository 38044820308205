import CssBaseline from '@material-ui/core/CssBaseline';
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core/styles';
import {ThemeProvider as ThemeProviderV5} from '@mui/material/styles';
import theme, {themeV5} from 'assets/theme/theme';
import ReleaseVersionChecker from 'components/ReleaseVersionChecker';
import {LocaleContext} from 'contexts/Intl';
import React from 'react';
import {IntlProvider} from 'react-intl';
import locale_da from 'translations/da.json';
import locale_en from 'translations/en.json';
import Layouts from './Layouts';
import JobsChecker from 'components/JobsChecker';

const generateClassName = createGenerateClassName({
  // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
  // using MUI classes (e.g. `.MuiButton`) they will lose styles.
  // Make sure to convert them to use `styled()` or `<Box />` first.
  disableGlobal: true,
  // Class names will receive this seed to avoid name collisions.
  seed: "mui-jss",
});

export default function App() {
  let [locale] = React.useContext(LocaleContext);
  const translations = {
    en: locale_en,
    da: locale_da,
  };

  return (
    <IntlProvider
      locale={locale}
      defaultLocale="en"
      messages={translations[locale]}
    >
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          <ThemeProviderV5 theme={themeV5}>
            <ReleaseVersionChecker />
            <JobsChecker />
            <CssBaseline />
            <Layouts />
          </ThemeProviderV5>
        </ThemeProvider>
      </StylesProvider>
    </IntlProvider>
  );
}
