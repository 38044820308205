import {useEffect, useState} from 'react';
import {Button, CircularProgress, Grid, Paper} from '@mui/material';
import {Calendar as BigCalendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {useIntl} from 'react-intl';
import {graphQLApi} from 'services/GraphQLApi';
import {useAuthDispatch} from 'contexts/Auth';
import {Refresh} from '@material-ui/icons';
import themeColors from 'assets/theme/colors';
import theme from 'assets/theme/theme';
import DefaultDateHeader from "react-big-calendar/lib/DateHeader";

const localized = momentLocalizer(moment);

export default function Calendar(props) {
  const intl = useIntl();
  const client = new graphQLApi(useAuthDispatch(), props.history);
  const assignmentId = props.match?.params?.assignmentID;
  const [assignment, setAssignment] = useState({
    id: null,
    name: '',
    notes: '',
  });
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  window.refreshEvents = () => {
    setIsLoading(true);
    client.query('{assignments(filter:{id:'+assignmentId+'}){total data{' +
        'id name notes' +
        ' consultants{id name calendar_events{id title description location status start end}}' +
        ' closed_target_subjects{id next_contact_at subject{id name address_1} subject_contact{id name}}' +
        '}}}').then(result => {
      if (result?.assignments) {
        let data = result.assignments.data[0];
        setAssignment({id: data.id, name: data.name, notes: data.notes});
        const es = data.closed_target_subjects.map(as => ({
          id: as.id,
          calendar_idx: 0,
          title: as.subject.name,
          start: moment(as.next_contact_at).toDate(),
          end: moment(as.next_contact_at).add(1, 'hour').toDate(),
        }));
        data.consultants.forEach((contact, idx) => {
          contact.calendar_events.forEach(e => {
            es.push({
              id: e.id,
              calendar_idx: idx+1,
              title: e.title,
              start: moment(e.start).toDate(),
              end: moment(e.end).toDate(),
            });
          });
        })
        setEvents(es);
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (assignmentId) {
      window.refreshEvents();
    }
  }, []);

  const WeekNumber = ({week}) => (
      <div style={{position:"relative"}}><div style={{position:"absolute",left:4,top:0,fontWeight:"bold"}}>{week}</div></div>
  );

  const DateHeader = (props) => {
    const m = moment(props.date);
    return (
        <>
          {
            m.weekday() === 0 && (<WeekNumber week={m.week()} />)
          }
          <DefaultDateHeader {...props} />
        </>
    );
  };

  return <Paper style={{margin:"-8px -24px",padding:8}}>
    {isLoading && <CircularProgress style={{zIndex:theme.zIndex.tooltip+10,position:'absolute',top:"16px",right:"calc(50vw - 20px)"}}/>}
    <Grid container style={{justifyContent:"space-between", marginBottom:8}}>
      <Grid item><strong>{
        intl.formatMessage({id:"dialer.calendar.assignment_notes", defaultMessage:"Assignment Notes"})
      }:</strong> {assignment.notes}</Grid>
      <Grid item><Button
          disabled={isLoading}
          startIcon={<Refresh/>}
          onClick={() => window.refreshEvents()}
      >{
        intl.formatMessage({id:"common.button.refresh", defaultMessage:"Refresh"})
      }</Button></Grid>
    </Grid>
    <BigCalendar
        components={{
          dateHeader: DateHeader
        }}
        localizer={localized}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "calc(100vh - 200px)" }}
        messages={{
          week: intl.formatMessage({id:"react-big-calendar.week", defaultMessage:"Week"}),
          work_week: intl.formatMessage({id:"react-big-calendar.work_week", defaultMessage:"Work week"}),
          day: intl.formatMessage({id:"react-big-calendar.day", defaultMessage:"Day"}),
          month: intl.formatMessage({id:"react-big-calendar.month", defaultMessage:"Month"}),
          previous: intl.formatMessage({id:"react-big-calendar.previous", defaultMessage:"Previous"}),
          next: intl.formatMessage({id:"react-big-calendar.next", defaultMessage:"Next"}),
          today: intl.formatMessage({id:"react-big-calendar.today", defaultMessage:"Today"}),
          agenda: intl.formatMessage({id:"react-big-calendar.agenda", defaultMessage:"Agenda"}),
          showMore: (total) => intl.formatMessage({id:"react-big-calendar.show_more", defaultMessage:"+{total} more"}, {total: total}),
        }}
        eventPropGetter={(event, _start, _end, _isSelected) => ({style: {backgroundColor: themeColors.colors[event.calendar_idx]}})}
    />
  </Paper>
}