import React, {useEffect, useState} from 'react';
import {Paper} from '@material-ui/core';
import EnhancedTable from 'components/DataTable/EnhancedTable';
import {FormatListNumbered} from '@material-ui/icons';
import {useIntl} from 'react-intl';
import {assignmentSubjectColumns, subjectColumns} from 'variables/subjects';
import {graphQLApi} from 'services/GraphQLApi';
import {useAuthDispatch} from 'contexts/Auth';

export default function MappingList(props) {
    const intl = useIntl();
    const subject_columns = [...subjectColumns(intl, 0, 'subjects.'), ...assignmentSubjectColumns(intl, 0, '')]
    const [subjectFields, setSubjectFields] = useState([]);
    const client = new graphQLApi(useAuthDispatch(), props.history);
    useEffect(() => {
      client.query('{subjectFields{data{id name}}}').then(r => {
        if (r && r.hasOwnProperty('subjectFields')) {
          setSubjectFields(r.subjectFields.data);
        }
      })
    }, []);

    const columns = [
        {
            title: intl.formatMessage({id: 'mappings.list.column.system_column', defaultMessage: 'System Column'}),
            field: 'system_column',
            render: row => {
              if (row.system_column) {
                if (row.system_column.startsWith('subject_field_data.')) {
                  let id = row.system_column.split('.')[1];
                  let col = subjectFields.find(x => x.id === id);
                  return col ? col.name : '';
                }
                let col = subject_columns.find(x => x.id === row.system_column);
                return col ? col.name : '';
              }
            },
        },
        {
            title: intl.formatMessage({id: 'mappings.list.column.file_column', defaultMessage: 'File Column'}),
            field: 'file_column'
        },
    ];

    return (
        <Paper>
            <EnhancedTable
                title={intl.formatMessage({id: 'mappings.list.table.heading', defaultMessage: "Mapping list"})}
                columns={columns}
                query="mappings"
                mutations="mapping"
                fields='id system_column file_column'
                icon={<FormatListNumbered/>}
                searchable={false}
                {...props}
            />
        </Paper>
    );
}