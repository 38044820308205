import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import theme from 'assets/theme/theme';
import hexToRgb from 'assets/theme/hex-to-rgb';
import themeColors from 'assets/theme/colors';
import {Warning} from '@material-ui/icons';
import moment from 'moment';
import {useHistory, useLocation} from 'react-router-dom';
import {graphQLApi} from '../../services/GraphQLApi';
import {authUser, useAuthDispatch} from '../../contexts/Auth';

export default function SubjectsFilter({outcomes = [], filter = [], filterOptions = {}, onChange}) {
  const [outcomeFilter, setOutcomeFilter] = useState([]);
  const [subjectsFilter, setSubjectsFilter] = useState([]);
  let assignmentId = filter.filter(v => v.match(/^assignment_id/))?.join('').split(':');
  if (assignmentId.length)
    assignmentId = parseInt(assignmentId[1]);
  else
    assignmentId = null;
  const client = new graphQLApi(useAuthDispatch(), useHistory());
  const [selectedOutcomes, setSelectedOutcomes] = useState([]);
  const [selectedData, setSelectedData] = useState({values:{},operators:{}});
  const [data, setData] = useState({values:{},operators:{}});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    client.query('{userSettings(filter:{user_id:'+authUser().id+', key_eq:"subjects_filter", index:'+assignmentId+'}){data{value}}}').then(r => {
      if (r && r.hasOwnProperty('userSettings') && r.userSettings.data[0]) {
        const s = JSON.parse(r.userSettings.data[0].value);
        if (s.outcomes) {
          setSelectedOutcomes(s.outcomes);
          setOutcomeFilter(s.outcomes);
        }
        if (s.data) {
          setSelectedData(s.data);
        }
      }
      setIsLoaded(true);
    });
  }, []);

  useEffect(() => {
    onChange([...filter, ...subjectsFilter, 'outcome_ids:[' + outcomeFilter.join(',') + ']'].join(', '));
  }, [outcomeFilter, subjectsFilter]);

  const updateSettings = (outcomeIds = [], data = {values:{},operators:{}}) => {
    if (isLoaded) {
      client.mutate('($id:ID!,$value:String){userSettingUpsert(user_id:$id, key:"subjects_filter", index:'+assignmentId+', value:$value){id}}', {
        id: authUser().id,
        value: JSON.stringify({
          outcomes: outcomeIds,
          data: data,
        })
      })
      if (outcomeIds && data) return; // FIXME shutup unused vars warning, remove when using the above commented code
    }
  };

  return <Grid container spacing={2} style={{margin: 0, width: '100%', padding: 4, position: 'relative'}}>
    <Outcomes outcomes={outcomes} preselected={selectedOutcomes} onChange={f => {
      setOutcomeFilter(f);
      updateSettings(f, data);
    }} xs={4}/>
    <Subjects filterOptions={filterOptions} preselected={selectedData} onChange={(f,v,o) => {
      setSubjectsFilter(f);
      setData({values:v,operators:o});
      updateSettings(outcomeFilter, {values:v,operators:o});
    }} xs={8}/>
  </Grid>;
}

export function Outcomes({outcomes = [], preselected = [], onChange, ...rest}) {
  const intl = useIntl();
  const [selected, setSelected] = useState(preselected);

  const handleOutcomeClick = (checked, outcomeId) => {
    let selection = [...selected];
    if (checked) {
      selection.push(outcomeId);
    }
    else {
      selection = selection.filter(id => id !== outcomeId);
    }
    setSelected(selection);
    onChange(selection);
  };
  const handleOutcomeAllNoneButtonClick = (isClosed) => {
    const ids = outcomes.filter(o => o.is_closed === isClosed).map(o => o.id);
    const filtered = ids.filter(id => selected.includes(id));
    let selection = [];
    if (filtered.length) {
      selection = selected.filter(id => !ids.includes(id));
    }
    else {
      selection = [...selected, ...ids.filter(id => !selected.includes(id))];
    }
    setSelected(selection);
    onChange(selection);
  };

  useEffect(() => {
    setSelected(preselected);
  }, [preselected]);

  return <Grid container item spacing={1} {...rest}>
    <Grid item xs={6}>
      <FormControl component="fieldset" style={{margin: theme.spacing(1)}} fullWidth>
        <FormLabel component="legend" style={{width: '100%', textAlign: 'center', paddingBottom: 6}}>
          <Button variant="contained" size="small"
                  onClick={() => handleOutcomeAllNoneButtonClick(false)}>{intl.formatMessage({
            id: 'assignment_subjects.datagrid.toolbar.button.select_all',
            defaultMessage: 'Select all/none',
          })}</Button>
        </FormLabel>
        <FormGroup style={{
          flexWrap: 'nowrap',
          height: 200,
          overflow: 'auto',
          backgroundColor: 'rgba(' + hexToRgb(themeColors.success.main) + ', 0.2)',
          borderStyle: 'solid',
          borderWidth: 2,
          borderRadius: 4,
          borderColor: themeColors.gray[400],
        }}>
          {outcomes.filter(o => o.is_closed === false).map(o => <FormControlLabel
              key={'outcome-' + o.id}
              control={<Checkbox
                  name={'outcome-' + o.id}
                  size="small"
                  checked={!!selected.find(id => id === o.id)}
                  onChange={e => handleOutcomeClick(e.target.checked, o.id)}/>}
              label={o.name}
          />)}
        </FormGroup>
      </FormControl>
    </Grid>
    <Grid item xs={6}>
      <FormControl component="fieldset" style={{margin: theme.spacing(1)}} fullWidth>
        <FormLabel component="legend" style={{width: '100%', textAlign: 'center', paddingBottom: 6}}>
          <Button variant="contained" size="small"
                  onClick={() => handleOutcomeAllNoneButtonClick(true)}>{intl.formatMessage({
            id: 'assignment_subjects.datagrid.toolbar.button.select_all',
            defaultMessage: 'Select all/none',
          })}</Button>
        </FormLabel>
        <FormGroup style={{
          backgroundColor: 'rgba(' + hexToRgb(themeColors.error.main) + ', 0.2)',
          flexWrap: 'nowrap',
          height: 200,
          overflow: 'auto',
          borderStyle: 'solid',
          borderWidth: 2,
          borderRadius: 4,
          borderColor: themeColors.gray[400],
        }}>
          {outcomes.filter(o => o.is_closed === true).map(o => <FormControlLabel
              key={'outcome-' + o.id}
              control={<Checkbox
                  name={'outcome-' + o.id}
                  size="small"
                  checked={!!selected.find(id => id === o.id)}
                  onChange={e => handleOutcomeClick(e.target.checked, o.id)}/>}
              label={o.name}
          />)}
        </FormGroup>
      </FormControl>
    </Grid>
  </Grid>;
}

export function Subjects(
    {
      filterOptions = {},
      preselected = {values:{},operators:{}},
      onChange,
      onlySubjects = false,
      ...rest
    }) {
  const intl = useIntl();
  const location = useLocation();
  const locationSearch = new URLSearchParams(location.search);
  const labels = {
    next_contact_at: intl.formatMessage({
      id: 'assignment_subjects.filter.label.next_contact_at',
      defaultMessage: 'Next contact',
    }),
    created_at: intl.formatMessage({id: 'assignment_subjects.filter.label.imported', defaultMessage: 'Imported'}),
    last_edited_by_phoner_at: intl.formatMessage({
      id: 'assignment_subjects.filter.label.last_edited_by_phoner_at',
      defaultMessage: 'Phoner touched',
    }),
    last_edited_by_manager_at: intl.formatMessage({
      id: 'assignment_subjects.filter.label.last_edited_by_manager_at',
      defaultMessage: 'Manager touched',
    }),
    meeting_established_at: intl.formatMessage({
      id: 'assignment_subjects.filter.label.meeting_established_at',
      defaultMessage: 'Meeting at',
    }),
    phoner_id: intl.formatMessage({id: 'assignment_subjects.filter.label.phoner', defaultMessage: 'Phoner'}),
    customer_contact_id: intl.formatMessage({
      id: 'assignment_subjects.filter.label.customer_contact',
      defaultMessage: 'Customer contact',
    }),
    status_id: intl.formatMessage({id: 'assignment_subjects.filter.label.status', defaultMessage: 'Status'}),
    past_status_id: intl.formatMessage({
      id: 'assignment_subjects.filter.label.past_status',
      defaultMessage: 'Past status',
    }),
    name: intl.formatMessage({id: 'assignment_subjects.filter.label.name', defaultMessage: 'Name'}),
    website: intl.formatMessage({id: 'assignment_subjects.filter.label.website', defaultMessage: 'Website'}),
    telephone: intl.formatMessage({id: 'assignment_subjects.filter.label.telephone', defaultMessage: 'Telephone'}),
    address: intl.formatMessage({id: 'assignment_subjects.filter.label.address', defaultMessage: 'Address'}),
    city: intl.formatMessage({id: 'assignment_subjects.filter.label.city', defaultMessage: 'City'}),
    business_type: intl.formatMessage({
      id: 'assignment_subjects.filter.label.business_type',
      defaultMessage: 'Business type',
    }),
    zip_from: intl.formatMessage({id: 'assignment_subjects.filter.label.zip_from', defaultMessage: 'Zip from'}),
    zip_to: intl.formatMessage({id: 'assignment_subjects.filter.label.zip_to', defaultMessage: 'Zip to'}),
    employees: intl.formatMessage({id: 'assignment_subjects.filter.label.employees', defaultMessage: 'Employees'}),
    field: intl.formatMessage({id: 'assignment_subjects.filter.label.field', defaultMessage: 'Extra Field'}),
    field_value: intl.formatMessage({
      id: 'assignment_subjects.filter.label.field_value',
      defaultMessage: 'Field Value',
    }),
  };

  const getOperatorIcon = (operator) => {
    switch (operator) {
      case 'lt':
        return <Tooltip title={'Mindre'}><Icon className="fa fa-less-than" fontSize="small"/></Tooltip>;
      case 'lte':
        return <Tooltip title={'Mindre eller samme'}><Icon className="fa fa-less-than-equal"
                                                           fontSize="small"/></Tooltip>;
      case 'gt':
        return <Tooltip title={'Større'}><Icon className="fa fa-greater-than" fontSize="small"/></Tooltip>;
      case 'gte':
        return <Tooltip title={'Større eller samme'}><Icon className="fa fa-greater-than-equal"
                                                           fontSize="small"/></Tooltip>;
      case 'ne':
        return <Tooltip title={'Forskellig'}><Icon className="fa fa-not-equal" fontSize="small"/></Tooltip>;
      case 'lt_date':
        return <Tooltip title={'Er før dagen'}><Icon className="fa fa-less-than" fontSize="small"/></Tooltip>;
      case 'lte_date':
        return <Tooltip title={'På dagen eller før'}><Icon className="fa fa-less-than-equal"
                                                           fontSize="small"/></Tooltip>;
      case 'gt_date':
        return <Tooltip title={'Er efter dagen'}><Icon className="fa fa-greater-than" fontSize="small"/></Tooltip>;
      case 'gte_date':
        return <Tooltip title={'På dagen eller efter'}><Icon className="fa fa-greater-than-equal"
                                                             fontSize="small"/></Tooltip>;
      case 'ne_date':
        return <Tooltip title={'Ikke denne dag'}><Icon className="fa fa-not-equal" fontSize="small"/></Tooltip>;
      case 'date':
        return <Tooltip title={'På denne dag'}><Icon className="fa fa-equals" fontSize="small"/></Tooltip>;
      case '=':
        return <Tooltip title={'Lig med'}><Icon className="fa fa-equals" fontSize="small"/></Tooltip>;
      case 'like':
        return <Tooltip title={'Indeholder'}><Icon className="fa fa-equals" fontSize="small"/></Tooltip>;
      case 'not_like':
        return <Tooltip title={'Indeholder ikke'}><Icon className="fa fa-not-equal" fontSize="small"/></Tooltip>;
      default:
        return <Warning/>;
    }
  };
  const switchOperator = (operator) => {
    let newO = {...operators};
    let o = newO[operator].shift();
    newO[operator].push(o);
    setOperators(newO);
    updateHook(data, newO);
  };

  const handleDataChange = (object, value) => {
    const d = {...data, [object]: value};
    setData(d);
    updateHook(d, operators);
  }

  const [data, setData] = useState({
    next_contact_at: '',
    created_at: locationSearch.has('created_at') ? moment(locationSearch.get('created_at')).format('YYYY-MM-DD') : '',
    last_edited_by_phoner_at: '',
    last_edited_by_manager_at: '',
    meeting_established_at: '',
    phoner_id: '',
    customer_contact_id: '',
    status_id: locationSearch.has('created_at') ? '2' : '',
    past_status_id: '',
    name: '',
    website: '',
    telephone: '',
    address: '',
    city: '',
    business_type: '',
    zip_from: '',
    zip_to: '',
    employees: '',
    field: '',
    field_value: '',
    ...preselected.values,
  });
  const [operators, setOperators] = useState({
    next_contact_at: ['date', 'gt_date', 'gte_date', 'lt_date', 'lte_date', 'ne_date'],
    created_at: ['gte_date', 'gt_date', 'lte_date', 'lt_date', 'date', 'ne_date'],
    last_edited_by_phoner_at: ['gt_date', 'gte_date', 'lt_date', 'lte_date', 'date', 'ne_date'],
    last_edited_by_manager_at: ['gt_date', 'gte_date', 'lt_date', 'lte_date', 'date', 'ne_date'],
    meeting_established_at: ['gt_date', 'lt_date', 'date', 'ne_date'],
    zip_from: ['=', 'gt', 'gte', 'ne'],
    zip_to: ['lt', 'lte'],
    employees: ['gt', 'gte', 'lt', 'lte', '='],
    field_value: ['like', 'not_like'],
    ...preselected.operators,
  });

  const updateHook = (data, operators) => {
    let newFilter = [];
    for (let k in data) {
      let v = data[k];
      if (!v) {
        continue;
      }
      if (v === '--none--') {
        newFilter.push(k + ':null');
        continue;
      }
      v = v.replace('"', '\\"');
      if (k === 'telephone' || !/^[\d.]+$/.test(v)) {
        v = '"' + v + '"';
      }
      let c = k;
      if (operators.hasOwnProperty(k)) {
        if (operators[k][0] === 'not_like') {
          c += '_ne';
        }
        else if (!['=', 'like'].includes(operators[k][0])) {
          c += '_' + operators[k][0];
        }
      }
      newFilter.push(c + ':' + v);
    }
    onChange(newFilter, data, operators);
  }

  useEffect(() => {
    if (!onlySubjects) {
      const d = {...data, ...preselected.values};
      setData(d);
      const o = {...operators, ...preselected.operators}
      setOperators(o);
      updateHook(d, o);
    }
  }, [preselected]);

  return <Grid container item spacing={1} {...rest}>
      <Grid item container xs={12} spacing={1}>
        {!onlySubjects && [
          'next_contact_at',
          'created_at',
          'last_edited_by_phoner_at',
          'last_edited_by_manager_at',
          'meeting_established_at'].map((v, k) =>
            <Grid item key={'dates-' + k} style={{width: '20%', overflow: 'clip'}}><TextField
                fullWidth
                size="small"
                label={labels[v]}
                type="date"
                name={'dates-' + v}
                value={data[v]}
                onChange={e => handleDataChange(v, e.target.value)}
                format={intl.formatMessage({id: "common.datetime.format"})}
                InputProps={{
                  startAdornment: (
                      <InputAdornment position="start">
                        <IconButton size="small" onClick={() => switchOperator(v)}>{getOperatorIcon(operators[v][0])}</IconButton>
                      </InputAdornment>
                  ),
                }}/></Grid>)}
        {!onlySubjects && ['phoner_id', 'customer_contact_id', 'status_id', 'past_status_id'].map(v =>
            <Grid item key={v} style={{width: '25%', overflow: 'clip'}}>
              <FormControl fullWidth>
                <InputLabel htmlFor={v}>{labels[v]}</InputLabel>
                <Select
                    inputProps={{
                      name: v,
                      id: v,
                    }}
                    value={filterOptions[v] ? data[v] : ''}
                    onChange={e => handleDataChange(v, e.target.value)}
                >
                  <MenuItem value={''}>&nbsp;</MenuItem>
                  <MenuItem value={'--none--'}><em>{intl.formatMessage({
                    id: 'assignment_subjects.select.not_assigned',
                    defaultMessage: 'Not assigned',
                  })}</em></MenuItem>
                  {filterOptions[v] && filterOptions[v].map(p =>
                      <MenuItem key={v + '-' + p.id} value={p.id}>{p.name ? p.name :
                          <i>{'ID: ' + p.id}</i>}</MenuItem>,
                  )}
                </Select>
              </FormControl>
            </Grid>)}
        {['name', 'website', 'telephone', 'address', 'city', 'business_type'].map(v =>
            <Grid item key={'data-' + v} xs={2} style={{overflow: 'clip'}}><TextField
                fullWidth
                size="small"
                label={labels[v]}
                name={v}
                value={data[v]}
                onChange={e => handleDataChange(v, e.target.value)}
            /></Grid>)}
        {['zip_from', 'zip_to', 'employees'].map(v =>
            <Grid item xs={2} key={'data-' + v} style={{overflow: 'clip'}}><TextField
                fullWidth
                size="small"
                label={labels[v]}
                name={v}
                value={data[v]}
                onChange={e => handleDataChange(v, e.target.value)}
                type="number"
                InputProps={{
                  startAdornment: (
                      <InputAdornment position="start">
                        <IconButton size="small" onClick={() => switchOperator(v)}>{getOperatorIcon(operators[v][0])}</IconButton>
                      </InputAdornment>
                  ),
                }}
            /></Grid>)}
        <Grid item xs={3} style={{overflow: 'clip'}}>
          <FormControl fullWidth>
            <InputLabel htmlFor="extra-field">{labels.field}</InputLabel>
            <Select
                inputProps={{
                  name: 'field',
                  id: 'field',
                }}
                value={data.field}
                onChange={e => handleDataChange('field', e.target.value)}
            >
              <MenuItem value={''}>&nbsp;</MenuItem>
              {filterOptions.field && filterOptions.field.map(sf => <MenuItem key={'subject-field-' + sf.id}
                                                 value={sf.id}>{sf.name}</MenuItem>)}
            </Select>
          </FormControl></Grid>
        <Grid item xs={3} style={{overflow: 'clip'}}><TextField
            fullWidth
            name="field_value"
            label={labels.field_value}
            value={data.field_value}
            onChange={e => handleDataChange('field_value', e.target.value)}
            InputProps={{
              startAdornment: (
                  <InputAdornment position="start">
                    <IconButton size="small" onClick={() => switchOperator('field_value')}>{getOperatorIcon(operators.field_value[0])}</IconButton>
                  </InputAdornment>
              ),
            }}
        /></Grid>
      </Grid>
    </Grid>;
}
