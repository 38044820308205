import React, {useState} from 'react';
import {IconButton, Paper} from '@material-ui/core';
import EnhancedTable from 'components/DataTable/EnhancedTable';
import {Close, Mail, OpenInBrowser} from '@material-ui/icons';
import {useIntl} from 'react-intl';
import {Dialog, DialogContent, DialogTitle, Grid, Link} from '@mui/material';
import themeColors from 'assets/theme/colors';

export default function MailsList(props) {
  const intl = useIntl();
  let assignmentId = props.match.params && props.match.params.assignmentID;
  const [showMail, setShowMail] = useState(null);

  const columns = [
    {title: intl.formatMessage({id: 'assignment_mails.list.column.subject', defaultMessage: 'Subject'}), field: 'subject'},
    {title: intl.formatMessage({id: 'assignment_mails.list.column.to_email', defaultMessage: 'To email'}), field: 'to'},
    {title: intl.formatMessage({id: 'assignment_mails.list.column.to_name', defaultMessage: 'To name'}), field: 'to_name'},
    {title: intl.formatMessage({id: 'assignment_mails.list.column.from_email', defaultMessage: 'From email'}), field: 'from'},
    {title: intl.formatMessage({id: 'assignment_mails.list.column.from_name', defaultMessage: 'From name'}), field: 'from_name'},
    {title: intl.formatMessage({id: 'assignment_mails.list.column.cc', defaultMessage: 'CC'}), field: 'cc'},
    {title: intl.formatMessage({id: 'assignment_mails.list.column.bcc', defaultMessage: 'BCC'}), field: 'bcc'},
    {title: intl.formatMessage({id: 'assignment_mails.list.column.created_at', defaultMessage: 'created_at'}), field: 'created_at', type: 'datetime'},
  ];

  return (
      <Paper>
        <EnhancedTable
            {...props}
            title={intl.formatMessage({id: 'assignment_mails.list.heading', defaultMessage: 'Assignment mail log'})}
            columns={columns}
            query="assignmentMails"
            filter={'assignment_id:' + assignmentId}
            fields="id from from_name to to_name cc bcc subject body errors created_at attachments{id file file_uri}"
            sorting="id"
            icon={<Mail/>}
            urlState
            actions={[
              {
                icon: OpenInBrowser,
                tooltip: intl.formatMessage({id: 'assignment_mails.list.action.show', defaultMessage: 'Show mail'}),
                rowClick: true,
                onClick: row => {
                  setShowMail(row);
                },
              },
            ]}
            rowStyle={row => ({backgroundColor: row.errors ? themeColors.error.badgeBg : 'inherit'})}
        />
        {showMail && <Dialog open={!!showMail} onClose={() => setShowMail(null)} maxWidth="lg">
          <DialogTitle variant="h2">
            <IconButton size="small" style={{float:"right"}} onClick={() => setShowMail(null)}><Close/></IconButton>
            {intl.formatMessage({id: 'assignment_mails.dialog.heading', defaultMessage: 'Mail contents'})}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={1}><b>{intl.formatMessage({id: 'assignment_mails.dialog.to', defaultMessage: 'To'})}:</b></Grid>
              <Grid item xs={5}>{showMail.to_name} &lt;{showMail.to}&gt;</Grid>
              <Grid item xs={1}><b>{intl.formatMessage({id: 'assignment_mails.dialog.from', defaultMessage: 'From'})}:</b></Grid>
              <Grid item xs={5}>{showMail.from_name} &lt;{showMail.from}&gt;</Grid>
              <Grid item xs={1}><b>{intl.formatMessage({id: 'assignment_mails.dialog.cc', defaultMessage: 'CC'})}:</b></Grid>
              <Grid item xs={5}>{showMail.cc}</Grid>
              <Grid item xs={1}><b>{intl.formatMessage({id: 'assignment_mails.dialog.bcc', defaultMessage: 'BCC'})}:</b></Grid>
              <Grid item xs={5}>{showMail.bcc}</Grid>
              <Grid item xs={1}><b>{intl.formatMessage({id: 'assignment_mails.dialog.subject', defaultMessage: 'Subject'})}:</b></Grid>
              <Grid item xs={11}>{showMail.subject}</Grid>
              <Grid item xs={12} style={{border:"1px solid " + themeColors.gray[500], margin:8}} dangerouslySetInnerHTML={{__html: showMail.body}}/>
              <Grid item xs={12}><b>{intl.formatMessage({id: 'assignment_mails.dialog.attachments', defaultMessage: 'Attachments'})}:</b>{
                showMail.attachments && showMail.attachments.map((a,i) => <Link style={{marginLeft:6}} key={'attachment_'+(i+1)} target="_blank" href={a.file_uri}>{a.file}</Link>)
              }</Grid>
            </Grid>
          </DialogContent>
          {showMail.errors && <DialogContent style={{color: themeColors.error.main}}>
            <b>Error:</b> {showMail.errors}
          </DialogContent>}
        </Dialog>}
      </Paper>
  );
}