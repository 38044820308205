import React from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import {graphQLApi, graphQLReduceFields} from '../../services/GraphQLApi';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useAuthDispatch} from 'contexts/Auth';
import themeColors from 'assets/theme/colors';
import {makeStyles} from '@material-ui/core/styles';
import {createFilterOptions} from '@material-ui/lab';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 *
 * Usage example:
    let customer = {id: 1, name: "TINXDK"};
    <AutocompleteApi
        id="customer_id"
        label="Customer"
        query="customers"
        titleField="name" or {["id", "name"]}
        selectedValue={customer}
        onChange={(e, value) => setCustomer(value)} />
 */

const useStyles = makeStyles(() => ({
  option: {
    '&[data-focus="true"]': {
      backgroundColor: themeColors.primary.main,
    },
  },
}));

const filterOptions = createFilterOptions();

export default function AutocompleteApi({
  id,
  label,
  query,
  filter = "",
  titleField,
  onChange,
  sorting,
  sortOptions,
  selectedValue = null,
  allowAdd = false,
  disabled = false,
  extraFields,
  textFieldProps = {},
  multiple = false,
  ...rest
}) {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const client = new graphQLApi(useAuthDispatch(), history);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [compValue, setCompValue] = React.useState(null);
  const loading = open && options.length === 0;

  const fields = graphQLReduceFields(
    [
      {
        type: "ID",
        field: "data",
        titleField: titleField,
        extraFields: extraFields,
      },
    ],
    "fields"
  );
  React.useEffect(() => {
    let active = true;

    (async () => {
      const response = await client.query(
        `{ ${query}(sorting:"${
          sorting
            ? sorting.field
            : Array.isArray(titleField)
            ? titleField[0]
            : titleField
        }",direction:"${sorting ? sorting.direction : "asc"}"${
          filter ? ",filter:{" + filter + "}" : ""
        }) { ${fields} } }`
      );
      //console.log('AutocompleteApi active=', active, ', loaded data=', response, 'selected value=', stableValue);
      if (active && response && response.hasOwnProperty(query)) {
        if (allowAdd && response[query].data.length === 0) {
          response[query].data.push({ id: "" });
        }
        if (typeof sortOptions === "function") {
          response[query].data = sortOptions(response[query].data);
        }
        setOptions(response[query].data);
        if (response[query].data && response[query].data.length) {
          if (typeof selectedValue !== 'object') {
            let s = response[query].data.find(o => o.id === selectedValue);
            setCompValue(s ? s : null);
          }
          else {
            setCompValue(selectedValue);
          }
        }
        else {
          setCompValue(null);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [
    selectedValue,
    filter,
    query,
    titleField,
    fields,
    sorting,
    sortOptions,
    allowAdd,
  ]);

  const createNewOption = (newValue) => {
    let v = { id: "new" };
    if (Array.isArray(titleField)) {
      for (let i in titleField) {
        v[titleField[i]] = i === "0" ? newValue : "";
      }
    } else {
      v[titleField] = newValue;
    }
    return v;
  };

  return (
    <Autocomplete
      disabled={disabled || loading || options.length === 0}
      id={id}
      multiple={multiple}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={compValue}
      onChange={(e, v) => {
        if (typeof v === "string") {
          v = createNewOption(v);
        } else if (v && v.hasOwnProperty("inputValue")) {
          v = createNewOption(v.inputValue);
        }
        setCompValue(v);
        if (onChange) {
          //console.debug('Sending change to hook', v, e);
          onChange(e, v);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filterOptions(options, params);

        // Suggest the creation of a new value
        if (allowAdd && params.inputValue !== "" && !compValue?.find(v => v.id === "new")) {
          filtered.push({
            inputValue: params.inputValue,
            ...createNewOption(
              intl.formatMessage({ id: "common.tooltip.add" }) +
                ' "' +
                params.inputValue +
                '"'
            ),
          });
        }

        return filtered;
      }}
      getOptionSelected={(option, value) => value && option.id === value.id}
      getOptionLabel={(option) =>
        Array.isArray(titleField)
          ? titleField
              // Complex traversal of object properties to get the compounded option label,
              // ex: project.title => {project:{title:"Test"}} => "Test"
              .map((v) => {
                if (v.indexOf(".") !== -1)
                  return v.split(".").reduce((o, k) => o[k], option);
                else return option[v];
              })
              .join(" - ")
          : option[titleField]
          ? option[titleField]
          : Object.values(option).join(", ")
      }
      options={options}
      loading={loading}
      classes={classes}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      {...rest}
    />
  );
}
