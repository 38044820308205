import React, {useState} from 'react';
import {IconButton, Paper} from '@material-ui/core';
import EnhancedTable from 'components/DataTable/EnhancedTable';
import {Close, Mail, OpenInBrowser} from '@material-ui/icons';
import {useIntl} from 'react-intl';
import {Dialog, DialogContent, DialogTitle, Grid} from '@mui/material';
import themeColors from 'assets/theme/colors';

export default function SmsList(props) {
  const intl = useIntl();
  let assignmentId = props.match.params && props.match.params.assignmentID;
  const [showMessage, setShowMessage] = useState(null);

  const getSmsStatus = (status) => {
    switch (status) {
      case 0:
        return intl.formatMessage({id:"assignment_sms.list.status.failed", defaultMessage:"Failed"});
      case 1:
        return intl.formatMessage({id:"assignment_sms.list.status.sending", defaultMessage:"Sending"});
      case 2:
        return intl.formatMessage({id:"assignment_sms.list.status.sent", defaultMessage:"Sent"});
      case 3:
        return intl.formatMessage({id:"assignment_sms.list.status.received", defaultMessage:"Received"});
      default:
        return intl.formatMessage({id:"assignment_sms.list.status.unknown", defaultMessage:"Unknown"});
    }
  }

  const columns = [
    {title: intl.formatMessage({id: 'assignment_sms.list.column.subject', defaultMessage: 'Subject'}), field: 'subject.name'},
    {title: intl.formatMessage({id: 'assignment_sms.list.column.subject_contact', defaultMessage: 'Contact'}), field: 'subject_contact.name'},
    {title: intl.formatMessage({id: 'assignment_sms.list.column.number', defaultMessage: 'To'}), field: 'number'},
    {title: intl.formatMessage({id: 'assignment_sms.list.column.status', defaultMessage: 'Status'}), field: 'status',
      render: row => getSmsStatus(row.status)
    },
    {title: intl.formatMessage({id: 'assignment_sms.list.column.message_length', defaultMessage: 'Message length'}), field: 'message', render: row => row.message.length},
    {title: intl.formatMessage({id: 'assignment_sms.list.column.created_at', defaultMessage: 'created_at'}), field: 'created_at', type: 'datetime'},
  ];

  return (
      <Paper>
        <EnhancedTable
            {...props}
            title={intl.formatMessage({id: 'assignment_sms.list.heading', defaultMessage: 'Assignment SMS log'})}
            columns={columns}
            query="smsMessages"
            filter={'assignment_id:' + assignmentId}
            fields="id number message status error created_at subject{name} subject_contact{name}"
            sorting="id"
            icon={<Mail/>}
            urlState
            actions={[
              {
                icon: OpenInBrowser,
                tooltip: intl.formatMessage({id: 'assignment_sms.list.action.show', defaultMessage: 'Show message'}),
                rowClick: true,
                onClick: row => {
                  setShowMessage(row);
                },
              },
            ]}
            rowStyle={row => ({backgroundColor: row.errors ? themeColors.error.badgeBg : 'inherit'})}
        />
        {showMessage && <Dialog open={!!showMessage} onClose={() => setShowMessage(null)} maxWidth="lg">
          <DialogTitle variant="h2">
            <IconButton size="small" style={{float:"right"}} onClick={() => setShowMessage(null)}><Close/></IconButton>
            {intl.formatMessage({id: 'assignment_sms.dialog.heading', defaultMessage: 'Message information'})}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={1}><b>{intl.formatMessage({id: 'assignment_sms.dialog.subject', defaultMessage: 'Subject'})}:</b></Grid>
              <Grid item xs={5}>{showMessage.subject?.name}</Grid>
              <Grid item xs={1}><b>{intl.formatMessage({id: 'assignment_sms.dialog.subject_contact', defaultMessage: 'Contact'})}:</b></Grid>
              <Grid item xs={5}>{showMessage?.subject_contact?.name}</Grid>
              <Grid item xs={1}><b>{intl.formatMessage({id: 'assignment_sms.dialog.number', defaultMessage: 'Number'})}:</b></Grid>
              <Grid item xs={5}>{showMessage.number}</Grid>
              <Grid item xs={1}><b>{intl.formatMessage({id: 'assignment_sms.dialog.status', defaultMessage: 'Status'})}:</b></Grid>
              <Grid item xs={5}>{getSmsStatus(showMessage.status)}</Grid>
              <Grid item xs={12}><b>{intl.formatMessage({id: 'assignment_sms.dialog.message', defaultMessage: 'Message'})}:</b></Grid>
              <Grid item xs={12} style={{border:"1px solid " + themeColors.gray[500], margin:8}}><pre>{showMessage.message}</pre></Grid>
            </Grid>
          </DialogContent>
          {showMessage.error && <DialogContent style={{color: themeColors.error.main}}>
            <b>Error:</b> {showMessage.error}
          </DialogContent>}
        </Dialog>}
      </Paper>
  );
}