export function updateItemInArray(items, key, match, attributes) {
  let index = items.findIndex(x=> x[key] === match);
  if (index === -1) {
    return [
      ...items,
      Object.assign({}, {[key]: match}, attributes),
    ];
  }
  else {
    return [
      ...items.slice(0, index),
      Object.assign({}, items[index], attributes),
      ...items.slice(index + 1),
    ];
  }
}

export const joditConfig = {
  width: 'auto',
  height: 'auto',
};

export function getFontColorFromColor(hexcolor) {
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}
