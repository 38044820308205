import React from "react";
import {useIntl} from "react-intl";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import {Person} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function SubjectContactEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.id);
    let subjectId = Number(props.match.params.subjectId);

    const fields = [
        {
            field: "subject_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "subject_contacts.edit.label.subject_id", defaultMessage: "Subject"}),
            query: "subjects",
            filter: "",
            titleField: "name"
        },
        {
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "subject_contacts.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            field: "title",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "subject_contacts.edit.label.title", defaultMessage: "Title"}),
            input: "text"
        },
        {
            field: "phone",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "subject_contacts.edit.label.phone", defaultMessage: "Phone"}),
            input: "text"
        },
        {
            field: "mobile",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "subject_contacts.edit.label.mobile", defaultMessage: "Mobile"}),
            input: "text"
        },
        {
            field: "email",
            initial: "",
            type: "Email",
            label: intl.formatMessage({id: "subject_contacts.edit.label.email", defaultMessage: "Email"}),
            input: "email"
        },
        {
            field: "department_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "subject_contacts.edit.label.department_id", defaultMessage: "Department"}),
            query: "departments",
            filter: "",
            titleField: "name"
        },
        {
            field: "side_link_contact_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "subject_contacts.edit.label.side_link_contact_id", defaultMessage: "Side Link Contact"}),
            query: "subjectContacts",
            filter: "",
            titleField: "name"
        },
        {
            field: "superior_link_contact_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "subject_contacts.edit.label.superior_link_contact_id", defaultMessage: "Superior Link Contact"}),
            query: "subjectContacts",
            filter: "",
            titleField: "name"
        },
        {
            field: "down_link_contact_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "subject_contacts.edit.label.down_link_contact_id", defaultMessage: "Down Link Contact"}),
            query: "subjectContacts",
            filter: "",
            titleField: "name"
        },
        {
            field: "import_key",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "subject_contacts.edit.label.import_key", defaultMessage: "Import Key"}),
            input: "text"
        }
    ];

    if (subjectId) {
        fields.shift();
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        avatar={<Person color="primary"/>}
                        title={id
                            ? intl.formatMessage({id: "subject_contacts.edit.heading", defaultMessage:"Edit Contact"})
                            : intl.formatMessage({id: "subject_contacts.edit.heading", defaultMessage:"Create Contact"})}
                        titleTypographyProps={{color:"primary"}}
                    />
                    <CardContent>
                        <GraphQLEditForm id={id}
                                         query={"subjectContacts"}
                                         mutations={"subjectContact"}
                                         fields={fields}
                                         cols={2}
                                         extraArgs={subjectId ? 'subject_id:' + subjectId : ''}
                                         {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
