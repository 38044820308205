import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

// core components
import componentStyles from 'assets/theme/views/auth/login.js';
import {authHeader, authSignOut, authVerify, getApiDomain, useAuthDispatch} from 'contexts/Auth';
import {Button, CardHeader, CircularProgress, Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';

const useStyles = makeStyles(componentStyles);

function Verify(props) {
    const intl = useIntl();
    const dispatch = useAuthDispatch();
    const token = authHeader();

    const history = useHistory();
    const location = useLocation();

    if (!token) history.push('/');

    const [expired, setExpired] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
      const getValidation = () => {
        authSignOut(dispatch);
        history.push('/');
      };

      const search = location.search;
      const params = new URLSearchParams(search);
      const id = params.get("id");
      const hash = params.get("hash");
      const expires = params.get("expires");
      const expiresDate = new Date(expires*1000);
      const isExpired = new Date() > expiresDate;
      setExpired(isExpired);
      const signature = params.get("signature");

      if (!id || !hash || !expires || !signature) {
        history.push('/');
      }
      else if (!isExpired && token) {
        authVerify(dispatch, props.history, setIsLoading, id, hash, expires, signature, token, getValidation);
      }
    }, [location]);

    const [domainCheck, setDomainCheck] = React.useState(null);

    React.useEffect(() => {
        if (domainCheck === null) {
            fetch(getApiDomain() + "/", {}).then((response) => {
                if (response.status === 200) {
                    return response.text().then((text) => {
                        if (text.indexOf(process.env.REACT_APP_NAME) !== -1 &&
                            text.indexOf("TINXDK") !== -1) {
                            setDomainCheck(true);
                        } else {
                            setDomainCheck(false);
                            console.error("Response from api check did not validate", text);
                        }
                    });
                } else {
                    setDomainCheck(false);
                }
            }).catch(reason => {
                console.error('Failed to validate the API domain', getApiDomain() + "/", reason);
                setDomainCheck(false);
            });
        }
    });

    const resend = (e) => {
      e.preventDefault();
      history.push('/');
    };

    const classes = useStyles();
    return (
        <>
            <Grid item xs={12} lg={5} md={7}>
                <Card classes={{root: classes.cardRoot}}>
                  <CardHeader title={intl.formatMessage({id:"auth.verification",defaultMessage:"Verification"})} classes={{root: classes.cardRoot, title: classes.cardTitle}} />
                    <CardContent classes={{root: classes.cardContent}}>
                        <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                          {!expired ? 'Verifying...' : (
                            <>
                              <Typography variant="h3">Expired</Typography>
                              <br />
                              <Button color="primary" variant="contained" onClick={resend}>
                                Resend
                              </Button>
                            </>
                          )}
                          {isLoading && <CircularProgress/>}
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default Verify;