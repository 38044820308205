import {Dialog, DialogTitle, DialogContent} from '@material-ui/core';
import {useIntl} from "react-intl";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function ContactDialog({customerId, open, row, onClose, ...rest}) {
    const intl = useIntl();

    const fields = [
        /*{
            field: "customer_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "customer_contacts.edit.label.customer_id", defaultMessage: "Customer"}),
            query: "customers",
            filter: "",
            titleField: "name"
        },*/
        {
            field: "title",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customer_contacts.edit.label.title", defaultMessage: "Title"}),
            input: "text"
        },
        {
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customer_contacts.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            field: "phone",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customer_contacts.edit.label.phone", defaultMessage: "Phone"}),
            input: "text"
        },
        {
            field: "mobile",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customer_contacts.edit.label.mobile", defaultMessage: "Mobile"}),
            input: "text"
        },
        {
            field: "email",
            initial: "",
            type: "Email",
            label: intl.formatMessage({id: "customer_contacts.edit.label.email", defaultMessage: "Email"}),
            input: "email"
        },
        {
            field: "calendar_url",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customer_contacts.edit.label.calendar_url", defaultMessage: "Calendar Url"}),
            input: "text"
        }
    ];

    function save(data) {
        onClose(data);
    }

    function close() {
        onClose();
    }

    return (
        <Dialog open={open} onClose={close}>
            <DialogTitle>{intl.formatMessage({
                id: "customer_contact.dialog.title",
                defaultMessage: "Customer Contact"
            })}</DialogTitle>
            <DialogContent>
                <GraphQLEditForm
                    id={row ? parseInt(row.id) : null}
                    query="customerContacts"
                    mutations="customerContact"
                    fields={fields}
                    cols={2}
                    extraArgs={customerId ? `customer_id:${customerId}` : ''}
                    onSave={save}
                    buttons={[{
                        label: intl.formatMessage({
                            id: "common.button.cancel",
                            defaultMessage: "Cancel"
                        }),
                        onClick: () => close()
                    }]}
                    {...rest}
                />
            </DialogContent>
        </Dialog>
    );
}