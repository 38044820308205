import {Card, CardContent, CardHeader, Grid} from '@material-ui/core';

import {Person} from '@material-ui/icons';
// import Comments from "components/Comments/Comments";
import GraphQLEditForm from 'components/GraphQL/GraphQLEditForm';
import {authRefresh, authUser} from 'contexts/Auth';
import moment from 'moment';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
// import {makeStyles} from "@material-ui/core/styles";
// import componentStyles from "assets/theme/layouts/admin.js";

// const useStyles = makeStyles(componentStyles);
moment.locale(window.language)

export default function UsersProfile (props) {
  // const classes = useStyles();
  const intl = useIntl()
  let id = authUser().id

  const fields = [
    {
      field: 'title',
      initial: '',
      type: 'String',
      // required: true,
      label: intl.formatMessage({
        id: 'user-profile.label.title',
        defaultMessage: 'Title',
      }),
      input: 'text',
    },
    {
      field: 'name',
      initial: '',
      type: 'String',
      required: true,
      label: intl.formatMessage({
        id: 'user-profile.label.name',
        defaultMessage: 'Name',
      }),
      input: 'text',
    },
    {
      field: 'email',
      initial: '',
      type: 'String',
      label: intl.formatMessage({
        id: 'user-profile.label.email',
        defaultMessage: 'E-mail',
      }),
      input: 'text',
    },
    {
      field: 'phone',
      initial: '',
      type: 'String',
      label: intl.formatMessage({
        id: 'user-profile.label.phone',
        defaultMessage: 'Phone',
      }),
      input: 'text',
    },
    {
      field: 'password',
      initial: '',
      type: 'String',
      label: intl.formatMessage({
        id: 'user-profile.label.password',
        defaultMessage: 'Password',
      }),
      input: 'text',
      no_fetch: true,
    },
    {
      field: 'password_confirmation',
      initial: '',
      type: 'String',
      label: intl.formatMessage({
        id: 'user-profile.label.passwordConfirm',
        defaultMessage: 'Confirm password',
      }),
      input: 'text',
      no_fetch: true,
    },
    // {
    //   input: 'heading',
    //   label: intl.formatMessage({
    //     id: 'user-profile.heading.sip_access',
    //     defaultMessage: 'Telephone provider authorization',
    //   }),
    // },
    // {
    //   field: 'sip_username',
    //   initial: '',
    //   type: 'String',
    //   label: intl.formatMessage({
    //     id: 'user-profile.label.sip_user',
    //     defaultMessage: 'SIP Username',
    //   }),
    //   input: 'text',
    // },
    // {
    //   field: 'sip_password',
    //   initial: '',
    //   type: 'String',
    //   label: intl.formatMessage({
    //     id: 'user-profile.label.sip_password',
    //     defaultMessage: 'SIP Password',
    //   }),
    //   input: 'text',
    // },
  ]

  return (<Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
        <Card>
          <CardHeader color="primary" avatar={<Person/>} title={<FormattedMessage
            id={'user-profile.heading'}
            defaultMessage={'Your profile'}
          />}/>
          <CardContent>
            <GraphQLEditForm
              {...props}
              id={id}
              query={'users'}
              mutations={'user'}
              fields={fields}
              cols={1}
              redirectToEdit={true}
              onSave={() => {
                authRefresh(true);
              }}
            />
          </CardContent>
        </Card>
      </Grid>
      {/*<Grid item xs={12} hidden={!id}>
          <Comments userId={id} history={props.history}/>
      </Grid>*/}
    </Grid>
  )
}
