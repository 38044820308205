import React from 'react';
import {Paper} from '@material-ui/core';
import EnhancedTable from 'components/DataTable/EnhancedTable';
import {Input} from '@material-ui/icons';
import {useIntl} from 'react-intl';

export default function SubjectFieldList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'subject_fields.list.column.name', defaultMessage:'Name'}), field: 'name'},
        {title: intl.formatMessage({id:'subject_fields.list.column.category', defaultMessage:'Category'}), field: 'category'},
        {title: intl.formatMessage({id:'subject_fields.list.column.import_key', defaultMessage:'Import Key'}), field: 'import_key'},
        {title: intl.formatMessage({id:'subject_fields.list.column.usage', defaultMessage:'Assignment usage'}), field: 'assignments_count', align: 'center', type: 'integer', initial: 0, sortable: false},
        {title: intl.formatMessage({id:'subject_fields.list.column.attach_to_new', defaultMessage:'Attach to new'}), field: 'attach_to_new', type: 'boolean', align: 'center'},
        {title: intl.formatMessage({id:'subject_fields.list.column.is_highlighted', defaultMessage:'Highlighted'}), field: 'is_highlighted', type: 'boolean', align: 'center'},
    ];

    return (
      <Paper>
        <EnhancedTable
          title={intl.formatMessage({id:'subject_fields.list.table.heading', defaultMessage:"Subject Fields list"})}
          columns={columns}
          query="subjectFields"
          mutations="subjectField"
          fields='id name category import_key  attach_to_new is_highlighted'
          counts={['assignments']}
          icon={<Input/>}
          {...props}
        />
      </Paper>
    );
}