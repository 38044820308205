import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import JoditEditor from 'jodit-react';
import {Jodit} from 'jodit';
import {useIntl} from 'react-intl';
import {authHeader, getApiDomain} from 'contexts/Auth';

const Editor = forwardRef(({
  id,
  name,
  tabIndex,
  value,
  onChange,
  filebrowser,
  model,
  modelId,
  config = {},
}, ref) => {
  const intl = useIntl();
  const conf = useMemo(() => {
    let defs = {
      toolbarStickyOffset: 50,
      placeholder: intl.formatMessage({id: 'common.field.html.placeholder', defaultMessage: 'Write formatted text here...'}),
      buttons: Jodit.defaultOptions.buttons.filter(b => !b.group || !['search', 'source', 'other', 'info'].includes(b.group)),
    };
    if (filebrowser) {
      defs = {
        ...defs,
        filebrowser: {
          showFoldersPanel: false,
          createNewFolder: false,
          moveFile: false,
          editImage: false,
          ajax: {
            url: getApiDomain() + '/files' + (model ? '/' + model + '/' + modelId : ''),
            headers: authHeader(),
          },
          uploader: true,
        },
        uploader: {
          url: getApiDomain() + '/upload' + (model ? '/' + model + '/' + modelId : ''),
          headers: authHeader(),
        },
      };
    }
    return {...defs, ...config};
  }, []);

  /** Support the use of the placeholder replacement button -- START */
  const [localValue, setLocalValue] = useState('');
  useEffect(() => {
    let v = value;
    let placeholderButton = null;
    if (v && config) {
      placeholderButton = config.extraButtons?.find(b => b.hasOwnProperty('placeholders'));
      if (!placeholderButton) {
        placeholderButton = config.buttons?.find(b => b.hasOwnProperty('placeholders'));
      }
    }
    if (placeholderButton) {
      placeholderButton.placeholders.forEach(option => {
        const expression = new RegExp(`(&#171;|«)(Q\\d+: ){0,1}${option.id.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}(&#187;|»)`, 'g');
        v = v.replace(expression, `«${option.type === 'question' ? 'Q' : ''}${option.name}»`);
      });
    }
    setLocalValue(v);
  }, [config, value]);

  function onBlur(e) {
    let v = e.hasOwnProperty('target') ? e.target.value : e;
    setLocalValue(v);
    let placeholderButton = null;
    if (v && config) {
      placeholderButton = config.extraButtons?.find(b => b.hasOwnProperty('placeholders'));
      if (!placeholderButton) {
        placeholderButton = config.buttons?.find(b => b.hasOwnProperty('placeholders'));
      }
    }
    if (placeholderButton) {
      placeholderButton.placeholders.forEach(option => {
        const expression = new RegExp(`(&#171;|«)(Q\\d+: ){0,1}${option.name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}(&#187;|»)`, 'g');
        v = v.replace(expression, `«${option.type === 'question' ? 'Q' : ''}${option.id}»`);
      });
    }

    onChange(v);
  }
  /** Support the use of the placeholder replacement button -- END */

  useImperativeHandle(ref, () => ({}));
  return <JoditEditor
      id={id}
      name={name}
      tabIndex={tabIndex}
      value={localValue}
      config={conf}
      onBlur={onBlur}
  />;
});

Editor.displayName = 'Editor';
Editor.propTypes = {
  config: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  tabIndex: PropTypes.number,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
};

export default Editor;
