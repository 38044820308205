import React, {useEffect, useRef, useState} from 'react';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, Paper, Snackbar} from '@material-ui/core';
import EnhancedTable from 'components/DataTable/EnhancedTable';
import {Business, PostAdd} from '@material-ui/icons';
import {useIntl} from 'react-intl';
import {Subjects} from 'components/SubjectsFilter';
import {graphQLApi} from 'services/GraphQLApi';
import {useAuthDispatch} from 'contexts/Auth';
import Pill from 'components/Pill/Pill';
import themeColors from 'assets/theme/colors';
import AutocompleteApi from 'components/AutocompleteApi/AutocompleteApi';

export default function SubjectList(props) {
  const intl = useIntl();
  const columns = [
    {title: intl.formatMessage({id: 'subjects.list.column.name', defaultMessage: 'Name'}), field: 'name'},
    {
      title: intl.formatMessage({id: 'subjects.list.column.address_1', defaultMessage: 'Address 1'}),
      field: 'address_1',
    },
    {title: intl.formatMessage({id: 'subjects.list.column.zip', defaultMessage: 'Zip'}), field: 'zip'},
    {title: intl.formatMessage({id: 'subjects.list.column.city', defaultMessage: 'City'}), field: 'city'},
    {
      title: intl.formatMessage({id: 'subjects.list.column.main_phone', defaultMessage: 'Phone'}),
      field: 'main_phone',
    },
    {
      title: intl.formatMessage({id: 'subjects.list.column.email', defaultMessage: 'Email'}),
      field: 'email',
      type: 'string',
    },
    {
      title: intl.formatMessage({id: 'subjects.list.column.contacts_count', defaultMessage: 'Contacts'}),
      field: 'subject_contacts_count',
      type: 'number',
    },
    {
      title: intl.formatMessage({id: 'subjects.list.column.assignments', defaultMessage: 'Assignments'}),
      field: 'assignment_subjects.assignment.name',
      type: 'string',
      render: row => row.assignment_subjects.filter(a => !!a.assignment).map((a, k) => <Pill key={'assignment-' + row.id + '-' + k} label={a.assignment.name}
                                                                                             color={themeColors.primary.light}/>),
    },
  ];
  let timeout = useRef();
  const debounce = (func, wait, immediate) => {
    return function() {
      let context = this, args = arguments;
      let later = function() {
        timeout.current = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      let callNow = immediate && !timeout.current;
      // console.log('Debouncing', callNow, timeout.current, immediate, args);
      clearTimeout(timeout.current);
      timeout.current = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  };
  const [filter, setFilter] = useState('');
  const [filterOptions, setFilterOptions] = useState({field: []});
  const tableRef = useRef();
  const client = new graphQLApi(useAuthDispatch(), props.history);
  useEffect(() => {
    client.query('{' +
        'subjectFields(sorting:"name", direction:"asc"){data{id name}}' +
        '}').then(r => {
      if (r && r.hasOwnProperty('subjectFields')) {
        setFilterOptions({field: r.subjectFields.data ? r.subjectFields.data : []});
      }
    });
  }, []);

  const debouncedUpdate = debounce(() => {
    if (tableRef.current) {
      tableRef.current.update();
    }
  }, 1250);

  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState(null);

  return (
      <Paper>
        <Dialog open={selectedRows.length > 0} onClose={() => setSelectedRows([])}>
          <DialogContent>
            <h2>{intl.formatMessage({
              id: 'subjects.assignment_dialog.title',
              defaultMessage: 'Select an assignment to assign the {count} chosen subjects to.',
            }, {count: selectedRows.length})}</h2>
            <AutocompleteApi
                query="assignments"
                titleField="name"
                selectedValue={selectedAssignment}
                onChange={(_e, v) => setSelectedAssignment(v)}
                label={intl.formatMessage(
                    {id: 'subjects.assignment_dialog.label.assignment', defaultMessage: 'Assignment'})}
            />
          </DialogContent>
          <DialogActions>
            {isSaving && <CircularProgress/>}
            <Button
                disabled={selectedAssignment === null || isSaving}
                color="primary"
                variant="contained"
                onClick={_e => {
                  setIsSaving(true);
                  client.mutate('($subjectIds:[ID],$assignmentId:ID){' +
                      'assignmentSubjectsCreate(subject_ids:$subjectIds, assignment_id:$assignmentId) {id subject{id} assignment{id name}}' +
                      '}', {subjectIds: selectedRows.map(r => r.id), assignmentId: selectedAssignment.id}).then(r => {
                    setIsSaving(false);
                    if (r && r.hasOwnProperty('assignmentSubjectsCreate')) {
                      setIsSaved(true);
                      setSelectedRows([]);
                      if (tableRef.current) {
                        tableRef.current.update();
                      }
                    }
                  });
                }}
            >{intl.formatMessage({id: 'common.button.save'})}</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
            open={isSaved}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            message={intl.formatMessage(
                {id: 'subjects.notification.saved', defaultMessage: 'Subjects have been assigned to the assignment'})}
            autoHideDuration={7500}
            onClose={_e => setIsSaved(false)}
        />
        <Subjects
            onlySubjects
            onChange={f => {
                setFilter(f.join(', '));
                debouncedUpdate();
            }}
            filterOptions={filterOptions}/>
        <EnhancedTable
            {...props}
            softDeletes
            title={intl.formatMessage({id: 'subjects.list.table.heading', defaultMessage: 'All Subjects'})}
            filter={filter}
            columns={columns}
            searchable={false}
            ref={tableRef}
            query="subjects"
            mutations="subject"
            fields="id name address_1 zip city main_phone email assignment_subjects{assignment{name}}"
            counts={['subject_contacts']}
            icon={<Business/>}
            actions={[
              {
                tooltip: '',
                onSelected: true,
                label: intl.formatMessage({
                  id: 'subjects.list.table.action.assign',
                  defaultMessage: 'Choose an assignment to assign the subjects to',
                }),
                icon: PostAdd,
                onClick: rows => {
                  setSelectedRows(rows);
                },
              },
            ]}
        />
      </Paper>
  );
}