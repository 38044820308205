import {Button, CardActions, CardHeader, Checkbox, CircularProgress, FormLabel, Grid, Link, MenuItem, Snackbar, TextField, Typography} from '@material-ui/core';
import {ArrowForward, Description} from '@material-ui/icons';
import {Autocomplete, createFilterOptions} from '@material-ui/lab';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {assignmentSubjectColumns, subjectColumns} from 'variables/subjects';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {graphQLApi} from 'services/GraphQLApi';
import {useAuthDispatch} from 'contexts/Auth';
import moment from 'moment';

export default function ImportFile(props) {
    const intl = useIntl();

    let assignmentId = props.match.params && props.match.params.assignmentID;


    const [isLoading, setIsLoading] = useState(false);
    const [showMappings, setShowMappings] = useState(false);
    const [file, setFile] = useState('');
    const [fileUploadError, setFileUploadError] = useState('');
    const [fileData, setFileData] = useState('');
    const [fileMappings, setFileMappings] = useState([]);
    const [fileSheets, setFileSheets] = useState([]);
    const [selectedFileSheet, setSelectedFileSheet] = useState(null);
    const [duplicates, setDuplicates] = React.useState([]);

    const [notification, setNotification] = React.useState({
        severity: 'info',
        show: false,
        message: '',
    });
    const notify = (message, color = 'info') => {
        setNotification({severity: color, message: message, show: true});
    }
    const closeNotification = () => setNotification({...notification, show: false});

    const [systemFields, setSystemFields] = useState([]);

    const ref = React.useRef();
    useEffect(() => {
        client.query('{' +
            'subjectFields(sorting:"name" direction:"asc") {data {id name} }' +
            'assignmentQuestions(sorting:"sorting" direction:"asc" filter:{assignment_id: '+assignmentId+'}) {data {id text} }' +
            '}').then(r => {
            let cols = assignmentSubjectColumns(intl, 0, '').filter(c => !['id', 'assignment.name'].includes(c.id));
            cols = [
                ...cols,
                ...subjectColumns(intl, cols.length, 'subjects.').filter(c => c.id !== 'subjects.id'),
            ];
            if (r.subjectFields) {
                cols = [
                    ...cols,
                    ...r.subjectFields.data.map((c, i) => ({id: 'subject_field_data.'+c.id, name: c.name, sorting: i + cols.length}))
                ];
            }
            if (r.assignmentQuestions) {
                cols = [
                    ...cols,
                    ...r.assignmentQuestions.data.filter(q => q.text.trim().length > 0).map((c, i) => ({id: 'assignment_question_answers.'+c.id, name: 'Q' + (i+1) +  ': ' + c.text, sorting: i + cols.length}))
                ];
            }
            setSystemFields(cols);
        });
    }, []);

    const client = new graphQLApi(useAuthDispatch(), props.history);

    const handleOnChangeFile = (e) => {
        setFile(e.target.files[0])
    };

    const handleUploadFile = (e, index) => {
        if (e) e.preventDefault();
        setFileUploadError('');
        setFileMappings([]);
        setFileSheets([]);
        if (file) {
            setIsLoading(true);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                setFileMappings([]);
                setFileData(JSON.stringify(reader.result));
                client.mutate('($file_data:String!) {uploadFile(file_data:$file_data'+(index !== undefined ? ',sheet_index:'+index : '')+') {file_columns sheets error message mappings{file_column system_column}}}',
                    {file_data: JSON.stringify(reader.result)}).then(result => {
                    if (result && result.hasOwnProperty('uploadFile')) {
                        if (result.uploadFile.error) {
                            setIsLoading(false);
                            setShowMappings(false);
                            setFileUploadError(result.uploadFile.message);
                            return;
                        }
                        const a = []
                        result.uploadFile.file_columns.forEach(element => {
                            const mapping = {}
                            const g = result.uploadFile.mappings.find(x => x.file_column === element)
                            mapping.file_column = element
                            mapping.system_column = g ? g.system_column : ""
                            a.push(mapping)
                        })
                        setFileMappings(a);
                        setFileSheets(result.uploadFile.sheets);
                        if (selectedFileSheet === null || selectedFileSheet > (result.uploadFile.sheets.length - 1)) {
                            setSelectedFileSheet(0);
                        }
                        setShowMappings(true);
                        setIsLoading(false);
                    }
                });
            };
        }
    }

    const handleImportFile = () => {
        if (!checkRequiredFields) {
            return
        }
        setIsLoading(true);
        let dateTime = moment().format('YYYY-MM-DD HH:mm:ss');
        client.mutate('($file_data:String!, $assignmentId:ID!, $duplicates:[ImportMappingsInput], $mappings:[ImportMappingsInput], $sheet_index:Int) {' +
            'importFile(duplicates:$duplicates, assignment_id:$assignmentId, mappings:$mappings, file_data:$file_data, sheet_index:$sheet_index)' +
            '}',
            {
                file_data: JSON.stringify(fileData),
                assignmentId: assignmentId,
                duplicates: duplicates,
                mappings: fileMappings.filter(mapping => mapping.system_column !== ""),
                sheet_index: selectedFileSheet,
            }).then(result => {
            if (result && result.importFile) {
                setShowMappings(false)
                setFile('');
                setFileMappings([]);
                setIsLoading(false);
                setDuplicates([]);
                notify(intl.formatMessage({id: "importFile.file.saved", defaultMessage: "File imported"}), "succes");
                props.history.push("/assignment/"+assignmentId+"/subjects?created_at=" + dateTime);
            } else {
                setIsLoading(false)
                notify(intl.formatMessage({
                    id: "importFile.file_save.failed",
                    defaultMessage: "File import failed"
                }), "error");

            }
        });
        ref.current.value = ""
    }

    const filteredOptions = (_options, state) => {
        let o = systemFields.filter(sf => !(fileMappings.map(x => x.system_column)).includes(sf.id));
        if (state.inputValue) {
            const f = createFilterOptions({
                matchFrom: 'any',
                stringify: option => option.name,
            });
            o = f(o, state);
        }
        return o;
    }

    const checkRequiredFields = () => {
        return fileMappings.filter(x => x.system_column === 'subjects.name').length === 0;
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Snackbar open={isLoading} anchorOrigin={{vertical: "top", horizontal: "center"}} message={"Loading..."}
                          action={<CircularProgress color="inherit"/>}/>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    message={notification.message}
                    style={{backgroundColor: notification.severity}}
                    open={notification.show}
                    onClose={closeNotification}
                    autoHideDuration={6000}
                />
            </Grid>
            <Grid item xl={4} xs={12}>
                <Card>
                    <CardHeader
                        avatar={<Description color="primary"/>}
                        title={intl.formatMessage({id: "importFile.heading", defaultMessage: "Import File"})}
                        titleTypographyProps={{color: "primary"}}
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="file"
                                    variant="standard"
                                    onChange={handleOnChangeFile}
                                    ref={ref}
                                    label={intl.formatMessage({id:"importFile.label.file_upload", defaultMessage:"Select file to import"})}
                                    InputLabelProps={{shrink:true}}
                                    error={!!fileUploadError}
                                    helperText={fileUploadError}
                                />
                            </Grid>
                            {fileSheets.length > 0 && <Grid item xs={12}>
                                <TextField
                                    label={intl.formatMessage({id:"importFile.label.select_sheet", defaultMessage:"Select what sheet to process"})}
                                    select
                                    fullWidth
                                    value={selectedFileSheet}
                                    onChange={e => {
                                        setSelectedFileSheet(e.target.value);
                                        handleUploadFile(e, e.target.value);
                                    }}
                                    InputLabelProps={{shrink:true}}
                                >{fileSheets.map((s, k) =>
                                    <MenuItem key={'file-sheet-' + k} value={k}>{s}</MenuItem>
                                )}</TextField>
                            </Grid>}
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container item spacing={2} justifyContent="space-between" alignItems="flex-end">
                            <Link href="/admin/mappings">Auto kolonne fletninger</Link>
                        <Button onClick={handleUploadFile} variant="outlined" color="primary">{intl.formatMessage({
                            id: "importFile.file.upload",
                            defaultMessage: "Upload File"
                        })}</Button>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
            {showMappings && (
                    <Grid item xl={8} xs={12}>
                        <Card>
                            <CardHeader
                                avatar={<Description color="primary"/>}
                                title={intl.formatMessage({
                                    id: "importFile.mappings.heading",
                                    defaultMessage: "Mappings"
                                })}
                                titleTypographyProps={{color: "primary"}}
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <FormLabel style={{fontWeight: "bold"}}>{intl.formatMessage({
                                            id: "importFile.mappings.edit.label.file_column",
                                            defaultMessage: "File column"
                                        })}</FormLabel>
                                    </Grid>
                                    <Grid item xs={2} style={{textAlign: "center", verticalAlign: "middle"}}>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormLabel style={{fontWeight: "bold"}}>{intl.formatMessage({
                                            id: "importFile.mappings.edit.label.system_column",
                                            defaultMessage: "System column"
                                        })}</FormLabel>
                                    </Grid>
                                    <Grid item xs={2} style={{textAlign: "center", verticalAlign: "middle"}}>
                                        <FormLabel style={{fontWeight: "bold"}}>{intl.formatMessage({
                                            id: "importFile.mappings.edit.label.duplicate",
                                            defaultMessage: "Duplicate"
                                        })}</FormLabel>
                                    </Grid>
                                </Grid>

                                {fileMappings.map((fileColumn, idx) => (
                                    <Grid container spacing={2} key={'file_mapping' + idx}>
                                        <Grid item xs={4}>
                                            <Typography variant="h4">{fileColumn.file_column}</Typography>
                                        </Grid>
                                        <Grid item xs={2} style={{textAlign: "center", verticalAlign: "middle"}}>
                                            <ArrowForward/>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                name="system_fields"
                                                fullWidth
                                                id=''
                                                value={systemFields.find(x => x.id === fileColumn.system_column) !== undefined ? systemFields.find(x => x.id === fileColumn.system_column) : null}
                                                onChange={(e, value) => {
                                                    if (value != null) {
                                                        const g = fileMappings.map(x => x.file_column === fileColumn.file_column ? {
                                                            ...x,
                                                            system_column: value.id
                                                        } : x)
                                                        setFileMappings(g)
                                                    } else {
                                                        const d = fileMappings.map(x => x.file_column === fileColumn.file_column ? {
                                                            ...x,
                                                            system_column: ""
                                                        } : x)
                                                        setFileMappings(d)
                                                    }
                                                }
                                                }
                                                options={systemFields.sort((a,b) => a.name.localeCompare(b.name))}
                                                filterOptions={filteredOptions}
                                                getOptionLabel={(option) => option.name || ""}
                                                renderInput={(params) => <TextField {...params}
                                                />}
                                            />
                                        </Grid>
                                        <Grid item xs={2} style={{textAlign: "center", verticalAlign: "middle"}}>
                                            <Checkbox
                                                checked={duplicates.findIndex(x => x.file_column === fileColumn.file_column) >= 0}
                                                onChange={() => {
                                                    let rows = []
                                                    if (duplicates.findIndex(x => x.file_column === fileColumn.file_column) >= 0) {
                                                        rows = duplicates.filter(x => x.file_column !== fileColumn.file_column)
                                                    } else {
                                                        rows = [...duplicates, fileColumn]
                                                    }
                                                    setDuplicates(rows)
                                                }}
                                                disabled={fileColumn.system_column === ""}
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                            </CardContent>
                            <CardActions>
                                <Grid container item spacing={2} justifyContent="flex-end">
                                    {checkRequiredFields() && (
                                      <Typography style={{margin:6}} variant="h4" color="error">{intl.formatMessage({
                                          id: "importFile.mappings.validation",
                                          defaultMessage: "Subject name is a required system field"
                                      })}</Typography>
                                    )}
                                    <Button onClick={handleImportFile} disabled={checkRequiredFields()}
                                            variant="contained"
                                            color="primary">IMPORT</Button>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
            )}
        </Grid>
    )
}

